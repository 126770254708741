import React, { useState } from "react";
import { Button } from "antd";
import CreateDayoffModal from "./CreateDayoffModal";

const CreateDayoffButton = () => {
  const [createModalShow, setCreateModalShow] = useState(false);
  return (
    <>
      <Button onClick={() => setCreateModalShow(true)}>
        Create Day Off
      </Button>
      <CreateDayoffModal
        closeModal={() => setCreateModalShow(false)}
        show={createModalShow}
      />
    </>
  );
};

export default CreateDayoffButton;
