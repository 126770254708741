import React from 'react';
import { Descriptions, Empty, Typography } from 'antd';

const { Text } = Typography;

const HoursReport = ({ data }) => {
  const projects = data && Object.keys(data).filter((item) => item !== 'total');
  const parsedData = [];
  projects?.forEach((project) => {
    const phases = [];
    const phasesData = Object.keys(data[project]);
    phasesData
      .filter((item) => item !== 'total')
      .forEach((phase) => {
        phases.push({ name: phase, hours: data[project][phase].hours });
      });
    parsedData.push({ project, phases, total: data[project].total });
  });
  const projectsJSX = parsedData.map((item) => {
    return (
      <React.Fragment key={item.project}>
        <Descriptions.Item label={<b>{item.project}</b>} labelStyle={{ width: '70%' }}>
          {' '}
        </Descriptions.Item>
        {item.phases.map((phase) => {
          return (
            <Descriptions.Item key={phase.name} label={phase.name} labelStyle={{ width: '70%', paddingLeft: '50px' }}>
              {phase.hours}
            </Descriptions.Item>
          );
        })}
        <Descriptions.Item
          label={
            <Text mark>
              <b>Total</b>
            </Text>
          }
          labelStyle={{ width: '70%' }}
          style={{ borderBottom: '1px solid #7ec1ff' }}
        >
          <Text mark>{item.total}</Text>
        </Descriptions.Item>
      </React.Fragment>
    );
  });
  return (
    <Descriptions bordered column={1} size={'small'}>
      <Descriptions.Item
        label={<b>Project / Phase</b>}
        labelStyle={{ width: '70%' }}
        style={{ backgroundColor: 'lightgreen' }}
      >
        <b>Hours</b>
      </Descriptions.Item>
      {data?.total > 0 ? (
        projectsJSX
      ) : (
        <Descriptions.Item
          label={<Empty style={{ height: '70px' }} image={Empty.PRESENTED_IMAGE_SIMPLE} />}
          labelStyle={{ width: '70%' }}
        >
          {''}
        </Descriptions.Item>
      )}
      <Descriptions.Item label={<b>Total</b>} labelStyle={{ width: '70%' }} style={{ backgroundColor: 'lightgreen' }}>
        <b>{data?.total}</b>
      </Descriptions.Item>
    </Descriptions>
  );
};

export default HoursReport;
