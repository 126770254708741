import React, { useState } from 'react';
import Container from "../../components/layout/Container/Container";
import { Typography, Divider, Button, Card, Table, Input } from 'antd';
import { titleLevel, getAccess } from '../../utils/helpers';
import ViewEventModal from './EventsModals/ViewEvent/ViewEventModal';
import CreateEventButton from './EventsModals/CreateEvent/CreateEventButton';
import './styles.scss';
import CustomTable from '../../components/UI/CustomTable/CustomTable';



const { Title } = Typography;

const Events = () => {
  //PS Если сегодня event, то на каждой странице отображается под хэдэром
  //полоска с Названием и Описанием праздника

  // Под вопросом: на главной странице над BirthdaysList такой же список, но с ближайшими events

  const [viewModalShow, setViewModalShow] = useState(false);
  const [searchTitle, setSearchTitle] = useState(null);
  const editAccess = getAccess('events', 'create');
  const createAccess = getAccess('events', 'edit');

  //getAccess = (endpoint, action)


  let eventsInfo = [
    {
      title: 'День большого пальца',
      date: '17/03 2023',
      time: '17:30',
      type: 'holiday',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce iaculis vehicula nunc a porta. Nam mollis, tellus sed consequat laoreet, nisi eros ullamcorper lectus, sit amet porta mi urna in quam. Donec quis ante ac sapien rutrum blandit. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Cras neque eros, vulputate sit amet cursus sit am'
    },
    {
      title: 'День пива',
      date: '25/08 2023',
      time: '17:30',
      type: 'holiday',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce iaculis vehicula nunc a porta. Nam mollis, tellus sed consequat laoreet, nisi eros ullamcorper lectus, sit amet porta mi urna in quam. Donec quis ante ac sapien rutrum blandit. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Cras neque eros, vulputate sit amet cursus sit am'
    },
    {
      title: 'День почесывания брюшка',
      date: '28/07 2023',
      time: '17:30',
      type: 'worked',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce iaculis vehicula nunc a porta. Nam mollis, tellus sed consequat laoreet, nisi eros ullamcorper lectus, sit amet porta mi urna in quam. Donec quis ante ac sapien rutrum blandit. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Cras neque eros, vulputate sit amet cursus sit am'
    },
    {
      title: 'День продуктивности',
      date: '17/09 2023',  
      time: '17:30',
      type: 'holiday',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce iaculis vehicula nunc a porta. Nam mollis, tellus sed consequat laoreet, nisi eros ullamcorper lectus, sit amet porta mi urna in quam. Donec quis ante ac sapien rutrum blandit. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Cras neque eros, vulputate sit amet cursus sit am'
    },
    {
      title: 'День лени',
      date: '9/10 2023',
      time: '17:30',
      type: 'worked',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce iaculis vehicula nunc a porta. Nam mollis, tellus sed consequat laoreet, nisi eros ullamcorper lectus, sit amet porta mi urna in quam. Donec quis ante ac sapien rutrum blandit. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Cras neque eros, vulputate sit amet cursus sit am'
    },
  ];

  const getDateSource = (dataEvents) => {
    return dataEvents?.map((event, index) => {
      return ({
        key: `${index}`,
        date: {['date']: event?.date, ['time']: event?.time},
        event: event,
      });
    });
  };

  
  const dataSource = getDateSource(eventsInfo);
  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (date) => (
        <div className='wrapperDate'>
          <p>{date?.date}</p>
          <p>{date?.time}</p>
        </div>
      ),
    },
    {
      title: 'Event',
      dataIndex: 'event',
      key: 'event',
      render: (event) => (
        <Card 
          size="small" 
          title={`${event?.title}`} 
          extra={editAccess ? <Button type="text">Edit</Button> : <></>} 
          style={{ width: '100%', cursor: 'pointer' }}
          headStyle={{height: '45px', fontSize: '15px'}}
          onClick={() => setViewModalShow(true)}
          >
          <p>{event?.description}</p>
        </Card>
      ),
    },
  ];

  return (
    <Container>
      <ViewEventModal 
        // id={id}
        show={viewModalShow}
        closeModal={() => setViewModalShow(false)}
      />
      <div className="events__header">
        <Title level={titleLevel()}>Events</Title>

        {createAccess ? <CreateEventButton/> : <></>}
      </div>
      <Divider />

      <div className="events">
        <div className='filters'>
          <Input 
            placeholder="Search event title" 
            value={searchTitle}
            onChange={(e) => setSearchTitle(e.target.value)}
            />
          <Button type='primary' onClick={() => setSearchTitle(null)}>Reset</Button>
        </div>
        {/* <Table dataSource={dataSource} columns={columns} /> */}
        <CustomTable itemsInfo={eventsInfo} tableType="events"/>
      </div>

    </Container>
  );
};

export default Events;
