import React from 'react';
import { Tag } from 'antd';
// import { taskStatusParser } from '../../utils/helpers';

const ProjectStatus = ({ status }) => {

  const getNiceStatus = (newStatus) => {
    let arrStatus = status.toLowerCase().split('');
    arrStatus[0] = arrStatus[0].toUpperCase();
    return arrStatus.join('').split('_').join(' ');
  }

  const color = () => {
    switch (status) {
      case 'DONE':
        return 'green';
      case 'IN_PROGRESS':
        return 'blue';
      case 'REJECTED':
        return 'red';
      case 'NEED_FEEDBACK':
        return 'gold';
      default:
        return 'blue';
    }
  };


  return <>{<Tag color={color()}>{getNiceStatus(status)}</Tag>}</>;
};

export default ProjectStatus;
