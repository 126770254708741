import {
  GET_BONUSES_REPORT_REQUEST,
  GET_BONUSES_REPORT_SUCCESS,
  GET_BONUSES_REPORT_FAILURE,
  GET_EMPLOYEE_REPORT_REQUEST,
  GET_EMPLOYEE_REPORT_SUCCESS,
  GET_EMPLOYEE_REPORT_FAILURE,
} from '../actionTypes';
import axiosWH from '../../utils/axios';

const getBonusesReportRequest = () => ({ type: GET_BONUSES_REPORT_REQUEST });
const getBonusesReportSuccess = (data) => ({ type: GET_BONUSES_REPORT_SUCCESS, data });
const getBonusesReportFailure = (error) => ({ type: GET_BONUSES_REPORT_FAILURE, error });

const getEmployeeHoursReportRequest = () => ({ type: GET_EMPLOYEE_REPORT_REQUEST });
const getEmployeeHoursReportSuccess = (data) => ({ type: GET_EMPLOYEE_REPORT_SUCCESS, data });
const getEmployeeHoursReportFailure = (error) => ({ type: GET_EMPLOYEE_REPORT_FAILURE, error });

/** Not done **/
export const getBonusesReport = (id) => {
  return async (dispatch) => {
    try {
      dispatch(getBonusesReportRequest());
      const response = await axiosWH.get(`/api/v1/report/bonuses?user=${id}`);
      dispatch(getBonusesReportSuccess(response.data));
      console.log(response.data);
    } catch (e) {
      dispatch(getBonusesReportFailure(e));
    }
  };
};
/** ---------------- **/

export const getEmployeeHoursReport = (id, startDate = '', endDate = '') => {
  return async (dispatch) => {
    try {
      dispatch(getEmployeeHoursReportRequest());
      const response = await axiosWH.get(
        `/api/v1/report/employee?user=${id}&start_date=${startDate}&end_date=${endDate}`
      );
      dispatch(getEmployeeHoursReportSuccess(response.data.data));
    } catch (e) {
      dispatch(getEmployeeHoursReportFailure(e));
    }
  };
};
