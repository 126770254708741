import React, { useState } from 'react';
import { Modal, Form, Button } from 'antd';


const ModalFormWrapper = ({ modalTitle, buttonName, children }) => {
  const [modalShow, setModalShow] = useState(false);
	const [form] = Form.useForm();

  return (
    <>
			<Button onClick={() => setModalShow(true)}>
				{buttonName}
			</Button>
			<Modal
				// id={id}
				open={modalShow} 
				onCancel={() => setModalShow(false)} 
				title={`${modalTitle}`}
				okText="Save" 
				// onOk={createDayoffHandler}
				footer={[]}
			>
      <div className='project'>
        <Form
          form={form}
          onFinish={(e) => {
            console.log(e);
            form.resetFields();
            // closeModal();
						setModalShow(false);
          }}
          onFinishFailed={(failedValues) => {
            console.log({failedValues});
            // closeModal();
						// setModalShow(false);
          }}  
					>

					{children}
          
          <div className='wrapperButton'>
            <Button type='primary' htmlType='submit'>Save</Button>
          </div>
        </Form>
      </div>
    </Modal>
    </>
  )
}

export default ModalFormWrapper;
