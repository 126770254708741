import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Alert, Collapse, Descriptions, Table, Button } from "antd";
import { PlusOutlined } from '@ant-design/icons';
import WorkedInPast from "../../../components/UI/WorkedInPast/WorkedInPast";
import TeamList from "../../../components/UI/TeamList/TeamList";
import { getContacts } from "../../../store/actions/contactsActions";
import AddDeveloperButton from "../ProjectModals/AddDeveloper/AddDeveloperButton";
import AddPhaseButton from "../ProjectModals/AddPhase/AddPhaseButton";



const { Panel } = Collapse;

const Phase = ({title}) => {
  const dataSource = [
    // {
    //   key: '1',
    //   label: 'Name',
    //   value: 32,
    // },
    {
      key: '2',
      label: 'Description',
      value: '	Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce iaculis vehicula nunc a porta. Nam mollis, tellus sed consequat laoreet, nisi eros ullamcorper lectus, sit amet porta mi urna in quam. Donec quis ante ac sapien rutrum blandit. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Cras neque eros, vulputate sit amet cursus sit amet, elementum tempor velit. Cras pretium condimentum odio at aliquet. Vestibulum at ipsum non enim posuere volutpat eu nec sapien. Fusce faucibus est ut augue placerat quis accumsan metus ullamcorper. Morbi sodales, velit id suscipit volutpat, ante ligula ultrices arcu, eu semper enim risus et elit. Quisque semper sapien ut nulla lacinia laoreet. In auctor dolor sit amet eros molestie congue. Maecenas ultrices interdum nisi et placerat. Mauris at tortor eget nibh fermentum fermentum.',
    },
    {
      key: '3',
      label: 'Type	Billable',
      value: 'Billable',
    },
    {
      key: '4',
      label: 'Total hours limit',
      value: '443 (0 billable / 0 spent )',
    },
    {
      key: '5',
      label: 'Phase timeout',
      value: 	'Allocations will not close never',
    },
  ];
  
  const columns = [
    {
      title: '',
      dataIndex: 'label',
      key: 'label',
      render: (text) => <b>{text}</b>,
    },

    {
      title: '',
      dataIndex: 'value',
      key: 'value',
    },
  ];

  return(
    <div className="phase">
      <Table dataSource={dataSource} columns={columns} pagination={false}/>
    </div>
  );
}

const Phases = ({data}) => {
  const dispatch = useDispatch();
  // console.log('data: ', data)
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    dispatch(getContacts());
  }, []);

  let activeUsers = data?.userProjectPhases?.filter((user) => user?.status === 'Active');
  let passiveUsers = data?.userProjectPhases?.filter((user) => user?.status !== 'Active');
  console.log('activeUsers: ', activeUsers)
  console.log('passiveUsers: ', passiveUsers)
  
  
  
  return (
    <div className="phases">
      {/* <h1>Total hours worked: 0 </h1> */}
      <Alert type="success" message={<h2>Total hours worked: 0</h2>} banner showIcon={false} />

      <div className="teamListWithButtons">
        <div className="firstColumn">
          <TeamList
            // allUsers={data?.userProjectPhases} 
            allUsers={activeUsers}
            setUserId={setUserId} 
            setIsModalVisible={setIsModalVisible}
            userId={userId}
            isModalVisible={isModalVisible}
          />
          <WorkedInPast users={passiveUsers}/>
          {/* <WorkedInPast users={activeUsers}/> */}
        </div>

        <div className="secondColumn">
          <div className="buttons">
            <Button type="primary"> Show tasks </Button>
            <div className='wrapperAddButtons'>
              <AddDeveloperButton />
              <AddPhaseButton />
            </div>
            {/* <Button type="primary"> Add phase </Button> */}

          </div>
          <div className="phasesMiniList">
            <h3>Phases</h3>
            <div>Phase_0591 very long title longlonglong </div>
            <div>Phase_0591</div>
            <div>Phase_0591</div>
          </div>
        </div>

      </div>


      <div className="phasesList">
        <Collapse>
          {['Phase_0591 very long title longlonglong', 'Phase_0552', 'Phase_0553']?.map((phaseTitle, index) => {
            return(
            <Panel 
              header={
                <div className="phaseHeader">
                  <div>{phaseTitle}</div>
                  <div className="phasesButtons">
                    <Button icon={<PlusOutlined />}><span className="addDeveloper">Developer</span></Button>
                    <Button>Edit</Button>
                  </div>
                </div>
              }
              key={index}
            >
              <Phase title={phaseTitle} />
            </Panel>
            )
          })}
        </Collapse>
      </div>

    </div>
  );
};

export default Phases;
