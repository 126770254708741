import React, { useState } from 'react';
import { Modal, Form, Input, Select, Checkbox, Button } from 'antd';
import { contractsOptions, sourcesOptions } from '../../../../utils/helpers';
import TextEditor from '../../../../components/UI/TextEditor/TextEditor';


const { Item } = Form;

const CreateProjectModal = ({show, closeModal}) => {
	const [form] = Form.useForm();

	const [projectName, setProjectName] = useState(null);
	const [description, setDescription] = useState(null);
	const [source, setSource] = useState(null);
	const [stateIsNda, setStateIsNda] = useState(null);
	const [stateIsInternal, setStateIsInternal] = useState(null);
	const [contractType, setContractType] = useState(null);
  const [editorValue, setEditorValue] = useState('');


  return (
    <Modal
			title="Create new project"
			centered
			open={show}
			onOk={() => closeModal()}
			onCancel={() => closeModal()}
			footer={[]}
    >
			<div className='project'>
				<Form
					form={form}
					onFinish={(e) => {
						console.log(e);
						form.resetFields();
						closeModal();
					}}
					onFinishFailed={(failedValues) => {
						console.log({failedValues});
						// closeModal();
					}}
				>
					<Item 
						name='projectName' 
						label='Project name'
						required
						rules={[{required: true, message: "Please enter project name"}]}
					>
						<Input
							placeholder="Enter project name"
							value={projectName}
							onChange={(e) => setProjectName(e.target.value)}
						/>
					</Item>
					<Item 
						name='description' 
						label='Description'
						required
						rules={[{required: true, message: "Please enter description"}]}
					>
						<TextEditor
							value={editorValue}
							onChange={setEditorValue}
							/>
					</Item>
					<Item name='source' label='Source'>
						<Select
							placeholder="Select source"
							value={source}
							onChange={(value) => setSource(value)}
							options={sourcesOptions}
						/>
					</Item>
					<Item name='isNda' label='' valuePropName="checked">
						<Checkbox
							checked={!!stateIsNda}
							onChange={(e) => setStateIsNda(e.target.checked)}
						>isNda</Checkbox>
					</Item>
					<Item name='isInternal' label='' valuePropName="checked">
						<Checkbox
							checked={!!stateIsInternal}
							onChange={(e) => setStateIsInternal(e.target.checked)}
						>isInternal</Checkbox>
					</Item>
					<Item name='contractType' label='Contract type'>
						<Select
							placeholder="Select contract type"
							value={contractType}
							onChange={(value) => setContractType(value)}
							options={contractsOptions}
						/>
					</Item>
					<div className='wrapperButton'>
						<Button type="primary" htmlType='submit'>Submit</Button>
					</div>
				</Form>

			</div>
    </Modal>
  )
}

export default CreateProjectModal;
