import React from 'react';
import { Table } from 'antd';
import ProjectStatus from "../Tasks/ProjectStatus";

const columns = [
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'Project',
    dataIndex: 'projectName',
    key: 'projectName',
  },
  {
    title: 'Phase',
    dataIndex: 'phaseName',
    key: 'phaseName',
  },
  {
    title: 'Task',
    dataIndex: 'taskName',
    key: 'taskName',
  },
  {
    title: 'Hours',
    dataIndex: 'hours',
    key: 'hours',
  },
  {
    title: 'Estim',
    dataIndex: 'estimationTime',
    key: 'estimationTime',
  },
  {
    title: 'Status',
    dataIndex: 'hoursStatus',
    key: 'hoursStatus',
    render: (_, record) => <ProjectStatus status={record.hoursStatus} />,
  },
  {
    title: 'Comment',
    dataIndex: 'hoursComment',
    key: 'hoursComment',
  },
];

const ReportDetailedTable = ({ data }) => {
  return (
    <div style={{width: '100%', overflow: 'scroll'}}>
      <Table columns={columns} dataSource={data} pagination={false} bordered style={{ minWidth: '1500px' }} />
    </div>
  );
};

export default ReportDetailedTable;
