import React from 'react';
import { Controller } from 'react-hook-form';
import { DatePicker, Typography } from 'antd';
// import moment from 'moment';
import dayjs from 'dayjs';
import { getDatePickerValue } from '../../utils/helpers.js';

const dateFormat = 'YYYY-MM-DD';

const FormDatePicker = ({ title, name, control, error, required = false }) => {
  return (
    <>
      <Controller
        name={name}
        control={control}
        rules={{ required: required ? `${title} is required` : false }}
        render={({ field: { onChange, value } }) => (
          <>

            <Typography className="field__label">
              {title} {required && <span className="required">*</span>}
            </Typography>
            <DatePicker
              // value={moment(value, dateFormat)}
              value={dayjs(getDatePickerValue(value), dateFormat)}
              format={dateFormat}
              status={error ? 'error' : null}
              onChange={onChange}
              className="tasks__field"
              allowClear={false}
            />
          </>
        )}
      />
      {error && <p className="errorText">{error.message}</p>}
    </>
  );
};

export default FormDatePicker;
