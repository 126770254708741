import React from 'react';

export const SatisfiedSmile = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="37" viewBox="0 0 36 37">
      <defs></defs>
      <g transform="translate(-578 -835)">
        <ellipse
          className="a"
          style={{ fill: '#f2d45a' }}
          cx="18"
          cy="18.5"
          rx="18"
          ry="18.5"
          transform="translate(578 835)"
        />
        <g transform="translate(585.062 842.534)">
          <circle
            className="b"
            style={{ fill: 'none', stroke: '#9b821e', strokeMiterlimit: 10, strokeWidth: '2px' }}
            cx="11"
            cy="11"
            r="11"
            transform="translate(-0.062 0.466)"
          />
          <g transform="translate(5.948 7.337)">
            <line
              className="c"
              style={{
                fill: 'none',
                stroke: '#9b821e',
                strokeMiterlimit: 10,
                strokeWidth: '2px',
                strokeLinecap: 'round',
              }}
              y2="3.058"
              transform="translate(0 0)"
            />
            <line
              className="c"
              style={{
                fill: 'none',
                stroke: '#9b821e',
                strokeMiterlimit: 10,
                strokeWidth: '2px',
                strokeLinecap: 'round',
              }}
              y2="3.058"
              transform="translate(10.074 0)"
            />
          </g>
          <path
            className="c"
            style={{
              fill: 'none',
              stroke: '#9b821e',
              strokeMiterlimit: 10,
              strokeWidth: '2px',
              strokeLinecap: 'round',
            }}
            d="M-177.048-394.44c0,1.471-1.465,2.663-3.273,2.663s-3.273-1.192-3.273-2.663"
            transform="translate(191.304 408.781)"
          />
        </g>
      </g>
    </svg>
  );
};
