import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getNews } from '../../store/actions/newsActions';
import { getDayoffs } from '../../store/actions/daysoffsActions';
import { getContacts } from '../../store/actions/contactsActions';
import { getClientsBirthdays, getUsersBirthdays } from '../../store/actions/birthdaysActions';
import { Divider, Spin, Button, Drawer, Col, Row, DatePicker, Typography, Space } from 'antd';
import { LeftOutlined, RightOutlined, CalendarFilled } from '@ant-design/icons';
import Container from '../../components/layout/Container/Container';
import Calendar from '../../components/UI/Calendar/Calendar';
import Whitter from '../News/Whitter';
import BirthdaysList from '../Birthdays/BirthdaysList';
import DayoffMain from '../../components/UI/DayoffMain/DayoffMain';
import { dateHelper, currentMonthYear, getMonthNumber } from '../../utils/helpers';
// import moment from 'moment';
import dayjs from 'dayjs';
import { defaultDashboardSettings, months } from '../../utils/constants';
import './styles.scss';

const { Title } = Typography;

const Main = () => {
  const dispatch = useDispatch();
  const usersBirthdays = useSelector((state) => state.birthdays.usersBirthdays);
  const usersLoading = useSelector((state) => state.birthdays.usersLoading);
  const calendarLoading = useSelector((state) => state.calendar.loading);
  const dayOffs = useSelector((state) => state?.dayOff?.dayOffs);
  const dayOffsLoading = useSelector((state) => state.dayOff.dayOffsLoading);
  const clientsBirthdays = useSelector((state) => state.birthdays.clientsBirthdays);
  const clientsLoading = useSelector((state) => state.birthdays.clientsLoading);
  const newsLoading = useSelector((state) => state.news.newsLoading);
  const settings = useSelector((state) => state.user.settings);
  const [currentDate, setCurrentDate] = useState(dateHelper(new Date(), '-').slice(0, -3));
  const dashboardSettings = settings?.dashboard ? settings?.dashboard : defaultDashboardSettings;

  useEffect(() => {
    dispatch(getNews());
    dispatch(getContacts());
    // dispatch(getDayoffs(dateHelper(new Date()), dateHelper(moment().endOf('month')), null));
    dispatch(getDayoffs(dateHelper(new Date()), dateHelper(dayjs().endOf('month')), null));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getUsersBirthdays(getMonthNumber(currentDate)));
    dispatch(getClientsBirthdays(getMonthNumber(currentDate)));
  }, [dispatch, currentDate]);

  const changeDateHandler = (date, dateString) => {
    setCurrentDate(dateString);
  };
  const changeMonths = (value) => {
    let result;
    if (value) {
      result = dateHelper(new Date(currentDate).setMonth(new Date(currentDate).getMonth() + 1));
    } else {
      result = dateHelper(new Date(currentDate).setMonth(new Date(currentDate).getMonth() - 1));
    }
    setCurrentDate(result.slice(0, -3));
    //изменить Birthday List и для Employee, Clients
  };

  let monthNumber = getMonthNumber(currentDate);
  let curMonth = months[monthNumber -1];

  return (
    <Spin spinning={calendarLoading || newsLoading || dayOffsLoading || usersLoading || clientsLoading} size="large">
      <Container>
        <Row gutter={24}>

          <Col xs={24} sm={24} md={24} lg={dashboardSettings?.calendarFullwidth ? 24 : 18}>
            <Row gutter={24}>

              <Col xs={24} sm={12} md={18} className="main__month">
                <Space>
                  <LeftOutlined className="main__month_arrow" onClick={() => changeMonths(false)} />
                  <Title level={4} className="main__month_title">
                    {currentMonthYear(currentDate)}
                    <DatePicker
                      onChange={changeDateHandler}
                      picker="month"
                      nextIcon={<RightOutlined className="main__month_arrow" width="18" height="18"/>}
                      // nextIcon={<CalendarFilled />}
                      allowClear={false}
                      inputReadOnly
                      className="main__month_datePicker"
                    />
                    {/* <CalendarFilled /> */}
                  </Title>
                  <RightOutlined className="main__month_arrow" onClick={() => changeMonths(true)} />
                </Space>
              </Col>
              <Col xs={24} sm={12} md={6}>
                {/* <DatePicker
                  onChange={changeDateHandler}
                  picker="month"
                  // nextIcon={<RightOutlined className="main__month_arrow" />}
                  nextIcon={<CalendarFilled />}

                  allowClear={false}
                  // style={{ width: '100%' }}
                  // style={{width: '30px'}}
                  inputReadOnly
                  className="main__month_datePicker"
                /> */}
              </Col>
            </Row>
            <Divider />
            <Calendar currentDate={currentDate}/>
            <Divider />
            {!dashboardSettings?.calendarFullwidth && <Whitter logo />}
          </Col>
          {dashboardSettings?.calendarFullwidth && (
            <Col xs={24} sm={24} md={24} lg={dashboardSettings.birthdays || dashboardSettings.dayoffs ? 18 : 24}>
              <Whitter logo />
            </Col>
          )}
          <Col xs={24} sm={24} md={24} lg={6}>
            {dashboardSettings.birthdays && usersBirthdays?.length > 0 && (
              <>
              <BirthdaysList
                title={<b>Birthdays in {`${curMonth}`}</b>}
                data={usersBirthdays?.sort((a, b) => a.day - b.day)}
                loading={usersLoading}
                userType={'Employees'}
              />
              </>
            )}
            {dashboardSettings.birthdays && clientsBirthdays?.length > 0 && (
              <BirthdaysList
                title={<b>Clients Birthdays</b>}
                data={clientsBirthdays?.sort((a, b) => a.day - b.day)}
                loading={clientsLoading}
                userType={'Clients'}
              />
            )}
            <Divider />
            {dashboardSettings.dayoffs && <DayoffMain dayOffs={dayOffs} />}
          </Col>
        </Row>
      </Container>
    </Spin>
  );
};

export default Main;
