import { GET_PHASES_FAILURE, GET_PHASES_REQUEST, GET_PHASES_SUCCESS } from "../actionTypes";

const initialState = {
  phases: [],
  loading: false
};

const phasesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PHASES_REQUEST:
      return { ...state, loading: true };
    case GET_PHASES_SUCCESS:
      return { ...state, loading: false, phases: action.phases };
    case GET_PHASES_FAILURE:
      return { ...state, loading: false, error: action.error };
    default:
      return { ...state };
  }
};

export default phasesReducer;
