import React from 'react';
import { Alert, Descriptions } from "antd";
import dayjs from 'dayjs';



const BlockInfo = ({ data, array, header }) => { //header="About" //array={arrAbout}
  const getInfo = (hhh) => {
    switch(hhh) {
      case "name":
        return {label: "Name", value: data?.name}
      case "otherNames": 
        return {label: "Other Names", value: data?.otherNames}
      case "emailAlias":
        return {label: "Email alias", value: `${data?.emailAlias}projects.sibers.com`}
      case "createdAt":
        return {label: "Started/finished", value: `${dayjs(data?.createdAt).format('L')} - now TO DO`};
      case "source":
        return {label: "Source", value: data?.source}
      case "isNda":
        return {label: "NDA", value: data?.isNda ? 'YES' : 'NO'}
      case "isInternal":
        return {label: "Internal", value: data?.isInternal ? 'YES' : 'NO'}
      case "dateDeadline":
        return {label: "Deadline", value: data?.dateDeadline}
      case "status":
        return {label: "Status", value: data?.status}
  
      ///////////////////////
      case "contractType":
        return {label: "Contract type", value: data?.contractType}
      case "paymentScheme": ////??????????????????
        return {label: "Payment scheme", value: data?.paymentScheme}

      case "estimatedHours":
        return {label: "Estimated hours", value: data?.estimatedHours}
      case "estimatedMoney":
        return {label: "Estimated money", value: data?.estimatedMoney}

    }
      //default
  }

  return(<>
    <Alert type="success" message={<h2>{header}</h2>} banner showIcon={false} />
    <Descriptions bordered column={1} size={'small'}>
    {array?.map((item) => {
      let itemInfo = getInfo(item);
      return(
        <Descriptions.Item label={<b>{itemInfo?.label}</b>} labelStyle={{ width: "25%" }}>
          {itemInfo?.value}
        </Descriptions.Item>
      )
      })}
    </Descriptions>
  </>)
}

export default BlockInfo;
