import React from 'react';
import { Collapse } from 'antd';
import ProjectItem from './ProjectItem';
// import ProjectExtra from "./ProjectExtra";

const { Panel } = Collapse;


const ProjectsCollapse = ({ projects, tasks }) => {

  const projectsPanelJSX = projects?.map((project) => {
    const projectTasks = tasks?.filter((task) => task.phase.project.id === project.id);

    return (
      <Panel 
        header={<b>{project.name}</b>} 
        key={project.id} 
        // extra={<ProjectExtra tasks={projectTasks}/>}
        >
          <ProjectItem tasks={projectTasks} />
      </Panel>
    );
  });

  return <div className='projectCollapse__wrapper'>
    <Collapse accordion>{projectsPanelJSX}</Collapse>
  </div>;
};

export default ProjectsCollapse;
