import React, { useState } from 'react'
import { Modal, Form, Input, Select, Checkbox, Button } from 'antd';


const { Item } = Form;

export const AddDeveloperModal = ({show, closeModal}) => {
	const [user, setUser] = useState(null);
	const [phase, setPhase] = useState(null);
	const [stateIsLeader, setStateIsLeader] = useState(null);
	const [form] = Form.useForm();


	const notRealOptions = [
		{
			value: '1',
			label: 'Not Identified',
		},
		{
			value: '2',
			label: 'Closed',
		},
		{
			value: '3',
			label: 'Communicated',
		},
		{
			value: '4',
			label: 'Identified',
		},
		{
			value: '5',
			label: 'Resolved',
		},
		{
			value: '6',
			label: 'Cancelled',
		},
	]

  return (
		<Modal
			title="Add developer to phase"
			centered
			open={show}
			onOk={() => {
				closeModal();
			}}
			onCancel={() => closeModal()}
			footer={[]}
		>
			<div className='project'>
				<Form
					form={form}
					onFinish={(e) => {
						console.log(e);
						form.resetFields();
						closeModal();
					}}
					onFinishFailed={(failedValues) => console.log({failedValues})}
				>
					<Item 
						name='user' 
						label='User'
						required
						rules={[{required: true, message: "Please enter user"}]}
					>
						<Select
							value={user}
							onChange={(value) => setUser(value)}
							showSearch
							style={{ width: 200 }}
							placeholder="Search user"
							optionFilterProp="children"
							filterOption={(input, option) => (option?.label ?? '').includes(input)}
							filterSort={(optionA, optionB) =>
								(optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
							}
							options={notRealOptions}
						/>
					</Item>
					<Item
						name='phase' 
						label='Phase'
						required
						rules={[{required: true, message: "Please enter phase"}]}
					>
						<Select
							placeholder="Select phase"
							value={phase}
							onChange={(value) => setPhase(value)}
							options={notRealOptions}
						/>

					</Item>
					<Item name='isLeader' label='' valuePropName="checked">
						<Checkbox
							checked={!!stateIsLeader}
							onChange={(e) => setStateIsLeader(e.target.checked)}
						>isLeader</Checkbox>
					</Item>
					<div className='wrapperButton'>
						<Button htmlType='submit' type='primary'>Submit</Button>
					</div>
				</Form>
			</div>
		</Modal>
  )
}

export default AddDeveloperModal;
