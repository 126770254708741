import {
  GET_TASK_REQUEST,
  GET_TASK_SUCCESS,
  GET_TASK_FAILURE,
  GET_TASKS_REQUEST,
  GET_TASKS_SUCCESS,
  GET_TASKS_FAILURE,
  CREATE_TASKS_REQUEST,
  CREATE_TASKS_SUCCESS,
  CREATE_TASKS_FAILURE,
  UPDATE_TASKS_REQUEST,
  UPDATE_TASKS_SUCCESS,
  UPDATE_TASKS_FAILURE,
  DELETE_TASKS_REQUEST,
  DELETE_TASKS_SUCCESS,
  DELETE_TASKS_FAILURE,
} from '../actionTypes';
import axiosWH from '../../utils/axios';
import { tasksParser } from '../../utils/helpers';
import { showNotification } from '../../App';


const getTasksRequest = () => ({ type: GET_TASKS_REQUEST });
const getTasksSuccess = (tasks, projects, count) => ({ type: GET_TASKS_SUCCESS, tasks, projects, count });
const getTasksFailure = (error) => ({ type: GET_TASKS_FAILURE, error });

const getTaskRequest = () => ({ type: GET_TASK_REQUEST });
const getTaskSuccess = (task) => ({ type: GET_TASK_SUCCESS, task });
const getTaskFailure = (error) => ({ type: GET_TASK_FAILURE, error });

const createTasksRequest = () => ({ type: CREATE_TASKS_REQUEST });
const createTasksSuccess = (data) => ({ type: CREATE_TASKS_SUCCESS, data });
const createTasksFailure = (error) => ({ type: CREATE_TASKS_FAILURE, error });

const updateTasksRequest = () => ({ type: UPDATE_TASKS_REQUEST });
const updateTasksSuccess = (task) => ({ type: UPDATE_TASKS_SUCCESS, task });
const updateTasksFailure = (error) => ({ type: UPDATE_TASKS_FAILURE, error });

const deleteTasksRequest = () => ({ type: DELETE_TASKS_REQUEST });
const deleteTasksSuccess = (id) => ({ type: DELETE_TASKS_SUCCESS, id });
const deleteTasksFailure = (error) => ({ type: DELETE_TASKS_FAILURE, error });

export const getTasks = () => {
  return async (dispatch) => {
    try {
      dispatch(getTasksRequest());
      const response = await axiosWH.get('/api/v1/tasks', {
        params: {
          limit: 0,
        },
      });
      dispatch(
        getTasksSuccess(response.data.data.items, tasksParser(response.data.data.items), response.data.data.count)
      );
    } catch (e) {
      dispatch(getTasksFailure(e.response.data.errors));
    }
  };
};

export const getTask = (id) => {
  return async (dispatch) => {
    try {
      dispatch(getTaskRequest());
      const response = await axiosWH.get(`/api/v1/tasks/${id}`);
      dispatch(getTaskSuccess(response.data.data));
    } catch (e) {
      dispatch(getTaskFailure(e.response.data.errors));
    }
  };
};

export const createTasks = (taskData) => {
  return async (dispatch) => {
    try {
      dispatch(createTasksRequest());
      const response = await axiosWH.post('/api/v1/tasks', taskData);
      dispatch(createTasksSuccess(response.data.data));
      showNotification('success', 'Successfully created Task');
      return response.data.data;
    } catch (e) {
      dispatch(createTasksFailure(e.response.data.errors));
      showNotification('error',  e.response.data.errors[0] ?? 'Something went wrong!', 'Error');
      return false;
    }
  };
};

export const updateTasks = (id, task) => {
  return async (dispatch) => {
    try {
      dispatch(updateTasksRequest());
      await axiosWH.put(`/api/v1/tasks/${id}`, task);
      dispatch(updateTasksSuccess());
      dispatch(getTasks(0));
      showNotification('success', 'Successfully updated Task');
      return true;
    } catch (e) {
      showNotification('error',  e.response.data.errors[0] ?? 'Something went wrong!', 'Error');
      dispatch(updateTasksFailure(e.response.data.errors));
      return false;
    }
  };
};

export const deleteTasks = (id) => {
  return async (dispatch) => {
    try {
      dispatch(deleteTasksRequest());
      await axiosWH.delete(`/api/v1/tasks/${id}`);
      dispatch(deleteTasksSuccess(id));
      showNotification('success', 'Successfully deleted Task');

    } catch (e) {
      dispatch(deleteTasksFailure(e.response.data.errors));
      showNotification('error',  e.response.data.errors[0] ?? 'Something went wrong!', 'Error');
    }
  };
};
