import React, { useState } from 'react';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import CreateProjectModal from './CreateProjectModal';



export const CreateProjectButton = () => {
  const [createModalShow, setCreateModalShow] = useState(false);

  return (
    <>
			<Button icon={<PlusOutlined />} onClick={() => setCreateModalShow(true)}>New project</Button>
			<CreateProjectModal
				// id={id}
				show={createModalShow}
				closeModal={() => setCreateModalShow(false)}
			/>
    </>
  )
}
