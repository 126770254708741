import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { loadFromLocalStorage, saveToLocalStorage } from './localStorage';
import userReducer from './reducers/userReducer';
import tasksReducer from './reducers/tasksReducer';
import newsReducer from './reducers/newsReducer';
import phasesReducer from './reducers/phasesReducer';
import contactsReducer from './reducers/contactsReducer';
import projectsReducer from './reducers/projectsReducer';
import dayOffsReducer from './reducers/daysoffsReducer';
import birthdaysReducer from './reducers/birthdaysReducer';
import hoursReducer from './reducers/hoursReducers';
import calendarReducer from './reducers/calendarReducer';
import reportReducer from './reducers/reportReducer';

const rootReducer = combineReducers({
  user: userReducer,
  tasks: tasksReducer,
  news: newsReducer,
  phases: phasesReducer,
  contact: contactsReducer,
  projects: projectsReducer,
  dayOff: dayOffsReducer,
  birthdays: birthdaysReducer,
  hours: hoursReducer,
  calendar: calendarReducer,
  report: reportReducer,
});

const preloadedState = loadFromLocalStorage('user');

const store = createStore(rootReducer, preloadedState, composeWithDevTools(applyMiddleware(thunk)));

store.subscribe(() => {
  saveToLocalStorage('user', {
    user: store.getState().user,
  });
});

export default store;
