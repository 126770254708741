import React from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { Row, Col, Alert } from "antd";
import NavigationItem from './NavigationItem';

const Navigation = ({columns, setColumns}) => {
  const onDragEnd = (result) => {
    if (!result.destination) return;
    const { source, destination } = result;
    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      });
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      });
    }
  };
  return (
      <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
        <Row gutter={16}>
          {Object.entries(columns).map(([columnId, column]) => {
            return (
              <Col span={12} key={columnId}>
                <Droppable droppableId={columnId}>
                  {(provided) => (
                    <div className="settings__navigation_list" ref={provided.innerRef} {...provided.droppableProps}>
                      <Alert message={columnId} type="info" style={{textAlign: 'center', textTransform: 'capitalize'}} />
                      {column.items.map((item, index) => {
                        return <NavigationItem key={item.title} item={item} index={index} />;
                      })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </Col>
            );
          })}
        </Row>
      </DragDropContext>
  );
};

export default Navigation;
