import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { loginUser } from '../../store/actions/userActions';
import { Button, Checkbox, Image, Input, Typography, Row, Col, Spin } from 'antd';
import './styles.scss';

const { Title } = Typography;

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const error = useSelector((state) => state?.user?.error);
  const loading = useSelector((state) => state?.user?.loading);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const inputLoginRef = useRef(null);
  const inputPasswordRef = useRef(null);


  useEffect(() => {
    inputLoginRef.current.focus({
      cursor: 'end',
    });
  },[])


  const submitFormHandler = (e) => {
    e.preventDefault();
    dispatch(loginUser({ username, password }, navigate));
  };

  const handleKeypressInput = (e) => {
    if (e.keyCode === 13 || e.keyCode === 9) {
      inputPasswordRef.current.focus({
        cursor: 'end',
      });
    }
  };

  const handleKeypress = (e) => {
    if (e.keyCode === 13) {
      submitFormHandler(e);
    }
  };

  return (
    <Spin spinning={loading}>
      <Row className="login__container">
        <Col xs={24} sm={20} md={12} lg={8} xl={8} xxl={6}>
          <div className="login">
            <Image width={250} src={require('../../assets/images/logo.png')} alt="logo" preview={false} />
            <Title level={3} className="login__title">
              Welcome To WH
            </Title>
            {error && (
              <Title className="login__title-error" level={4}>
                {error}
              </Title>
            )}
            <form className="form">
              <Input
                ref={inputLoginRef}
                className={`form__item ${error ? 'form__error' : null}`}
                placeholder="Login"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                onPressEnter={handleKeypressInput}
              />
              <Input
                ref={inputPasswordRef}
                className={`form__item ${error ? 'form__error' : null}`}
                placeholder="Password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onPressEnter={handleKeypress}
              />
              <Checkbox className="form__checkbox">Remember me on this computer</Checkbox>
              <Button type="primary" className="login__button" onClick={submitFormHandler}>
                Login
              </Button>
              <Link className="login__link" to="/forgot-password">
                Forgot your password?
              </Link>
            </form>
          </div>
        </Col>
      </Row>
    </Spin>
  );
};

export default Login;
