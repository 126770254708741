import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { putCurrentUserSettings } from '../../store/actions/userActions';
import { Divider, Typography, Col, Row, Button, Tabs, Segmented } from 'antd';
import Container from '../../components/layout/Container/Container';
import Navigation from './Navigation/Navigation';
import DashboardSettings from './DashboardSettings/DashboardSettings';
import { titleLevel } from '../../utils/helpers';
import { defaultEmployeeNavigation, defaultDashboardSettings } from '../../utils/constants';
import './styles.scss';

const { Title, Text } = Typography;

const Settings = () => {
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.user.settings);
  const [key, setKey] = useState('1');
  const [columns, setColumns] = useState(settings?.navigation ? settings?.navigation : defaultEmployeeNavigation);
  // const [dashboardSettings, setDashboardSettings] = useState(settings?.dashboard ? settings?.dashboard : defaultDashboardSettings);
  const [dashboardSettings, setDashboardSettings] = useState(defaultDashboardSettings);

  const saveHandler = () => {
    dispatch(putCurrentUserSettings({ navigation: columns, dashboard: dashboardSettings }));
  };
  const options = [
    {
      label: <Text strong>Navigation</Text>,
      value: '1',
    },
    {
      label: <Text strong>Dashboard Settings</Text>,
      value: '2',
    },
    {
      label: <Text strong>Other Settings</Text>,
      value: '3',
    },
  ];
  const items = [
    {
      key: '1',
      label: `Navigation`,
      children: <Navigation columns={columns} setColumns={setColumns} />,
    },
    {
      key: '2',
      label: `Dashboard Settings`,
      children: <DashboardSettings settings={dashboardSettings} setSettings={setDashboardSettings}/>,
    },
    {
      key: '3',
      label: `Other Settings`,
      children: `coming soon...`,
    },
  ];
  return (
    <Container>
      <Row>
        <Col span={12}>
          <Title level={titleLevel()} style={{ margin: 0 }}>
            Settings
          </Title>
        </Col>
        <Col span={12} style={{ textAlign: 'right' }}>
          <Button type="primary" onClick={() => saveHandler()}>
            Save
          </Button>
        </Col>
      </Row>
      <Divider />
      <Tabs
        defaultActiveKey={2}
        activeKey={key}
        centered
        type="card"
        animated
        items={items}
        renderTabBar={() => {
          return (
            <Segmented block options={options} style={{ marginBottom: '10px' }} onChange={(value) => setKey(value)} />
          );
        }}
      />
    </Container>
  );
};

export default Settings;
