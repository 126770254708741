import React from 'react';
import { Button, Card, Table, Input } from 'antd';
import { getAccess } from '../../../utils/helpers';
import './style.scss';


const CustomTable = ({itemsInfo, tableType }) => {

  const getRightData = (elems) => {
    return elems?.map((elem) => {
      return {
        // ['date']: tableType === 'events' ? elem?.date : 'kl',
        ['date']: elem?.date,
        ['time']: elem?.time,
        ['title']: tableType === 'events' ? elem?.title : elem?.name,
        ['body']: tableType === 'events' ? elem?.description : elem?.type,
      }
    })
  }

  let rightData = getRightData(itemsInfo);

  const getDateSource = (dataElems) => { //dataEvents
    return dataElems?.map((elem, index) => {
      return (tableType === 'events' ? {
        key: `${index}`,
        date: {['date']: elem?.date, ['time']: elem?.time},
        event: elem,
      }
      : {
        key: `${index}`,
        date: elem?.date,
        holiday: elem,
      });

    });
  };

  // const dataSource = tableType === 'events' ? getDateSource(eventsInfo) : getDateSource(holidaysInfo);
  const dataSource = getDateSource(rightData);
  // console.log('dataSource: ', dataSource)
  
  const getRenderColumn = (date) => {
    if (tableType === 'events') return (
      <div className='wrapperDate'>
        <p>{date?.date}</p>
        <p>{date?.time}</p>
      </div>
    )
    if (tableType === 'holidays') return (
      <div className='wrapperDate'>
        <p>{date}</p>
      </div>
    )
  }
  

  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (date) => getRenderColumn(date)
    },
    {
      title: (tableType === 'events') ? 'Event' : 'Holiday',
      dataIndex: (tableType === 'events') ? 'event' : 'holiday',
      key: (tableType === 'events') ? 'event' : 'holiday',
      render: (event) => (
        <Card 
          size="small" 
          title={`${event?.title}`} 
          // extra={editAccess ? <Button type="text">Edit</Button> : <></>} 
          style={{ width: '100%', cursor: 'pointer' }}
          headStyle={{height: '45px', fontSize: '15px'}}
          // onClick={() => setViewModalShow(true)}
          >
          <p>{event?.body}</p>
        </Card>
      ),
    },
  ];
  console.log('columns: ', columns)

  return (
    <div className={`customTable ${tableType === 'holidays' ? 'holidaysType' : ''}`}>
      <Table dataSource={dataSource} columns={columns} />
    </div>
  )
}

export default CustomTable;