import React from 'react';
import Container from '../../components/layout/Container/Container';
import { Divider, Typography } from 'antd';
import { titleLevel } from '../../utils/helpers';
import CustomTable from '../../components/UI/CustomTable/CustomTable';
import CreateHoliday from './HolidaysModals/CreateHoliday';
import './style.scss';


const { Title } = Typography;

const Holidays = () => {
  let holidaysInfo = [
    {
      date: '18-08-2023',
      type: 'Holiday',
      name: 'День отдыха',
    },
    {
      date: '10-08-2023',
      type: 'Holiday',
      name: 'День Абакана',
    },
    {
      date: '15-08-2023',
      type: 'Worked',
      name: 'День зубочистки',
    },
    {
      date: '1-09-2023',
      type: 'Worked',
      name: 'День бантов и шариков',
    },
    {
      date: '17-09-2023',
      type: 'Worked',
      name: 'День стула',
    },
    {
      date: '1-09-2023',
      type: 'Worked',
      name: 'День потери трех рублей',
    },
  ]
  return (
    <Container>
      <div className="holidays__header">
        <Title level={titleLevel()}>Holidays</Title>

        {/* {createAccess ? <CreateEventButton/> : <></>} */}
        <CreateHoliday />
      </div>
      <Divider />
      <div className='customTableWrapper'>
        <CustomTable itemsInfo={holidaysInfo} tableType="holidays"/>
      </div>
    </Container>
  )
}

export default Holidays;