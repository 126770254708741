import React, { useState } from 'react';
import { Button } from 'antd';
import AddInfrastructureModal from './AddInfrastructureModal';



const AddInfrastructureButton = () => {
  const [modalShow, setModalShow] = useState(false);

  return (
    <>
			<Button onClick={() => setModalShow(true)}>
				Add
			</Button>
			<AddInfrastructureModal
				// id={id}
				show={modalShow}
				closeModal={() => setModalShow(false)}
			/>
    </>
  )
}

export default AddInfrastructureButton;
