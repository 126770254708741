import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getEmployeeHoursReport } from '../../store/actions/reportActions';
import { Link } from 'react-router-dom';
import { Col, Row, Spin, Typography, Divider, Descriptions, Button } from 'antd';
import Container from '../../components/layout/Container/Container';
import HoursReport from './HoursReport';
import { titleLevel, getFirstAndLastDayOfMonth, dateHelper } from '../../utils/helpers';
import { grades } from '../../utils/constants';
import './styles.scss';

const { Title } = Typography;

const Profile = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const hoursReport = useSelector((state) => state.report.hoursReport);
  const hoursReportLoading = useSelector((state) => state.report.hoursReportLoading);
  const [firstDay, lastDay] = getFirstAndLastDayOfMonth();

  useEffect(() => {
    dispatch(getEmployeeHoursReport(user.id, firstDay, lastDay));
  }, [dispatch, user, user.id]);

  const nextAttestationPoints = () => {
    const index = grades.findIndex((grade) => grade.name === user?.grade.name);
    return grades[index + 1]?.points;
  };

  return (
    <Spin spinning={hoursReportLoading} size="large">
      <Container>
        <Title level={titleLevel()}>Your Profile</Title>
        <Divider />
        <Row gutter={16}>
          <Col xs={24} sm={24} md={24} lg={14} style={{marginBottom: '10px'}}>
            <Row gutter={8}>
              <Col span={24}>
                <div className="profile__blockHeader">
                  <Title level={5} style={{ margin: 0, color: '#fff' }}>
                    {user?.firstName} {user?.lastName}
                  </Title>
                </div>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8}>
                <div className="profile__info_imageContainer">
                  <img
                    src={`${process.env.REACT_APP_WH_URL}/images/contacts/${user?.photo}`}
                    alt=""
                    className="profile__info_image"
                  />
                </div>
              </Col>
              <Col xs={24} sm={24} md={16} lg={16}>
                <Descriptions bordered column={1} size={'small'}>
                  <Descriptions.Item label="Team" labelStyle={{ width: '40%' }}>
                    {user?.team?.name.toUpperCase()}
                  </Descriptions.Item>
                  <Descriptions.Item label="Grade">{user?.grade?.name}</Descriptions.Item>
                  <Descriptions.Item label="Location">{user?.location?.name}</Descriptions.Item>
                  <Descriptions.Item label="Room">{user?.room}</Descriptions.Item>
                  <Descriptions.Item label="Email">{user?.email}</Descriptions.Item>
                  <Descriptions.Item label="Home Phone">{user?.homePhone}</Descriptions.Item>
                  <Descriptions.Item label="Cell Phone">{user?.cellPhone}</Descriptions.Item>
                  <Descriptions.Item label="Skype">{user?.skype}</Descriptions.Item>
                  <Descriptions.Item label="Date of birth">{user?.birthday}</Descriptions.Item>
                  <Descriptions.Item label="Schedule">{user?.schedule}</Descriptions.Item>
                </Descriptions>
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={24} lg={10}>
            <Row>
              <Col span={24}  style={{marginBottom: '10px'}}>
                <div className="profile__blockHeader">
                  <Title level={5} style={{ margin: 0, color: '#fff' }}>
                    Hours info
                  </Title>
                  <Link to="/report/hours">
                    <Button size="small" ghost>
                      Full hours report
                    </Button>
                  </Link>
                </div>
                <HoursReport data={hoursReport?.report.brief} />
              </Col>
              <Col span={24}  style={{marginBottom: '10px'}}>
                <div className="profile__blockHeader">
                  <Title level={5} style={{ margin: 0, color: '#fff' }}>
                    Grade and Points info
                  </Title>
                  <Link to="/report/bonus">
                    <Button size="small" ghost>
                      Full bonus report
                    </Button>
                  </Link>
                </div>
                <Descriptions bordered column={1} size={'small'}>
                  <Descriptions.Item label="Current grade" labelStyle={{ width: '60%' }}>
                    {user?.grade?.name}
                  </Descriptions.Item>
                  <Descriptions.Item label="Recent attestation was">
                    {user?.lastAttestationDate ? dateHelper(user?.lastAttestationDate) : '-'}
                  </Descriptions.Item>
                  <Descriptions.Item label="Current points sum (from last attestation)">
                    {user?.grade?.points}
                  </Descriptions.Item>
                  <Descriptions.Item label="Points needed for next attestation">{nextAttestationPoints()}</Descriptions.Item>
                </Descriptions>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Spin>
  );
};

export default Profile;
