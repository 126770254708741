import React from 'react';
import Container from "../../components/layout/Container/Container";
import { titleLevel } from '../../utils/helpers';
import { Typography, Divider, Button, Card, Table, Input } from 'antd';


const { Title } = Typography;


const Reports = () => {



  return (
    <Container>
			<Title level={titleLevel()}>Reports</Title>



    </Container>
  )
}

export default Reports;
