import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useResizeWindows from '../../../utils/hooks/useResizeWindows';
import { Link } from 'react-router-dom';
import { getHoursReport, getHoursReportSuccess } from '../../../store/actions/hoursActions';
import CustomizedEmpty from '../CustomizedEmpty/CustomizedEmpty';
import { hoursRefactor } from './helpers';
import { Alert, Button, Modal, Spin, Table, Divider, ConfigProvider } from 'antd';
import { dateHelper } from '../../../utils/helpers';
import eventIcon from '../../../assets/calendarIcons/eventIcon.png';
import holidayIcon from '../../../assets/calendarIcons/holidayIcon.png';
import workIcon from '../../../assets/calendarIcons/workIcon.png';

const HoursInfoModal = ({ date, close, data }) => {
  const dispatch = useDispatch();
  const width = useResizeWindows();
  const hours = useSelector((state) => state.hours.hours);
  const hoursLoading = useSelector((state) => state.hours.hoursLoading);
  useEffect(() => {
    date && dispatch(getHoursReport(date));
  }, [dispatch, date]);

  const customizeRenderEmpty = CustomizedEmpty("No hours");

  const columns = [
    {
      title: 'Phase',
      dataIndex: 'phase',
      key: 'phase',
      width: width < 750 ? '30%' : '20%',
    },
    {
      title: 'Task',
      dataIndex: 'task',
      key: 'task',
      width: width < 750 ? '50%' : '20%',
    },
    {
      title: 'Hours',
      dataIndex: 'hours',
      key: 'hours',
      width: width < 750 ? '20%' : '10%',
    },
    {
      title: 'Comment',
      dataIndex: 'comment',
      key: 'comment',
      render: (_, record) => (
        <>
          {record?.comment && (
            <span>{record?.comment.length < 45 ? record?.comment : record?.comment?.slice(0, 45) + '...'}</span>
          )}
        </>
      ),
    },
  ];

  const columnsAmount = () => {
    if (width <= 500) {
      return columns.slice(1, -1);
    } else if (width > 500 && width <= 750) {
      return columns.slice(0, -1);
    } else return columns;
  };


  return (
    <Modal
      title={`Hours / ${date}`}
      width={750}
      centered
      open={!!date}
      closable={true}
      onCancel={close}
      afterClose={() => dispatch(getHoursReportSuccess(null))}
      footer={
        <>
          {data?.locked ? (
            <Alert
              message="This date is locked."
              showIcon
              style={{ textAlign: 'left' }}
              type="error"
              action={
                <Link to={`/hours?date=${dateHelper(date)}`}>
                  <Button size="small" danger>
                    Go to hours
                  </Button>
                </Link>
              }
            />
          ) : (
            <Link to={`/hours?date=${dateHelper(date)}`}>
              <Button type="primary">Go to hours</Button>
            </Link>
          )}
        </>
      }
    >
      <Spin spinning={hoursLoading}>
        {data.holiday && (
          <>
            <img
              src={data.holiday.type === 'Holiday' ? holidayIcon : workIcon}
              alt=""
              style={{ height: '25px', marginRight: '10px' }}
            />
            <span>{data.holiday.name}</span>
            <Divider />
          </>
        )}
        {data.event && (
          <>
            <img
              src={eventIcon}
              alt=""
              style={{ height: '25px', marginRight: '10px' }}
            />
            <span>{data.event.title}</span>
            <Divider />
          </>
        )}
        <ConfigProvider renderEmpty={customizeRenderEmpty}>
          <Table dataSource={hoursRefactor(hours)} columns={columnsAmount()} pagination={false} bordered />
        </ConfigProvider>
      </Spin>
    </Modal>
  );
};

export default HoursInfoModal;
