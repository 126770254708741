// import moment from 'moment';

import dayjs from 'dayjs';
import { status_project, monthObject } from './constants';
import { notification } from 'antd';
import useResizeWindow from './hooks/useResizeWindows';
import { loadFromLocalStorage } from '../store/localStorage.js';
import { access } from './access';

export const notificationMessage = (message) => {
  return notification.error({
    message: message,
    placement: 'topRight',
  });
};

export const notificationSuccessMessage = (message) => {
  return notification.success({
    message: message,
    placement: 'topRight',
  });
}


export const tasksParser = (tasks) => {
  const projects = [];
  tasks.forEach((item) => {
    projects.push(item.phase.project);
  });
  const uniqueProjects = (xs) => {
    const seen = {};
    return xs.filter((x) => {
      const key = JSON.stringify(x);
      return !(key in seen) && (seen[key] = x);
    });
  };

  return uniqueProjects(projects);
};

// export const taskStatusParser = (taskStatus) => {
//   let res = status_project.filter((status) => status?.id === taskStatus)
//   return res && res?.length !== 0 ? [0]?.name : res;
//   // return status_project.filter((status) => status?.id === taskStatus)[0]?.name;
// };

export const dateHelper = (date, r = '-') => {
  // return moment(date).format(`YYYY${r}MM${r}DD`);
  return dayjs(date).format(`YYYY${r}MM${r}DD`);
};

export const errorRefactor = (str) => {
  const index = str.indexOf(':');
  return index === -1 ? str : str.slice(index + 2);
};

export const sorter = (a, b) => {
  if (a.name.toLowerCase() > b.name.toLowerCase()) {
    return 1;
  }
  if (a.name.toLowerCase() < b.name.toLowerCase()) {
    return -1;
  }
  return 0;
};

export const taskDataRefactor = (task) => {
  const taskRefactored = {};
  taskRefactored.user = task?.user.id;
  taskRefactored.estimationTime = task?.estimationTime;
  taskRefactored.name = task?.name;
  taskRefactored.descriptions = task?.descriptions;
  taskRefactored.startDate = task?.startDate;
  taskRefactored.endDate = task?.endDate;
  taskRefactored.currentStatus = task?.currentStatus;
  taskRefactored.phase = task?.phase.id;
  taskRefactored.taskManagerLink = task?.taskManagerLink;
  return taskRefactored;
};

export const hourRefactor = (hour) => {
  const hourRefactored = {};
  hourRefactored.id = hour.id;
  hourRefactored.amount = hour.amount;
  hourRefactored.comment = hour.comment;
  hourRefactored.status = hour.status;
  hourRefactored.user = hour.user.id;
  hourRefactored.date = hour.date;
  hourRefactored.task = hour.task.id;
  hourRefactored.phase = hour.phase.id;
  return hourRefactored;
};

export const isRussian = (text) => {
  let arr_ru = [
    'а',
    'б',
    'в',
    'г',
    'д',
    'е',
    'ё',
    'ж',
    'з',
    'и',
    'й',
    'к',
    'л',
    'м',
    'н',
    'о',
    'п',
    'р',
    'с',
    'т',
    'у',
    'ф',
    'х',
    'ц',
    'ч',
    'ш',
    'щ',
    'э',
    'ю',
    'я',
  ];
  let arr_RU = [
    'А',
    'Б',
    'В',
    'Г',
    'Д',
    'Е',
    'Ё',
    'Ж',
    'З',
    'И',
    'Й',
    'К',
    'Л',
    'М',
    'Н',
    'О',
    'П',
    'Р',
    'С',
    'Т',
    'У',
    'Ф',
    'Х',
    'Ц',
    'Ч',
    'Ш',
    'Щ',
    'Э',
    'Ю',
    'Я',
  ];
  const rus = arr_ru.concat(arr_RU);
  let value = false;
  rus.forEach((letter) => {
    if (text?.includes(letter)) value = true;
  });
  return value;
};

export const addAction = (hours) => {
  const resultArr = [];
  hours.forEach((item) => {
    resultArr.push({
      action: item.id.toString().includes('new-record') ? 'create' : 'update',
      id: item.id.toString().includes('new-record') ? null : item.id,
      data: {
        amount: item.amount,
        comment: item.comment,
        status: item.status,
        user: item.user,
        date: dateHelper(item.date),
        task: item.task,
      },
    });
  });
  return resultArr;
};

export const reduceHours = (data) => {
  return data.reduce((acc, value) => {
    if (value.amount) {
      return acc + parseFloat(value.amount);
    } else return acc;
  }, 0);
};

export const currentMonthYear = (date, fullDate = false) => {
  if (fullDate) {
    const month = date.slice(5, 7);
    const year = date.slice(0, -6);
    return `${monthObject[month]} ${year}`;
  } else {
    const month = date.slice(-2);
    const year = date.slice(0, -3);
    return `${monthObject[month]} ${year}`;
  }
};

export const getMonthNumber = (date) => {
  if (date[date.length - 2] === '0') {
    return date.slice(-1);
  } else {
    return date.slice(-2);
  }
};

export const titleLevel = () => {
  const width = useResizeWindow();
  return width > 900 ? 2 : 4;
};

export const getFirstAndLastDayOfMonth = (newDate = null) => {
  const getFirstDayOfMonth = (year, month) => {
    return new Date(year, month, 1);
  };
  const getLastDayOfMonth = (year, month) => {
    return new Date(year, month + 1, 0);
  };
  const date = newDate === null ? new Date() : new Date(newDate);
  const firstDay = dateHelper(getFirstDayOfMonth(date.getFullYear(), date.getMonth()));
  const lastDay = dateHelper(getLastDayOfMonth(date.getFullYear(), date.getMonth()));
  return [firstDay, lastDay];
};

export const hoursReportDetailedParser = (data) => {
  const result = [];
  Object.keys(data).forEach((date) => {
    data[date].forEach((item, index) => {
      result.push({ ...item, date: index === 0 ? date : '' });
    });
  });
  return result;
};

export const getClientsBirthdaysAccess = () => {
  const roles = loadFromLocalStorage('user').user.user.roles;
  // console.log('roles: ', roles);


  let rolesWithAccess = roles.filter((role) => (role === 'Admin' || role === 'PManager' || role === 'CCManager'));
  return rolesWithAccess.length === 0 ? false : true; 
}

export const getAccess = (endpoint, action) => {//(endpoint, action)
  
  const userRoles = loadFromLocalStorage('user').user.user.roles;
  // console.log('userRoles: ', userRoles)
  // console.log('access[endpoint]: ', access[endpoint])
  // console.log('access[endpoint]: ', access[endpoint].all)
  // console.log('access[endpoint][action]: ', access[endpoint][action])

  
  let setActionAccess = new Set([ ...access[endpoint].all, ...access[endpoint][action] ])
  let arrActionAccess = [...setActionAccess]; //полный массив доступа по специфическому действию
  
  const getAccessAction = () => {
    let isAdmin = !!userRoles.find(role => role === 'Admin');
    
    if (!isAdmin) {
      let usersAccessAllAction = [...access[endpoint]['all']];
      // console.log('usersAccessAllAction: ', usersAccessAllAction);

      const getIsUserFullAccess = () => {
        let arrayFullAccess = userRoles.map(
          (role) => !!usersAccessAllAction.find(accessAll => accessAll === role));
        return Boolean(arrayFullAccess.reduce((sum, item) => sum + item, false));
      }
      
      let isUserWithFullAccess = getIsUserFullAccess();
      // console.log('isUserWithFullAccess: ', isUserWithFullAccess);

      let isAccess = isUserWithFullAccess ? true : Boolean(userRoles.reduce((sum, current) => {

        let res = arrActionAccess.find(accessRole => accessRole === current)
        // console.log(res);
        return sum + !!res
      }, 0));

      return isAccess
    } else return isAdmin;
  }

  let isAccessAction = getAccessAction();
  // console.log('isAccessAction: ', isAccessAction);
  return isAccessAction;
  //массив ролей пользователя
  //массив ролей доступных
}

export const getUsersOptions = (arrUsers) => {
  const options = [];
  arrUsers.forEach(user => {
    options.push({
      value:`${user.lastName} ${user.firstName}`, label: `${user.lastName} ${user.firstName}`, id: user.id       
    })
  });
  return options;
}

export const getDatePickerValue = (value) => {
// export const getDatePickerValue = (value, label) => {
  let getIsDate = (input) => {
    if ( Object.prototype.toString.call(input) === "[object Date]" )
      return true;
    return false;  
  };

  let isDate = getIsDate(value);

  if (typeof(value) === 'string') return value;
  else {
    if (value?._d) value._d.toLocaleDateString('en-CA');
    else return value?.$d ? value?.$d.toLocaleDateString('en-CA') : value?.toLocaleDateString('en-CA');
  }
}

export const getUniqueArray = (nonUniqueArray) => { 
  //let testProjects = [{id:1, name: 'aaa'}, {id: 1, name: 'aaa'}, {id:2, name: 'bbb'}];
  let uniqueArray = nonUniqueArray.reduce((sum, current) => {
    let res = sum.find((itemSum) => itemSum.id === current.id);
    if (!res) sum.push(current);
    return sum;
  }, []);
  
  return uniqueArray;
}

//from small to large //first to last
export const sortedDates = (arr) => arr?.sort((a, b) => new Date(a?.startDate).getTime() - new Date(b?.startDate).getTime())

//last to first
export const sortedDatesReverse = (arr) => arr?.sort((a, b) => new Date(b?.startDate).getTime() - new Date(a?.startDate).getTime())


// export const sumTest = (a, b) => a + b;


export const getFullname = (person) => {
  console.log('person: ', person)
  let clientFirstName = person?.firstName ? person?.firstName : ''
  let clientLastName = person?.lastName ? person?.lastName : ''
  return `${clientFirstName} ${clientLastName}`;
}

export const getCutName = (person) => {
  let clientFirstName = person?.firstName ? person?.firstName : ''
  let clientLastName = person?.lastName ? `${person?.lastName?.slice(0, 1)}.` : ''
  return `${clientFirstName} ${clientLastName}`;
}

export const getEmptyChecking = (items) => { //clientInfo?.client?.companies
  if (items) {  
    if (items?.isArray) return items?.length === 0 ? false : true; //[]
    else return Object.keys(items)?.length === 0 ? false : true; //{}
  } else return false;
}

export const getAllClientsAndCompanies = (project) => {
  let arrayClients = project?.projectClients;

  const arrayNameWithCompany = !getEmptyChecking(arrayClients) ? [] : arrayClients?.reduce((sumNameWithCompany, projectClientsItem) => {
    const getCompanies = () => {
      let companyPrepared = !getEmptyChecking(projectClientsItem?.client?.companies) ? null : projectClientsItem?.client?.companies?.reduce(
        (sumCompanies, companyItem) => sumCompanies +`${sumCompanies === '' ? '' : ', '}${companyItem.name}`, '');
      return !!companyPrepared ? companyPrepared : '';
    }
    let res = {
      ['company']: getCompanies(), 
      ['client']: arrayClients?.length < 3 ? getFullname(projectClientsItem?.client) : getCutName(projectClientsItem?.client)
    }
    return sumNameWithCompany?.length === 0 ? [res] : [...sumNameWithCompany, res]
  }, []);

  const getAllItems = (arr, typeItem) => {
    let items = arr.reduce((sum, item) => {
      let isRepeated = `${sum}`?.includes(`${item[`${typeItem}`]}`);

      if (sum === '') return isRepeated ? sum : sum + item[`${typeItem}`];
      else {
        if (item[`${typeItem}`]) return isRepeated ? sum : `${sum}, ${item[`${typeItem}`]}`;
        else sum;
      }
      // else return item[`${typeItem}`] ? `${sum}, ${item[`${typeItem}`]}` : sum;
    },'');
    return items;
  }
  // let clients = getAllItems(arrayNameWithCompany, 'client');
  // let companies = getAllItems(arrayNameWithCompany, 'company');
  return {['clients']: getAllItems(arrayNameWithCompany, 'client'), ['companies']: getAllItems(arrayNameWithCompany, 'company')}
}

export const getColumnsData = (columnss, items, typeData) => { //items === tasks // projects

  let arrLabels = columnss?.map((column) => column.title);

  const getValue = (label, elem) => { //(label, task) data for mobile table 
    if (typeData === 'tasks') { //elem === task
      switch(label) {
        case 'phase': 
          return elem?.phase?.name;
        case 'date': 
          let start = new Date(elem?.startDate)?.toLocaleDateString('en-GB'); 
          let end = new Date(elem?.endDate)?.toLocaleDateString('en-GB');
          return `${start} - ${end}`;
        case 'status': 
          return elem?.currentStatus;
        case 'task': 
          return elem['name'];
        case 'header':
          return {
            ['workedHours']: elem?.workedHours, 
            ['estimationTime']: elem?.estimationTime,
            ['taskLabel']: elem?.name,
            ['phaseLabel']: elem?.phase?.name,
          }
        default: return elem[`${label}`];
      }
    }

    if (typeData === 'projects') { //elem === project
      let allClientsAndCompanies = getAllClientsAndCompanies(elem);
      // console.log('elem: ', elem)
      switch(label) {
        case 'client':
          return allClientsAndCompanies?.clients;
        case 'company':
          return allClientsAndCompanies?.companies;
        case 'contract':
          return elem?.contractType;
        case 'date':
          return new Date(elem?.createdAt)?.toLocaleDateString('en-CA'); //('en-GB')
        case 'header':
          return {
            ['name']: elem?.name,
            ['status']: elem?.status,
            ['client']: allClientsAndCompanies?.clients,
            ['company']: allClientsAndCompanies?.companies,
            ['date']: new Date(elem?.createdAt)?.toLocaleDateString('en-CA'),
          }
        default:
          return elem[`${label}`]; //source, status
      }
    }
  }

  let data = items?.map((item) => {
    let res = arrLabels?.reduce((prevValue, label) => {
      let currentLabel = label?.toLowerCase();
      if (prevValue?.length === 0) prevValue = {['id']: item.id, ['header']: getValue('header', item)}
      return {...prevValue, [`${currentLabel}`]: getValue(currentLabel, item)};
    }, []);
    return {...res};
  })
  // console.log('data: ', data)
  return data;
}

  export const getSortedAlphabet = (objectsArray, smth) => objectsArray?.sort((a, b) => a[smth] > b[smth] ? 1 : -1);

  export const getStringUppercase = (element) => {
    let arrElem = element.split('');
    arrElem[0] = arrElem[0].toUpperCase();
    return arrElem.join('');
  }
  

  export const sourcesOptions = [
    { value: null, label: 'All sources' },
    ...getSortedAlphabet([
      { value: 0, label: 'Elance'},
      { value: 1, label: 'HireRussians'},
      { value: 2, label: 'Sibers'},
      { value: 3, label: 'Guru'},
      { value: 4, label: 'Direct'},
      { value: 5, label: 'Repeated'},
      { value: 6, label: 'Other'}
    ], 'label')
  ];
  export const contractsOptions = [
    { value: null, label: 'All contracts' },
    ...getSortedAlphabet([
      { value: 0, label: 'FS/FP'},
      { value: 1, label: 'NFS/NFP'},
      { value: 2, label: 'NFS/FP'},
      { value: 3, label: 'FS/NFP'},
    ], 'label')
  ]
  export const statusesOptions = [
    { value: null, label: 'All statuses' },
    { value: 0, label: 'Open'},
    { value: 1, label: 'Closed'},
  ]

  export const estimatedOptions = [
    { value: null, label: 'All estimates' },
    { value: '0-100', label: '0-100 hours'},
    { value: '100-500', label: '100-500 hours'},
    { value: '500-50000', label: '500+ hours'},
  ]

  export const workedHoursOptions = [
    { value: null, label: 'All worked hours' },
    { value: '0-100', label: '0-100 hours'},
    { value: '100-500', label: '100-500 hours'},
    { value: '500-50000', label: '500+ hours'},
  ]

  export const getFilterOption = (input, option) => {
    const getLabel = () => {
      const getFullnameOrInitials = () => {
        if (input?.toLowerCase()?.length <= 3 && input?.includes(' ') && (input?.split(' ')?.length === 2)) {
          let arrLabel = option?.label?.toLowerCase().split(' ');
          let initialsArr = arrLabel.map((partFullname) => partFullname?.slice(0, 1))
          // console.log('INITIALS: ', initialsArr.join(' '))
          return initialsArr.join(' ');
        } else {
          // console.log('FULLNAME: ', option?.label?.toLowerCase())

          return option?.label?.toLowerCase()
        };
      }

      return option?.label?.toLowerCase() ? getFullnameOrInitials() : '';
    }

    return getLabel()?.includes(input.toLowerCase());
  } //дополнить фильтры фильтрацией firstName-lastName, lastName-firstname, f l, l f (инициалы)

  export const getFilterSort = (optionA, optionB) =>
  (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())

  export const getNiceRole = (personRole, roleType) => { //typeRole 'full' 'cut'
    let arrRole = personRole?.name?.split('');
    let indexes = [];
    let countIndex = 0;
    arrRole?.forEach((item) => {
      if (item === item?.toUpperCase()) {
        indexes?.push(countIndex)
      }
      countIndex++;
    })

    let arrRoleWords = indexes?.map((itemIndexes, index) => {
      let res = indexes[index+1] ? arrRole?.slice(itemIndexes, indexes[index+1]) : arrRole?.slice(itemIndexes)
      return res.join('');
    });


    const getCutRole = (currentRole) => {
      switch (currentRole) {
        case 'Project Manager': return 'PM';
        case 'Sales Manager': return 'Sales';
        case 'Customer Care Manager': return 'CC';
        default: return currentRole;
      }
    }

    // return arrRoleWords.join(' ').trim();
    let result = arrRoleWords.join(' ').trim();

    return roleType === 'full' ? result : getCutRole(result);
  }

  export const getDateIsNow = (item) => new Date(item)?.toLocaleDateString("en-CA") === new Date()?.toLocaleDateString('en-CA');

  export const checkEmptyAllFilters = (array) => {
    // console.log('ПРОВЕРКА НА ПУСТОТУ')
    let arrFilterItems = [...array];
    let arrayNonEmptyFilters = arrFilterItems?.filter((item, index) => {
      if (index === arrFilterItems?.length - 1) {
        // console.log('item: ', item, ' | ', !!item !== false)
        return !!item !== false; 
      }
      // if (index === 0 && item !== null) {
      if (index === 0) {
        // console.log('item: ', item, ' | ', item !== null ? false : true)
        return item !== null ? false : true;
      }
      if (!!Date.parse(`${item}`)) {
        // console.log('item: ', item, ' | ', !getDateIsNow(item))
        return !getDateIsNow(item);
      }
      else {
        // console.log('item: ', item, ' | ', item !== null && item!=='')
        return item !== null && item !== ''; //item !==null item!==''
      }
    })

    return arrayNonEmptyFilters?.length === 0;
  }

  // export const convertToObjSearchParams = (arr) => {
  //   let arrayObjects = arr?.length === 0 ? [] : arr?.map((item) => ({[`${item?.label}`]: `${item.value}`}))
  //   return Object.assign({}, ...arrayObjects);
  // }

  // export const convertToRightParams = (arrayInitialParams, forWhat) => {
  //   let arrArraysInitialParams = arrayInitialParams?.map((item) => [item?.label, item?.value])
  //   if (forWhat === 'server') {
  //     let nonEmptyArrArrays = arrArraysInitialParams?.filter((item) => 
  //       item[1] !== null || (item[1] === null && item[0] === 'userId'));
  //     return Object.fromEntries(nonEmptyArrArrays);
  //   } else {
  //     let nonEmptyArrInitialParams = arrArraysInitialParams?.filter((item) => 
  //       (item[1] !== null || item[1] === null && item[0] === 'userId') && item[1] !== '')
      
  //     let dataForFiltersRes = nonEmptyArrInitialParams?.map((elem) => {
  //       let isNumber = +elem[1] === 0 || (+elem[1] !== 0 && !!+elem[1] === true);
  //       if (elem[0] === 'userId') return {label: `${elem[0]}`, value: elem[1]}

  //       if (isNumber) return {label:`${elem[0]}`, value: +elem[1]} //number
  //       else return {label: `${elem[0]}`, value: elem[1]} //string, date
  //     })
  //     return dataForFiltersRes;
  //   }
  // }

export const getUniqueUsers = (passiveUsers_) => {
  let uniquePassiveUsers = []
  passiveUsers_?.forEach((item) => {
    let isUnique = !(uniquePassiveUsers?.find((elem) => {
      return elem?.user.id === item?.user?.id
    }));

    if (isUnique) uniquePassiveUsers.push(item);
  });

  return uniquePassiveUsers;
}

export const sumTest = (a, b) => a + b;