import React from 'react';
import Container from "../../components/layout/Container/Container";
import './styles.scss';

const Documents = () => {
  return (
    <Container>
      Documents
    </Container>
  );
};

export default Documents;
