import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Result } from 'antd';
import Container from '../../components/layout/Container/Container';

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <Button type="primary" onClick={() => navigate('/')}>
            Back Home
          </Button>
        }
      />
    </Container>
  );
};

export default NotFound;
