import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getContacts } from '../../store/actions/contactsActions';
import { getUsersBirthdays, getClientsBirthdays } from '../../store/actions/birthdaysActions';
import { Divider, Col, Row, Typography } from "antd";
import Container from '../../components/layout/Container/Container';
import MonthSelector from './MonthSelector';
import BirthdaysList from './BirthdaysList';
import { titleLevel, getClientsBirthdaysAccess } from "../../utils/helpers";
import './styles.scss';

const { Title } = Typography;

const Birthdays = () => {
  const dispatch = useDispatch();
  const usersBirthdays = useSelector((state) => state.birthdays.usersBirthdays);
  const clientsBirthdays = useSelector((state) => state.birthdays.clientsBirthdays);
  const clientsLoading = useSelector((state) => state.birthdays.clientsLoading);
  const usersLoading = useSelector((state) => state.birthdays.usersLoading);
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1);
  useEffect(() => {
    dispatch(getContacts());
  }, [dispatch]);
  useEffect(() => {
    if (currentMonth) {
      dispatch(getUsersBirthdays(currentMonth));
      dispatch(getClientsBirthdays(currentMonth));
    }
  }, [dispatch, currentMonth]);
  const clientsBirthdaysAccess = getClientsBirthdaysAccess();
  return (
    <Container>
      <div className="birthdays">
      <Title level={titleLevel()} className="birthdays__title">Birthdays</Title>

        {/* <Row> */}
          {/* <Col xs={12} sm={16} md={16} lg={16}> */}
            {/* <Title level={titleLevel()} className="birthdays__title">Birthdays</Title> */}
          {/* </Col> */}
          {/* <Col xs={12} sm={8} md={8} lg={8}> */}
            {/* <MonthSelector value={currentMonth} onChange={setCurrentMonth} /> */}
          {/* </Col> */}
        {/* </Row> */}
        <Divider />
        <MonthSelector value={currentMonth} onChange={setCurrentMonth} />
        <Row gutter={16}>
          <Col xs={24} sm={24} md={12}>
            <BirthdaysList
              title={'Employees'}
              data={usersBirthdays?.sort((a, b) => a.day - b.day)}
              loading={usersLoading}
              userType={'Employees'}
            />
          </Col>
          {clientsBirthdaysAccess 
          ? <Col xs={24} sm={24} md={12}>
            <BirthdaysList
              title={'Clients'}
              data={clientsBirthdays?.sort((a, b) => a.day - b.day)}
              loading={clientsLoading}
              userType={'Clients'}
            />
          </Col>
          :<></>}
        </Row>
      </div>
    </Container>
  );
};

export default Birthdays;
