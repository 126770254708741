import {
  GET_NEWS_REQUEST,
  GET_NEWS_SUCCESS,
  GET_NEWS_FAILURE,
  GET_SINGLE_NEWS_REQUEST,
  GET_SINGLE_NEWS_SUCCESS,
  GET_SINGLE_NEWS_FAILURE,
} from '../actionTypes';
import axiosWH from '../../utils/axios';

const getNewsRequest = () => ({ type: GET_NEWS_REQUEST });
const getNewsSuccess = (data) => ({ type: GET_NEWS_SUCCESS, data });
const getNewsFailure = (error) => ({ type: GET_NEWS_FAILURE, error });

const getSingleNewsRequest = () => ({ type: GET_SINGLE_NEWS_REQUEST });
export const getSingleNewsSuccess = (data) => ({ type: GET_SINGLE_NEWS_SUCCESS, data });
const getSingleNewsFailure = (error) => ({ type: GET_SINGLE_NEWS_FAILURE, error });

export const getNews = (query) => {
  return async (dispatch) => {
    try {
      dispatch(getNewsRequest());
      const response = await axiosWH.get(`/api/v1/news?${query}`);
      dispatch(getNewsSuccess(response.data));
    } catch (e) {
      dispatch(getNewsFailure(e));
    }
  };
};

export const getSingleNews = (id) => {
  return async (dispatch) => {
    try {
      dispatch(getSingleNewsRequest());
      const response = await axiosWH.get(`/api/v1/news/${id}`);
      dispatch(getSingleNewsSuccess(response.data.data));
    } catch (e) {
      dispatch(getSingleNewsFailure(e));
    }
  };
};
