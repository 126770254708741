import { GET_CALENDAR_REQUEST, GET_CALENDAR_SUCCESS, GET_CALENDAR_FAILURE } from '../actionTypes';
import axiosWH from '../../utils/axios';

const getCalendarRequest = () => ({ type: GET_CALENDAR_REQUEST });
const getCalendarSuccess = (data) => ({ type: GET_CALENDAR_SUCCESS, data });
const getCalendarFailure = (error) => ({ type: GET_CALENDAR_FAILURE, error });

export const getCalendar = (params = '') => {
  return async (dispatch) => {
    try {
      dispatch(getCalendarRequest());
      const response = await axiosWH.get(`/api/v1/calendar/${params}`);
      dispatch(getCalendarSuccess(response.data.data));
    } catch (error) {
      dispatch(getCalendarFailure(error));
    }
  };
};
