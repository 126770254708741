import React, {useState, useEffect} from 'react';
import { Card, Table, Button } from 'antd';
import { SaveFilled, DeleteFilled, EditFilled } from "@ant-design/icons";
import RemoveWrapper from '../../shared/RemoveWrapper/RemoveWrapper';
import AddFile from '../../../pages/Project/ProjectModals/AddFile/AddFile';
import CustomTooltip from '../../shared/Tooltip/Tooltip';
import { getFullname, getSortedAlphabet } from '../../../utils/helpers';
// import {ReactComponent as LockSvg} from '../../assets/images/lock2black.svg';
import {ReactComponent as RestoreSvg} from '../../../assets/images/restore.svg'
import './style.scss';


const InfrastructureCard = ({ 
  item, index, 
  cardsDeleting, setCardsDeleting, 
  setCardsArchiving, setCardsActive, 
  cardsArchiving, cardsActive,
  setUserId, setIsModalVisible,
	cardType,

}) => {
	// const cardType = "infrastructure" //comments
  //НУЖНА КНОПКА СОХРАНЕНИЯ ИЗМЕНЕНИЙ ПРИ УДАЛЕНИИ
  // console.log('item: ', item)

	const [afterAchieve, setAfterAchieve] = useState(false);
  const [deleteCard, setDeleteCard] = useState(null);

  useEffect(() => {
    let filteredDeleteCard = cardsDeleting?.filter((elem) => item?.id === elem)[0];
    setDeleteCard(filteredDeleteCard);
  }, [cardsDeleting]);

  // useEffect(() => {
  //   console.log('CHANGED afterAchieve: ', afterAchieve)
  // }, [afterAchieve]);

  const columns = [
    {
      title: '',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '',
      dataIndex: 'value',
      key: 'value',
    },
  ];

  let dataSource = [
    {
      key: `${index}`,
      name: 'Name',
      value: item?.name,
    },
    {
      key: `${index}`,
      name: 'URL',
      value: item?.url,
    },
    {
      key: `${index}`,
      name: 'Comment',
      value: item?.comment,
    },
  ];
  const getItemTypeInfo = (itemType) => {
    switch(itemType) {
      case 'VSC (Version Control System)': 
        return {
          color: 'green',
          title: 'VSC',
          tooltip: 'Version Control System',
        }
      case 'Task Management (Readmine)': 
        return {
          color: 'yellow',
          title: 'Task Management',
          tooltip: 'Readmine',
        }
      case 'Continuous Integration (Jenkins)': 
        return {
          color: 'red',
          title: 'CI',
          tooltip: 'Continuous Integration (Jenkins)',
        }
      case 'Hosting (Production server)': 
        return {
          color: 'blue',
          title: 'Hosting',
          tooltip: 'Production server',
        }
      case 'Sandbox (Demo Server)': 
        return {
          color: 'purple',
          title: 'Sandbox',
          tooltip: 'Demo Server',
        }
      case 'Communication (BazaCamp)': 
        return {
          color: 'purple',
          title: 'Communication',
          tooltip: 'BazaCamp',
        }
      case 'Bugtracking (Bugzilla)': 
        return {
          color: 'brown',
          title: 'Bugtracking',
          tooltip: 'Bugzilla',
        }
      case 'Code signing assets (Certificates, dev programs)': 
        return {
          color: 'green',
          title: 'Code signing assets',
          tooltip: 'Certificates, dev programs',
        }
    }
  }
  let itemTypeInfo = getItemTypeInfo(item?.type)

  //VSC (Version Control System)
  //Task Management (Readmine)
  //Continuous Integration (Jenkins)
  //Hosting (Production server)
  //Sandbox (Demo Server)
  //Communication (BazaCamp)
  //Bugtracking (Bugzilla)
  //Code signing assets (Certificates, dev programs)

  // let filteredDeleteHour = deleteHours?.filter((item) => row.id === item)[0];
  // let filteredDeleteCard = cardsDeleting?.filter((elem) => item?.id === elem)[0];

  let getNewArray = (arrDelete) => {
    // console.log('arrDelete: ', arrDelete);
    // console.log('item.id: ', item?.id)
    if (arrDelete?.length === 0) return [item?.id];
    if (arrDelete?.length === 1) {
      let res = new Set([arrDelete[0], item?.id]);
      return res?.size === 1 ? [item?.id] : [...new Set([arrDelete[0], item?.id])];
    }
    if (arrDelete?.length !== 0 && arrDelete?.length !== 1) return [...new Set([...arrDelete, item?.id])];
  }
	let getNewObjectsArray = (cards, newItem) => {
		if (cards?.length === 0) return [newItem];
		if (cards?.length === 1) {
			let res = new Set([cards[0], newItem]);
			console.log('res: ', res)
			console.log('[...new Set([cards[0], newArchivingItem]) ', [...new Set([cards[0], newItem])])
			return res?.size === 1 ? [newItem] : [...new Set([cards[0], newItem])];
		}
		if (cards?.length !== 0 && cards?.length !== 1) return [...new Set([...cards, newItem])];
	} 
  const handleDelete = () => {
    // console.log('______DELETE_____')
		if (cardType === 'active') {
			// console.log('TYPE ACTIVE')
			if (!afterAchieve) {
        let newArrDelete = getNewArray(cardsDeleting);
				if (cardsDeleting?.length !== newArrDelete?.length) setCardsDeleting(newArrDelete);

        // console.log('setCardsDeleting(newArrDelete): ', newArrDelete);
			} else setAfterAchieve(false);
		}
		if (cardType === 'archive') {
			// console.log('TYPE ARCHIVE')
      // setAfterAchieve(true);
			let newActiveItem = cardsArchiving?.filter((elem) => elem?.id === item?.id)[0];
			let newArrActive = getNewObjectsArray(cardsActive, newActiveItem);
			let newArrArchiving = cardsArchiving?.filter((elem) => elem?.id !== item?.id);
			setCardsArchiving(newArrArchiving);
      //getSortedAlphabet(info, 'type')
      // setCardsActive(newArrActive);
      setCardsActive(getSortedAlphabet(newArrActive, 'type'));
			setAfterAchieve(true);
		}
  }
  const handleArchive = () => {
		if (cardType === 'active') {
			// let newArrArchiving = getNewArray(cardsArchiving);
			let newArchivingItem = cardsActive?.filter((card) => card.id === item?.id)[0];
			let newArrArchiving = getNewObjectsArray(cardsArchiving, newArchivingItem)
	
			// if (cardsArchiving?.length !== newArrArchiving?.length) {
				let newArrActive = cardsActive?.filter((elem) => {
					// console.log('item.id: ', item?.id, ' ', elem?.id, ' :elem.id' )
					return item?.id !== elem?.id;
				});
				setCardsArchiving(newArrArchiving);
				setCardsActive(newArrActive);
			// }

		}
  }

  let prevCard = getSortedAlphabet(cardsActive, 'type')[index-1];
  let isShowType = prevCard?.type !== item?.type;
  return (
    <RemoveWrapper
      // condition = {filteredDeleteHour === row.id}
      // cancelDelete = {cancelDeleteHour}
      // condition = {filteredDeleteCard === item?.id}
      condition = {deleteCard === item?.id}

      buttonName = "deleting card"
      cancelDelete = {() => {
        let newArrDelete = cardsDeleting?.filter((elem) => item?.id !== elem);
        setCardsDeleting(newArrDelete ? newArrDelete : [])
			}}
    >
      <div className={`${itemTypeInfo?.color}`}>
        <Card 
          size="small" 
          title={isShowType ? <CustomTooltip title={`${itemTypeInfo?.tooltip}`} placement="right">
              {itemTypeInfo?.title} 
              </CustomTooltip> : <></>}
          extra={<div className="wrapperButtons">
            {cardType === 'active' ? <>
            
              <Button>
                <span className="buttonIcon"><EditFilled /></span>
                <span className="buttonText">Edit</span>
              </Button>
           
              <Button onClick={handleArchive}>
                <CustomTooltip title='Archive' placement="top">
                  <span className="buttonIcon"><SaveFilled /></span>
                </CustomTooltip>
                <span className="buttonText">Archive</span>
              </Button>
              <Button onClick={() => handleDelete()}>
                <span className="buttonIcon"><DeleteFilled className="icon"/></span>
                <span className="buttonText">Delete</span>
              </Button>
            </> : <></>}
            {cardType === 'archive' ? <>
            <Button onClick={() => handleDelete()}>
              <CustomTooltip title='Restore' placement="top">
                <span className="buttonIcon restore"><RestoreSvg className="icon"/></span>
              </CustomTooltip>
              <span className="buttonText">Restore</span>
            </Button>
            </> : <></>}
          </div>} 
          style={{ width: '100%' }}
        >
          <div className="firstSection">
            <div className="content">
              <Table dataSource={dataSource} columns={columns} pagination={false}/>
            </div>
            <div className="firstSectionFooter">
              <AddFile />
              <div className="wrapperSignatures">
                <div className="signature">
                <span>
                  <span className=''>Created by</span>
                  <span 
                  className="buttonModal"
                  onClick={() => {
                    setUserId(item?.author?.id);
                    setIsModalVisible(true);
                  }}
                  >{getFullname(item?.author)}</span> 
                </span>
                  <span className=''>{item?.date}</span>
                  </div>
                {/* <div className="signature">Updated by Admin Admin 2023-07-20</div> */}
              </div>
            </div>

          </div>
          <div className="secondSection">
            <hr/>
            {/* <div className="signature">Created by Admin Admin 2023-07-18</div> */}
          </div>
        </Card>
      </div>            
    </RemoveWrapper>
  );
}

export default InfrastructureCard;