import React from 'react';
import { Controller } from 'react-hook-form';
import { Input, Typography } from 'antd';

const { TextArea } = Input;

const FormTextArea = ({ title, placeholder, name, control, error, required = false }) => {
  return (
    <>
      <Controller
        name={name}
        control={control}
        rules={{ required: required ? `${title} is required` : false }}
        render={({ field: { onChange, value } }) => (
          <>
            <Typography className="field__label">
              {title} {required && <span className="required">*</span>}
            </Typography>
            <TextArea
              rows={3}
              value={value}
              onChange={onChange}
              placeholder={placeholder}
              status={error ? 'error' : null}
            />
          </>
        )}
      />
      {error && <p className="errorText">{error.message}</p>}
    </>
  );
};

export default FormTextArea;
