import React, { useState } from 'react';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import AddPhaseModal from './AddPhaseModal';



const AddPhaseButton = () => {
  const [modalShow, setModalShow] = useState(false);

  return (
    <>
			<Button 
				// type="primary" 
				icon={<PlusOutlined />} 
				onClick={() => setModalShow(true)}
			>
				Phase
			</Button>
			<AddPhaseModal
				// id={id}
				show={modalShow}
				closeModal={() => setModalShow(false)}
			/>
    </>
  )
}

export default AddPhaseButton;
