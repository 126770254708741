import {
  CREATE_TASKS_FAILURE,
  CREATE_TASKS_REQUEST,
  CREATE_TASKS_SUCCESS,
  DELETE_TASKS_FAILURE,
  DELETE_TASKS_REQUEST,
  DELETE_TASKS_SUCCESS,
  GET_TASK_FAILURE,
  GET_TASK_REQUEST,
  GET_TASK_SUCCESS,
  GET_TASKS_FAILURE,
  GET_TASKS_REQUEST,
  GET_TASKS_SUCCESS,
  UPDATE_TASKS_FAILURE,
  UPDATE_TASKS_REQUEST,
  UPDATE_TASKS_SUCCESS
} from "../actionTypes";

export const initialState = {
  tasks: [],
  projects: [],
  task: null,
  count: 0,
  loading: false,
  errors: []
};

const tasksReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TASKS_REQUEST:
      return { ...state, loading: true, errors: [] };
    case GET_TASKS_SUCCESS:
      return { ...state, loading: false, tasks: action.tasks, projects: action.projects, count: action.count };
    case GET_TASKS_FAILURE:
      return { ...state, loading: false, errors: action.error };
    case GET_TASK_REQUEST:
      return { ...state, loading: true };
    case GET_TASK_SUCCESS:
      return { ...state, loading: false, task: action.task };
    case GET_TASK_FAILURE:
      return { ...state, loading: false, errors: action.error };
    case CREATE_TASKS_REQUEST:
      return { ...state, loading: true, errors: [] };
    case CREATE_TASKS_SUCCESS:
      return { ...state, loading: false, tasks: [action.data, ...state.tasks], errors: [] };
    case CREATE_TASKS_FAILURE:
      return { ...state, loading: false, errors: action.error };
    case UPDATE_TASKS_REQUEST:
      return { ...state, loading: true, errors: [] };
    case UPDATE_TASKS_SUCCESS:
      return { ...state, loading: false, errors: [] };
    case UPDATE_TASKS_FAILURE:
      return { ...state, loading: false, errors: action.error };
    case DELETE_TASKS_REQUEST:
      return { ...state, loading: true };
    case DELETE_TASKS_SUCCESS:
      return { ...state, loading: false, tasks: [...state.tasks].filter((task) => task.id !== action.id) };
    case DELETE_TASKS_FAILURE:
      return { ...state, loading: false, errors: action.error };
    default:
      return { ...state };
  }
};

export default tasksReducer;
