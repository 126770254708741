import React from 'react';
import { List, Typography, ConfigProvider } from 'antd';
import UserInfoModalButton from "../../components/UI/UserInfoModal/UserInfoModalButton";
import CustomizedEmpty from '../../components/UI/CustomizedEmpty/CustomizedEmpty';
import { monthReverseObject } from '../../utils/constants';



// const BirthdaysList = ({ title, data, loading }) => {
const BirthdaysList = ({ title, data, loading, userType }) => {
  const customizeRenderEmpty = CustomizedEmpty("No birthdays");
  let now = new Date().toLocaleDateString('en-CA').split("-").slice(1).join('-');
  let monthNumber = monthReverseObject[title?.props?.children[1]];

  /** TO DO clients data **/
  return (
    <div className="birthdays__listContainer_list">
    <ConfigProvider renderEmpty={customizeRenderEmpty}>
      <List
        header={<div><b>{title}</b></div>}
        bordered
        // locale={{emptyText: 'No birthdays'}}
        loading={loading}
        dataSource={data ?? []}
        renderItem={(item,index) => {
          let getItemStyle = () => {
            let style = {justifyContent: 'flex-start'};
            if (data[index + 1]?.day === item?.day) {
              if (data[index - 1]?.day === item?.day) style = {...style, borderBlockEnd: '1px solid rgba(5,5,5,.00)', padding: "0px 15px 0px"};//middle
              else style = {...style, borderBlockEnd: '1px solid rgba(5,5,5,.00)', padding: "6px 15px 0px"}; //first 
            } else if (data[index - 1]?.day === item?.day) style = {...style, padding: "0px 15px 6px"}; //last
            return style;
          }
          let itemStyle = getItemStyle();

          const getCurrentBirthMonthDay = () => {
            let dayNumber = `${item?.day}`.split('').length === 1 ? `0${item?.day}` : `${item?.day}`;
            return`${monthNumber}-${dayNumber}`;
          }
          let currentBirthday = getCurrentBirthMonthDay();

          return(
          // <List.Item style={{ justifyContent: 'flex-start' }}>
          <List.Item style={itemStyle}>
            <div className={`birthdays__listContainer_mark ${currentBirthday === now ? 'isToday' : ''}`}>
              {data[index - 1]?.day !== item?.day && <Typography.Text mark>{item?.day}</Typography.Text>}
            </div>
            {/* {title === 'Clients' ? <p>{item.firstName} {item.lastName}</p> : <UserInfoModalButton user={item} title={title}/>} */}
            {title === 'Clients' ? <p>{item.firstName} {item.lastName}</p> : <UserInfoModalButton user={item} userType={userType}/>}

          </List.Item>
          )
        }
        }
      />

    </ConfigProvider>
    </div>
  );
};

export default BirthdaysList;
