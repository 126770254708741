import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Descriptions, Modal, Avatar, Space, Tooltip } from 'antd';
import { UserOutlined } from '@ant-design/icons';


const UserInfoModal = ({ id, open, close, userType }) => {
// const UserInfoModal = ({ id, open, close }) => {

  const contacts = useSelector((state) => state.contact?.contacts?.data);
  const clientsBirthdays = useSelector((state) => state.birthdays?.clientsBirthdays)
  const [ userState, setUserState ] = useState({});

  const getUsers = (type) => {
    switch(type){
      case 'Employees':
        return contacts?.items?.find((item) => item.id === id);
      case 'Clients':
        return clientsBirthdays?.find((item) => item.id === id);
      default:
        return {}
    }
  }
  useEffect(() => {
    let user = getUsers(userType);
    setUserState(user)

  }, []);

  useEffect(() => {
    let user = getUsers(userType);
    setUserState(user)

  }, [userType, id]);



  return (
    <Modal
      open={open}
      onCancel={close}
      footer={null}
      width={750}
      title={
        <Space direction={'horizontal'} size={'large'}>
          <Avatar
            size={150}
            icon={<UserOutlined />}
            src={`${process.env.REACT_APP_WH_URL === "https://mobile.wh.local" ? "https://wh.local" : process.env.REACT_APP_WH_URL}/images/${userType === 'Employees' ? 'contacts' : 'clients'}/${userState?.photo}`}
            // src={`${process.env.REACT_APP_WH_URL}/images/${userType === 'Employees' ? 'contacts' : 'clients'}/${userState?.photo}`}
            // src={`${process.env.REACT_APP_WH_URL}/images/'/${user?.photo}`}
          />
          <h3>
            {/* {user?.firstName} {user?.lastName} */}
            {userState?.firstName} {userState?.lastName}

          </h3>
        </Space>
      }
    >
      <Descriptions bordered column={1} size={'small'}>
        <Descriptions.Item label="Team" labelStyle={{ width: '40%' }}>
          {userState?.team?.name.toUpperCase()}
        </Descriptions.Item>
        <Descriptions.Item label="Location">{userState?.location?.name}</Descriptions.Item>
        <Descriptions.Item label="Room">{userState?.room}</Descriptions.Item>

        <Descriptions.Item label="Email">
          <Tooltip 
            title="To write a letter"
            overlayInnerStyle={{height: '28px', minHeight: '25px', padding: "2px 8px"}}
            placement="right" 
            color='rgb(135 208 104 / 85%)' key='rgb(135 208 104 / 85%)'
            >
            <a href={`mailto:${userState?.email}`}>{userState?.email}</a>
          </Tooltip>
        </Descriptions.Item>

        <Descriptions.Item label="Home Phone">
          <Tooltip 
            title="To call by phone" 
            placement="right" 
            overlayInnerStyle={{height: '28px', minHeight: '25px', padding: "2px 8px"}}
            color='rgb(135 208 104 / 85%)' key='rgb(135 208 104 / 85%)'
            >
            <a href={`tel:+${userState?.location?.name === 'Bishkek' ? 996111111111 : 79111111111}`}>
              {userState?.homePhone}
            </a>
          </Tooltip>
        </Descriptions.Item>

        <Descriptions.Item label="Cell Phone">
          <Tooltip 
            title="To call by phone" 
            placement="right" 
            overlayInnerStyle={{height: '28px', minHeight: '25px', padding: "2px 8px"}}
            color='rgb(135 208 104 / 85%)' key='rgb(135 208 104 / 85%)'
            >
            <a href={`tel:+${userState?.location?.name === 'Bishkek' ? 996111111111 : 79111111111}`}>
              {userState?.cellPhone}
            </a>
          </Tooltip>
        </Descriptions.Item>

        <Descriptions.Item label="Skype">
          <Tooltip 
            title="To call by Skype" 
            placement="right" 
            overlayInnerStyle={{height: '28px', minHeight: '25px', padding: "2px 8px"}}
            color='rgb(135 208 104 / 85%)' key='rgb(135 208 104 / 85%)'
            // color='#87d068' key='#87d068'
            >
            <a href={`skype:${userState?.skype}`}>{userState?.skype}</a>
          </Tooltip>
        </Descriptions.Item>

        <Descriptions.Item label="Date of birth">{userState?.birthday}</Descriptions.Item>
        <Descriptions.Item label="Schedule">{userState?.schedule}</Descriptions.Item>
      </Descriptions>
    </Modal>
  );
};

export default UserInfoModal;
