import React from 'react';
import { Controller } from 'react-hook-form';
import { Select, Typography } from 'antd';

const { Option } = Select;

const FormSelect = ({ name, control, required, error, title, options }) => {
  return (
    <>
      <Controller
        name={name}
        control={control}
        rules={{ required: required ? `${title} is required` : false }}
        render={({ field: { onChange, value } }) => (
          <>
            <Typography className="field__label">
              {title} {required && <span className="required">*</span>}
            </Typography>
            <Select className="tasks__field" value={value} status={error ? 'error' : null} onChange={onChange}>
              {options.map((item) => (
                <Option value={item.id} key={item.name}>
                  { name === 'phase' ? <><b>[{item?.project?.name}]</b> {item.name}</> : item.name}
                </Option>
              ))}
            </Select>
          </>
        )}
      />
      {error && <p className="errorText">{error.message}</p>}
    </>
  );
};

export default FormSelect;
