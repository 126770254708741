import React from 'react';

export const HappySmile = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
      <defs></defs>
      <g transform="translate(-578 -790)">
        <circle className="a" style={{ fill: '#b0f25a' }} cx="18" cy="18" r="18" transform="translate(578 790)" />
        <g transform="translate(585.062 796.774)">
          <circle
            className="b"
            style={{ fill: 'none', stroke: '#6da721', strokeMiterlimit: 10, strokeWidth: '2px' }}
            cx="11"
            cy="11"
            r="11"
            transform="translate(-0.062 0.226)"
          />
          <path
            className="c"
            style={{
              fill: 'none',
              stroke: '#6da721',
              strokeMiterlimit: 10,
              strokeWidth: '2px',
              strokeLinecap: 'round',
            }}
            d="M-124.869-394.44c0,1.463-1.458,2.65-3.256,2.65s-3.256-1.186-3.256-2.65Z"
            transform="translate(139.108 408.384)"
          />
          <path
            className="c"
            style={{
              fill: 'none',
              stroke: '#6da721',
              strokeMiterlimit: 10,
              strokeWidth: '2px',
              strokeLinecap: 'round',
            }}
            d="M-134.684-404.053a1.51,1.51,0,0,0-1.638-1.334,1.51,1.51,0,0,0-1.639,1.334"
            transform="translate(141.893 413.146)"
          />
          <path
            className="c"
            style={{
              fill: 'none',
              stroke: '#6da721',
              strokeMiterlimit: 10,
              strokeWidth: '2px',
              strokeLinecap: 'round',
            }}
            d="M-116.173-404.053a1.51,1.51,0,0,0-1.639-1.334,1.51,1.51,0,0,0-1.638,1.334"
            transform="translate(133.818 413.146)"
          />
        </g>
      </g>
    </svg>
  );
};

