import React, { useState } from 'react';
import { Modal, Button } from 'antd';



const ViewEventModal = ({show, closeModal}) => {
  const event = {
    title: "День независимости программистов!",
    date: "29/05/2023",
    description: "njejrf erfgekrng rtg krntkgvn vdnkfne",
  }

  return (
    <Modal
      title={`${event?.title}`}
      centered
      open={show}
      onCancel={() => closeModal()}
      footer={[
        <Button key="back" onClick={() => closeModal()}>Close</Button>
      ]}
    >
    <div>
      <p><b>Date:</b></p>
      <div>{event?.date}</div>
    </div>
    <div>
      <p><b>Description:</b></p>
      <div>{event?.description}</div>
    </div>

    </Modal>
  )
}

export default ViewEventModal;
