import React from 'react';
import { useDispatch } from 'react-redux';
import { logoutUser } from '../../../store/actions/userActions';
import { Link, useNavigate } from 'react-router-dom';
import { Avatar, Dropdown } from 'antd';
import { RightOutlined, UserOutlined, CaretRightOutlined } from '@ant-design/icons';
import './styles.scss';

const ProfileLink = ({ name, photo }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const logOutHandler = () => {
    dispatch(logoutUser());
    navigate('/login');
  };

  //добавить стрелочки
  let array = ['profile', 'settings'];

  const getItems = (arrayItems) => {
    let hiddenMenuItems = arrayItems?.map((elem, index) => ({
      key: `${index}`,
      label: (
        <Link to={`/${elem}`} className="header__navLink">
          <div className="header__dropdownLink">
            <span>{elem}</span> <CaretRightOutlined className="header__navLink_arrowRight"/>
          </div>
        </Link>
      ),
    }));

    hiddenMenuItems.push({
      key: `${arrayItems?.length}`,
      label: (
        <div className="header__dropdownLink" onClick={() => logOutHandler()}>
          Logout
        </div>
      ),
    });
    return hiddenMenuItems;
  }
  // const items = [
  //   {
  //     key: '1',
  //     label: (
  //       <Link to="/profile" className="header__navLink">
  //         <div className="header__dropdownLink">
  //           profile <RightOutlined />
  //         </div>
  //       </Link>
  //     ),
  //   },
  //   {
  //     key: '2',
  //     label: (
  //       <Link to="/settings" className="header__navLink">
  //         <div className="header__dropdownLink">
  //           settings <RightOutlined />
  //         </div>
  //       </Link>
  //     ),
  //   },
  //   {
  //     key: '3',
  //     label: (
  //       <div className="header__dropdownLink" onClick={() => logOutHandler()}>
  //         Logout
  //       </div>
  //     ),
  //   },
  // ];
  const items = getItems(array);
  
  return (
    <Dropdown menu={{ items }} placement="bottomRight" arrow>
      <div className="profileLink">
        <Avatar
          size="large"
          icon={<UserOutlined />}
          src={`${process.env.REACT_APP_WH_URL}/images/contacts/${photo}`}
        />
        <h4 className="profileLink__name">{name}</h4>
      </div>
    </Dropdown>
  );
};

export default ProfileLink;
