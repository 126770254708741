import React from "react";

const Subscriptions = ({data}) => {
  return (
    <div>
      Subscriptions
    </div>
  );
};

export default Subscriptions;
