import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getCalendar } from '../../../store/actions/calendarActions';
import { Divider } from 'antd';
import Days from './Days';
import Cell from './Cell';
import CalendarMonthInfo from './CalendarMonthInfo';
import './styles.scss';

const Calendar = ({currentDate}) => {
  const dispatch = useDispatch();
  const calendar = useSelector((state) => state.calendar.calendar);

  useEffect(() => {
    dispatch(getCalendar(currentDate.replace('-', '/')));
  }, [dispatch, currentDate]);
  const cellsJSX =
    calendar &&
    calendar?.days &&
    Object.keys(calendar?.days).map((cell) => {
      return <Cell key={cell} date={cell} calendar={calendar?.days} currentDate={currentDate} />;
    });
  return (
    <div className="calendar">
      <Days />
      <Divider />
      <div className="calendar__cells">{cellsJSX}</div>
      <Divider />
      <div className="calendar__footer">
        <CalendarMonthInfo calendar={calendar} date={currentDate} />
      </div>
    </div>
  );
};

export default Calendar;
