import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser } from '../../../store/actions/userActions';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { Drawer, Divider, Avatar } from 'antd';
import { UserOutlined, CloseOutlined } from '@ant-design/icons';
import { defaultEmployeeNavigation } from '../../../utils/constants';
import useResizeWindows from '../../../utils/hooks/useResizeWindows';

const HeaderDrawer = ({ open, close, photo, name }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.user.settings);
  const linksActive = settings?.navigation ? settings?.navigation?.active : defaultEmployeeNavigation.active;
  let width = useResizeWindows();

  const logOutHandler = () => {
    dispatch(logoutUser());
    navigate('/login');
    close();
  };
  const linksJSX = linksActive?.items.map((link) => {
    return (
      <React.Fragment key={link.id}>
        <NavLink
          onClick={close}
          to={link.title === 'calendar' ? '/' : `/${link.title}`}
          className={({ isActive }) => (isActive ? 'drawer__navLinkActive' : 'drawer__navLink')}
        >
          {link.title}
        </NavLink>
        <Divider className="drawer__divider" />
      </React.Fragment>
    );
  });

  return (
    <Drawer
      title={
        <div className="drawer__user">
          <Avatar
            size="large"
            icon={<UserOutlined />}
            src={`${process.env.REACT_APP_WH_URL}/images/contacts/${photo}`}
          />
          <Link to="/profile" onClick={close} className="drawer__user_name">
            <h4 className="drawer__user_name">{name}</h4>
          </Link>
          <CloseOutlined className="drawer__user_closeIcon" onClick={close} />
        </div>
      }
      closable={false}
      placement="right"
      onClose={close}
      open={open}
      width={width > 450 ? 310 : 230}

    >
      <div className="drawer__navLinks">
        {linksJSX}
        <NavLink
          onClick={close}
          to={'/settings'}
          className={({ isActive }) => (isActive ? 'drawer__navLinkActive' : 'drawer__navLink')}
        >
          settings
        </NavLink>
        <Divider className="drawer__divider" />
        <div className="drawer__navLink" onClick={() => logOutHandler()}>
          Logout
        </div>
      </div>
    </Drawer>

  );
};

export default HeaderDrawer;
