import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as EventSvg } from '../../../assets/images/event.svg';

import './styles.scss';

const Container = ({ children }) => {
  useEffect(() => {
    window.scrollTo(0,0);

  }, []);

  //PS Пока данные на calendar непрогружены, ни натодной странице нет event'а

  const calendar = useSelector((state) => state.calendar.calendar);
  let now = new Date().toLocaleDateString('en-CA');
  // console.log('----------')
  // console.log('now: ', now)
  // console.log('calendar: ', calendar)

  const getEvent = (calendar_) => {
    if (calendar_) {
      if (calendar_?.days) return calendar_?.days[now] ? calendar_?.days[now]?.event : calendar_?.days[now]
      else return calendar_?.days;
    }
    else return calendar_;
  }
  let event = calendar?.hasOwnProperty('days') ? getEvent(calendar) : calendar?.event;
  // let event = !calendar ? calendar : calendar?.days[now] ? calendar?.days[now] : calendar?.days[now]?.event;
  // console.log('event: ', event)
  
  return (
    <div className={`container ${event ? 'eventContainer' : ''}`}>
      {event && <div className='eventHeader'>
        <div className='eventWrapper'>
          <EventSvg /> <span className='eventDate'>Today 17:00</span> {event?.title} Международный женский день

        </div>
        </div>}
      {children}
    </div>);
};

export default Container;
