import {
  GET_DAYOFFS_REQUEST,
  GET_DAYOFFS_SUCCESS,
  GET_DAYOFFS_FAILURE,

  CREATE_DAYOFF_REQUEST,
  CREATE_DAYOFF_SUCCESS,
  CREATE_DAYOFF_FAILURE,

  UPDATE_DAYOFF_REQUEST,
  UPDATE_DAYOFF_SUCCESS,
  UPDATE_DAYOFF_FAILURE,

  GET_DAYOFF_REQUEST,
  GET_DAYOFF_SUCCESS,
  GET_DAYOFF_FAILURE,

  DELETE_DAYOFF_REQUEST,
  DELETE_DAYOFF_SUCCESS,
  DELETE_DAYOFF_FAILURE
} from "../actionTypes";

const initialState = {
  dayOffs: null,
  dayOff: null,
  dayOffsError: null,
  dayOffError: null,
  createDayOffError: null,
  deleteDayOffError: null,
  updateDayOffError: null,
  dayOffsLoading: false,
  dayOffLoading: false,
  createDayOffLoading: false,
  updateDayOffLoading: false
};

const dayOffsReducer = (state = initialState, action) => {
  switch (action.type) {
    /** GET ALL */
    case GET_DAYOFFS_REQUEST:
      return { ...state, dayOffsLoading: action.value };
    case GET_DAYOFFS_SUCCESS:
      return { ...state, dayOffs: action.data, dayOffsError: null };
    case GET_DAYOFFS_FAILURE:
      return { ...state, dayOffsError: action.error };
    /** GET SINGLE */
    case GET_DAYOFF_REQUEST:
      return { ...state, dayOffLoading: action.value };
    case GET_DAYOFF_SUCCESS:
      return { ...state, dayOff: action.data, dayOffError: null };
    case GET_DAYOFF_FAILURE:
      return { ...state, dayOffError: action.error };
    /** CREATE */
    case CREATE_DAYOFF_REQUEST:
      return { ...state, dayOffsLoading: action.value };
    case CREATE_DAYOFF_SUCCESS:
      const dayoffsCopy = [...state.dayOffs];
      dayoffsCopy.push(action.data);
      return { ...state, dayOffs: dayoffsCopy, createDayOffError: null };
    case CREATE_DAYOFF_FAILURE:
      return { ...state, createDayOffError: action.error };
    /** UPDATE */
    case UPDATE_DAYOFF_REQUEST:
      return { ...state, updateDayOffLoading: action.value };
    case UPDATE_DAYOFF_SUCCESS:
      const updatedDayoffs = [...state.dayOffs];
      const index = updatedDayoffs.findIndex(item => item.id === action.data.id);
      updatedDayoffs[index] = action.data;
      return { ...state, dayOffs: updatedDayoffs, updateDayOffError: null };
    case UPDATE_DAYOFF_FAILURE:
      return { ...state, updateDayOffError: action.error };
    /** DELETE */
    case DELETE_DAYOFF_REQUEST:
      return {...state, updateDayOffLoading: action.value};
    case DELETE_DAYOFF_SUCCESS:
      const filteredDayoffs = state.dayOffs.filter(item => item.id !== action.id);
      return { ...state, dayOffs: filteredDayoffs, deleteDayOffError: null };
    case DELETE_DAYOFF_FAILURE:
      return { ...state, deleteDayOffError: action.error };
    default:
      return state;
  }
};
export default dayOffsReducer;
