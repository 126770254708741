import {
  LOGIN_USER_FAILURE,
  LOGIN_USER_REQUEST,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  GET_CURRENT_USER_SETTINGS_REQUEST,
  GET_CURRENT_USER_SETTINGS_SUCCESS,
  GET_CURRENT_USER_SETTINGS_FAILURE,
  PUT_CURRENT_USER_SETTINGS_REQUEST,
  PUT_CURRENT_USER_SETTINGS_SUCCESS,
  PUT_CURRENT_USER_SETTINGS_FAILURE,
} from "../actionTypes";

export const initialState = {
  user: null,
  loading: false,
  error: null,
  settings: null,
  getSettingsLoading: false,
  getSettingsError: null,
  putSettingsLoading: false,
  putSettingsError: null,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER_REQUEST:
      return { ...state, loading: true };
    case LOGIN_USER_SUCCESS:
      return { ...state, loading: false, user: action.user };
    case LOGIN_USER_FAILURE:
      return { ...state, loading: false, error: action.error };
    case LOGOUT_USER:
      return { ...state, user: null };
    case GET_CURRENT_USER_SETTINGS_REQUEST:
      return {...state, getSettingsLoading: true};
    case GET_CURRENT_USER_SETTINGS_SUCCESS:
      return {...state, settings: action.data, getSettingsLoading: false, getSettingsError: null};
    case GET_CURRENT_USER_SETTINGS_FAILURE:
      return {...state, getSettingsError: action.error, getSettingsLoading: false};
    case PUT_CURRENT_USER_SETTINGS_REQUEST:
      return {...state, putSettingsLoading: true};
    case PUT_CURRENT_USER_SETTINGS_SUCCESS:
      return {...state, putSettingsLoading: false, putSettingsError: null};
    case PUT_CURRENT_USER_SETTINGS_FAILURE:
      return {...state, putSettingsError: action.error, putSettingsLoading: false}
    default:
      return { ...state };
  }
};

export default userReducer;
