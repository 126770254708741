import React from "react";
import Container from "../../components/layout/Container/Container";

const BonusReport = () => {
  return (
    <Container>

    </Container>
  );
};

export default BonusReport;
