import React from "react";
import { Link } from "react-router-dom";
import { Table } from "antd";
import { LockOutlined, UnlockOutlined } from "@ant-design/icons";
import MobileTable from "../../components/UI/MobileTable/MobileTable";
import useResizeWindows from "../../utils/hooks/useResizeWindows";
import { getFullname, getEmptyChecking, getAllClientsAndCompanies } from "../../utils/helpers";
import moment from "moment/moment";
// import {ReactComponent as UnlockSvg} from './unlock.svg';
import {ReactComponent as UnlockSvg} from '../../assets/images/unlock2black.svg';
import {ReactComponent as LockSvg} from '../../assets/images/lock2black.svg';



const ProjectsTable = ({ projectsList, page, setPage, limit, setLimit }) => { 
  const width = useResizeWindows();

  let projectsForTable = !projectsList ? projectsList : projectsList?.map((project) => {
    let allClientsAndCompanies = getAllClientsAndCompanies(project);
    let clients = allClientsAndCompanies?.clients;
    let companies = allClientsAndCompanies?.companies;

    return {...project, ...{['company']: companies, ['client']: clients}}
  })

  const columns = [
    {
      // title: "Project Name",
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_, record) => <Link to={`/projects/${record.id}/summary`}>{record.name}</Link>
    },
    {
      title: "Client",
      dataIndex: "client",
      key: "client"
    },
    {
      title: "Company",
      dataIndex: "company",
      key: "company"
    },
    {
      title: "Source",
      dataIndex: "source",
      key: "source"
    },
    {
      title: "Contract", //Contact
      dataIndex: "contractType",
      key: "contractType"
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, record) => (
        <>
          {record.status === "Open" ?
            <p>
            <div className="wrapperSvg"><UnlockSvg /></div>
            {/* {record.status} */}
            </p>
            :
            <p>
            <div className="wrapperSvg"><LockSvg /></div>
            {/* {record.status} */}
            </p>

            //LockSvg
            //<p><LockOutlined /> {record.status}</p> 
          }
        </>
      )
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (_, record) => <>{moment(record.createdAt).format("L")}</>
    }
  ];

  return (
    <>
      {width > 990 ? 
        <Table 
          columns={columns} 
          // dataSource={projectsList} 
          dataSource={projectsForTable} 

          bordered size={'small'}/>
        : <MobileTable 
          columns={columns} 
          items={projectsList} 
          typeData="projects"
          page={page}
          setPage={setPage}
          />
      }
    </>);
};

export default ProjectsTable;
