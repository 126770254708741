import {
  GET_NEWS_SUCCESS,
  GET_NEWS_FAILURE,
  GET_SINGLE_NEWS_SUCCESS,
  GET_SINGLE_NEWS_FAILURE,
  GET_NEWS_REQUEST,
  GET_SINGLE_NEWS_REQUEST,
} from '../actionTypes';

const initialState = {
  news: null,
  singleNews: null,
  newsLoading: false,
  singleNewsLoading: false,
  getNewsError: null,
  getSingleNewsError: null,
};

const newsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_NEWS_REQUEST:
      return { ...state, newsLoading: true };
    case GET_NEWS_SUCCESS:
      return { ...state, news: action.data, getNewsError: null, newsLoading: false };
    case GET_NEWS_FAILURE:
      return { ...state, getNewsError: action.error, newsLoading: false };
    case GET_SINGLE_NEWS_REQUEST:
      return { ...state, singleNewsLoading: true };
    case GET_SINGLE_NEWS_SUCCESS:
      return { ...state, singleNews: action.data, getSingleNewsError: null, singleNewsLoading: false };
    case GET_SINGLE_NEWS_FAILURE:
      return { ...state, getSingleNewsError: action.error, singleNewsLoading: false };
    default:
      return { ...state };
  }
};

export default newsReducer;
