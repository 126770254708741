import {
  GET_CONTACTS_REQUEST,
  GET_CONTACTS_SUCCESS,
  GET_CONTACTS_FAILURE,
  GET_LOCATIONS_REQUEST,
  GET_LOCATIONS_SUCCESS,
  GET_LOCATIONS_FAILURE,
  GET_TEAMS_REQUEST,
  GET_TEAMS_SUCCESS,
  GET_TEAMS_FAILURE,
} from '../actionTypes';
import axiosWH from '../../utils/axios';

const getContactsRequest = (data) => ({ type: GET_CONTACTS_REQUEST, data });
const getContactsSuccess = (data) => ({ type: GET_CONTACTS_SUCCESS, data });
const getContactsFailure = (data) => ({ type: GET_CONTACTS_FAILURE, data });

const getLocationsRequest = (data) => ({ type: GET_LOCATIONS_REQUEST, data });
const getLocationsSuccess = (data) => ({ type: GET_LOCATIONS_SUCCESS, data });
const getLocationsFailure = (data) => ({ type: GET_LOCATIONS_FAILURE, data });

const getTeamsRequest = (data) => ({ type: GET_TEAMS_REQUEST, data });
const getTeamsSuccess = (data) => ({ type: GET_TEAMS_SUCCESS, data });
const getTeamsFailure = (data) => ({ type: GET_TEAMS_FAILURE, data });

export const getContacts = () => {
  return async (dispatch) => {
    try {
      dispatch(getContactsRequest());
      const response = await axiosWH.get('/api/v1/users', {
        params: {
          limit: 0,
        },
      });

      dispatch(getContactsSuccess(response.data));
    } catch (e) {
      dispatch(getContactsFailure(e));
    }
  };
};

export const getLocations = () => {
  return async (dispatch) => {
    try {
      dispatch(getLocationsRequest());
      const response = await axiosWH.get('/api/v1/locations');
      dispatch(getLocationsSuccess(response.data));
    } catch (error) {
      dispatch(getLocationsFailure(error));
    }
  };
};

export const getTeams = () => {
  return async (dispatch) => {
    try {
      dispatch(getTeamsRequest());
      const response = await axiosWH.get('/api/v1/teams', {
        params: {
          limit: 0,
        },
      });
      dispatch(getTeamsSuccess(response.data));
    } catch (error) {
      dispatch(getTeamsFailure(error));
    }
  };
};
