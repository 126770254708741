import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, Outlet, useNavigate } from "react-router-dom";
import { getSingleProject } from "../../store/actions/projectsActions";
import Container from "../../components/layout/Container/Container";
import { getAccess, titleLevel } from "../../utils/helpers";
import { Segmented, Spin, Tabs, Typography } from "antd";
import './styles.scss';

const { Title } = Typography;
const { TabPane } = Tabs;

const Project = () => {
  let { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { singleProjectLoading } = useSelector((state) => state.projects);
  const singleProject = useSelector((state) => state.projects?.singleProject);
  const [key, setKey] = useState("1");

  useEffect(() => {
    dispatch(getSingleProject(id));
  }, [dispatch, id]);

  const getOptions = (arr) => arr?.map((item, index) => {
    let path = item === "Phases & Team" ? "Phases".toLowerCase() : item.toLowerCase();

    return ({
      label: (
          <p onClick={() => navigate(`${path}`)} style={{ padding: "5px" }}>
            <b>{item}</b>
          </p>
        ),
      value: `${index+1}`
    })});

  const arrayTabNames = ['Summary', 'About', 'Phases & Team', 'Infrastructure', 'Subscriptions'];
  const getArrayTabNamesAllowed = (array) => {
    return array.filter((item) => {
      switch(item) {
        case 'Phases & Team': return getAccess('projects', 'phasesTeam')
        case 'Payment': return getAccess('projects', 'paymentTerms');
        // case 'Infrastructure': return getAccess('projects', 'infrastructure'); //не готов
        // case 'Subscriptions': не понятно
        default: return true;
      }
    });
  }
  const options = getOptions([...getArrayTabNamesAllowed(arrayTabNames)])

  // console.log('singleProject: ', singleProject)

  return (
    <Spin spinning={singleProjectLoading}>
      <Container>
        <Title level={titleLevel()}>
          <Link to="/projects">Projects </Link>/ {singleProject?.name}
        </Title>
        <div className="project-wrapper-tabs">
          <Tabs
            defaultActiveKey={key}
            activeKey={key}
            centered
            type="card"
            // animated
            renderTabBar={() => {
              return (
                <Segmented
                  block
                  options={options}
                  style={{backgroundColor: '#EAF8D7', marginBottom: '10px'}}
                  onChange={(value) => setKey(value)} />
              );
            }}
          >

          {arrayTabNames?.map((item, index) => {
            item = item === "Phases & Team" ? "Phases" : item;
            return(
              <TabPane tab={item} key={`${index+1}`}>
                <Outlet />
              </TabPane>
            )
          })}

          </Tabs>

        </div>
      </Container>
    </Spin>
  );
};

export default Project;
