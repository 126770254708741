import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProjects } from "../../store/actions/projectsActions";
import { Spin, Typography, Divider } from "antd";
import Container from "../../components/layout/Container/Container";
import ProjectsTable from "./ProjectsTable";
import ProjectsFilter from "./ProjectsFilter";
import AdvancedProjectFilter from "./AdvancedProjectFilter";
import { 
  contractsOptions, 
  sourcesOptions, 
  statusesOptions, 
  estimatedOptions, 
  getDateIsNow, 
  checkEmptyAllFilters,
  titleLevel, 
  getAccess,
} from "../../utils/helpers";
import "./styles.scss";
import { saveToLocalStorage, loadFromLocalStorage } from "../../store/localStorage";
import { CreateProjectButton } from "../Project/ProjectModals/CreateProject/CreateProjectButton";



const { Title } = Typography;

const Projects = () => {
  const dispatch = useDispatch();
  const projectsData = useSelector((state) => state.projects.projects?.data?.items); //initial projects
  const id = useSelector((state) => state.user?.user?.id);
  const { projectsLoading } = useSelector((state) => state.projects);


  const [name, setName] = useState("");
  const [client, setClient] = useState("");
  const [userId, setUserId] = useState(id);

  const [searchSource, setSearchSource] = useState(null);
  const [searchContract, setSearchContract] = useState(null);
  const [searchStatus, setSearchStatus] = useState(null);
  const [searchStartDate, setSearchStartDate] = useState(new Date());
  const [searchEstimate, setSearchEstimate] = useState(null);
  const [searchWorkedHours, setSearchWorkedHours] = useState(null)
  const [searchIsInternal, setIsInternal] = useState(null);

  const [isReset, setIsReset] = useState(false);
  const [stateIsEmpty, setStateIsEmpty] = useState(null);
  const [debouncedInput, setDebouncedInput] = useState([{ label: 'userId', value: userId}]);
  const [localStorageFilters, setLocalStorageFilters] = useState(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const arrayAllFilters = [
    userId, 
    name, 
    client, 
    searchSource, 
    searchContract, 
    searchStatus, 
    searchStartDate, 
    searchEstimate, 
    searchWorkedHours, 
    searchIsInternal
  ];
  let arrFiltersNames = [ 
    'userId',
    'name',
    'client',
    'source',
    'contractType',
    'status',
    'startDate',
    'estimatedHours',
    'workedHours',
    'isInternal',
    // 'teams'
  ];
  const setFiltersValues = (arrayObjects) => { //arrayObjects - filtersFromLocalStorage
    let obj = arrayObjects;
    // console.log('УСТАНАВЛИВАЕМ ЗНАЧЕНИЯ ИЗ LOCAL STORAGE')

    for (let key in obj) {
      let value = obj[`${key}`];
      let label = key;
      // console.log('label: ', label, ', value: ', value)

      if ((value !== null || value === null && label === 'userId') && value !== '') {
        if (label === 'userId') setUserId(value);
        if (label === 'name') setName(value);
        if (label === 'client') setClient(value);
        if (label === 'source') setSearchSource(value);
        if (label === 'contractType') setSearchContract(value);
        
        if (label === 'status') setSearchStatus(value);
        if (label === 'startDate') setSearchStartDate(value);
        if (label === 'estimatedHours') setSearchEstimate(value);
        if (label === 'workedHours') setSearchWorkedHours(value);
        if (label === 'isInternal') setIsInternal(!!value);
      }
    }
  }

  useEffect(() => {
    // console.log('START useEffect, []')
    let isEmpty = checkEmptyAllFilters(arrayAllFilters);
    let filtersFromLocalStorage = loadFromLocalStorage('filters');
    setLocalStorageFilters(filtersFromLocalStorage);
    
    if (filtersFromLocalStorage?.length !== 0 && isEmpty) setFiltersValues(filtersFromLocalStorage);
    // console.log('END useEffect, []')
  }, []);

  useEffect(() => {
    // console.log('START useEffect, [...arrayALLFilters]')

    let isEmptyFilters = checkEmptyAllFilters(arrayAllFilters)
    if (stateIsEmpty !== isEmptyFilters) setStateIsEmpty(isEmptyFilters);

    // console.log('Filters: Set new values');
    //PS Внимательно с обработчиками данных | смотри, что отправлять на сервер
    const getNewParam = (searchParam, paramOptions) => {
      if (searchParam !== null) {
        if (typeof(searchParam) === 'number') {
          let searchParamLabel = paramOptions?.filter((option) => option?.value === searchParam)[0]?.label;
          return searchParamLabel;
        } else return searchParam;

      } else return null;
    }

    let newSource = getNewParam(searchSource, sourcesOptions);
    let newContract = getNewParam(searchContract, contractsOptions);
    let newStatus = getNewParam(searchStatus, statusesOptions);
    let isNow = getDateIsNow(searchStartDate);
    let newSearchStartDate = (isNow || searchStartDate === null || searchStartDate === undefined) ? null : `${searchStartDate}`;
    let newSearchIsInternal = +searchIsInternal;

    // let arrNewFilters = [userId, name, client, newSource, newContract, newStatus, newSearchStartDate, searchEstimate, searchWorkedHours, newSearchIsInternal];
    let arrObjectsNewFilters = [
      {label: 'userId', value: userId},
      {label: 'name', value: name},
      {label: 'client', value: client},
      {label: 'source', value: newSource},
      {label: 'contractType', value: newContract},
      {label: 'status', value: newStatus},
      {label: 'startDate', value: newSearchStartDate},
      {label: 'estimatedHours', value: searchEstimate},
      {label: 'workedHours', value: searchWorkedHours},
      {label: 'isInternal', value: newSearchIsInternal},
      // {label: 'teams' value: },
    ];
    // console.log('arrObjectsNewFilters: ', arrObjectsNewFilters);

    // console.log('isEmptyFilters: ', isEmptyFilters)
    // if (!isEmptyFilters) {
      const timeoutId = setTimeout(() => {
        setDebouncedInput([...arrObjectsNewFilters]);
        // console.log('300ms are finished')
        //
      }, 300);
      // console.log('END useEffect, [...arrayALLFilters]')
  
      return () => clearTimeout(timeoutId);

    // }

    //(userId = null, name = '', client = '', source = null, contractType = null, status = null, startDate = null, teams = null, ) => {
  }, [...arrayAllFilters, 500]);

  useEffect(() => {
    // console.log('START useEffect, [debounce]')

    let debouncedInputValue = debouncedInput.map((item) => item.value);
    // console.log('debouncedInput: ', debouncedInput);

    dispatch(getProjects(...debouncedInputValue, `${page}`, `${limit}`));
    // dispatch(getProjects(...debouncedInputValue));

    
    const getRightView = (arrObjs) => {
      let objObjs = arrObjs.map((item) => ({[`${item?.label}`]: item?.value}));
      return Object.assign({}, ...objObjs);
    }

    saveToLocalStorage('filters', getRightView(debouncedInput));  
    // console.log('END useEffect, [debounce]')

  }, [debouncedInput]);


  const resetFilter = () => {
    setName('');
    setClient('');
    setUserId(id);

    setSearchSource(null);
    setSearchContract(null);
    setSearchStatus(null);
    setSearchStartDate(new Date())
    setSearchEstimate(null);
    setSearchWorkedHours(null);
    setIsInternal(null);

    setIsReset(true);

    localStorage.removeItem('filters');
    setDebouncedInput([{ label: 'userId', value: userId}])
    
  };


  return (
    <Container>
      <div className="projects__header">
        <Title level={titleLevel()}>Projects</Title>
        <CreateProjectButton />
        {/* {createAccess ? <CreateEventButton/> : <></>} */}
      </div>
      <Divider />
      <Spin spinning={projectsLoading}>
        <ProjectsFilter
          name={name}
          setName={setName}
          client={client}
          setClient={setClient}
          checked={userId}
          setChecked={setUserId}
          reset={resetFilter}
        />
        <AdvancedProjectFilter
          searchSource={searchSource}
          setSearchSource={setSearchSource}
          searchContract={searchContract}
          setSearchContract={setSearchContract}
          searchStatus={searchStatus}
          setSearchStatus={setSearchStatus}


          searchStartDate={searchStartDate}
          setSearchStartDate={setSearchStartDate}
          searchEstimate={searchEstimate}
          setSearchEstimate={setSearchEstimate}

          searchWorkedHours={searchWorkedHours}
          setSearchWorkedHours={setSearchWorkedHours} 

          searchIsInternal={searchIsInternal}
          setIsInternal={setIsInternal}

          isReset={isReset}
          setIsReset={setIsReset}          
          initialProjects={projectsData}
          />
        <ProjectsTable 
          projectsList={projectsData} 
          page={page}
          setPage={page}
          limit={limit}
          setLimit={setLimit}
          />
      </Spin>
    </Container>
  );
};

export default Projects;
