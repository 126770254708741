import React, { useState } from 'react';
import UserInfoModal from './UserInfoModal';
import useResizeWindows from '../../../utils/hooks/useResizeWindows';
import './styles.scss';

// const UserInfoModalButton = ({ user }) => {
const UserInfoModalButton = ({ user, userType }) => {
  const pageName = window.location.href.split('/')[3];

  const [infoModalVisible, setInfoModalVisible] = useState(false);
  let width = useResizeWindows();

  let viewFN = user?.firstName?.split('')?.length + user?.lastName?.split('')?.length > 15 ? `${user?.firstName?.split('')[0]}.` : user?.firstName;
  let viewFirstName = (width > 415 && width < 990 || width > 1810) ? user?.firstName : 
    (pageName === 'dayoff' || pageName === 'birthdays') ? user?.firstName : viewFN;
  
  return (
    <>
      <span className="name" onClick={() => setInfoModalVisible(true)}>
        {/* {user?.firstName + ' ' + user?.lastName} */}
        {`${user?.lastName} ${viewFirstName}`}
      </span>
      <UserInfoModal open={infoModalVisible} close={() => setInfoModalVisible(false)} id={user.id} userType={userType} />
    </>
  );
};

export default UserInfoModalButton;
