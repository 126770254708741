import React, { useState } from 'react';
import useResizeWindows from '../../utils/hooks/useResizeWindows';
import { Avatar, Card, Space, Col, Table, Divider } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import UserInfoModal from '../../components/UI/UserInfoModal/UserInfoModal';

const ContactItem = ({ item }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const width = useResizeWindows();

  const getDataSource = (elem) => {
    const dataSource = [
      {
        key: '1',
        label: 'Team',
        value: `${elem?.team?.name}`,
      },
      {
        key: '2',
        label: 'Location',
        value: `${elem?.location?.name}`,
      },
      {
        key: '3',
        label: 'Room',
        value: `${elem?.room}`,
      },
      {
        key: '4',
        label: 'Date of birth',
        value: `${elem?.birthday}`,
      },
      {
        key: '5',
        label: 'Schedule',
        value: `${elem?.schedule}`,
      },
    ];
    return dataSource;
  }

  const columns = [
    {
      title: '',
      dataIndex: 'label',
      key: 'label',
      render: (text) => <b>{text}</b>,
    },

    {
      title: '',
      dataIndex: 'value',
      key: 'value',
    },
  ];

  let dataSource = getDataSource(item);

  return (
    <Col xs={24} sm={24} md={12} lg={8} xxl={6} style={{ marginBottom: '15px' }}>
      <UserInfoModal id={item.id} open={isModalVisible} close={() => setIsModalVisible(false)} userType={'Employees'}/>
      <div className='contacts_item'>
        <Card
          title={
            // width > 766 ? (
            width < 450  || 766 < width  ? (
              <Space direction={'horizontal'} size={'large'} style={{margin: '10px 0'}}>
                <Avatar
                  size={70}
                  icon={<UserOutlined />}
                  // src={`${process.env.REACT_APP_WH_URL}/images/contacts/${item.photo}`}
                  src={`${process.env.REACT_APP_WH_URL === "https://mobile.wh.local" ? "https://wh.local" : process.env.REACT_APP_WH_URL}/images/contacts/${item.photo}`}

                />
                {/* <h3>{item.lastName} {item.firstName}</h3> */}
                <div className='mobileName'>
                  <h3>
                    <div className='lastName'>{item.lastName} </div>
                    <div className='firstName'> {item.firstName}</div>
                  </h3>

                </div>
              </Space>
            ) : null
          }
          hoverable
          onClick={() => setIsModalVisible(true)}
          // type="inner"
          className="contacts_item__table"
          bodyStyle={{padding: "10px 24px !important"}}
        >
          {/* {width > 766 ? ( */}
          {width < 450  || 766 < width  ? (

            <>
              {/* <b>Team:</b> {item.team.name} */}
              <Table 
                columns={columns} 
                dataSource={dataSource}
                showHeader={false}
                pagination={false} 
                size='small' />
            </>
          ) : (
            <Space direction={'horizontal'} size={'large'}>
              <Avatar
                size={70}
                icon={<UserOutlined />}
                // src={`${process.env.REACT_APP_WH_URL}/images/contacts/${item.photo}`}
                src={`${process.env.REACT_APP_WH_URL === "https://mobile.wh.local" ? "https://wh.local" : process.env.REACT_APP_WH_URL}/images/contacts/${item.photo}`}

              />
              <div className='contact_item__title_with_table'>
                <h3>
                  <span className='lastName'>{item.lastName} </span>
                  <span className='firstName'> {item.firstName}</span>
                </h3>
                {/* <b>Team:</b> {item.team.name} */}
                <Divider/>
                <Table 
                  columns={columns} 
                  dataSource={dataSource}
                  showHeader={false}
                  pagination={false} 
                  size='small' />
              </div>
            </Space>
          )}
        </Card>

      </div>
    </Col>
  );
};

export default ContactItem;
