import {
  GET_HOURS_REPORT_REQUEST,
  GET_HOURS_REPORT_SUCCESS,
  GET_HOURS_REPORT_FAILURE,
  ADD_HOURS_RECORD_REQUEST,
  ADD_HOURS_RECORD_SUCCESS,
  ADD_HOURS_RECORD_FAILURE,
  DELETE_HOURS_RECORD_REQUEST,
  DELETE_HOURS_RECORD_SUCCESS,
  DELETE_HOURS_RECORD_FAILURE,
} from '../actionTypes';

const initialState = {
  hours: null,
  hoursLoading: false,
  getHoursError: null,
  addHoursError: null,
  deleteHoursError: null,
};

const hoursReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_HOURS_REPORT_REQUEST:
      return { ...state, hoursLoading: true };
    case GET_HOURS_REPORT_SUCCESS:
      return { ...state, hours: action.data, getHoursError: null, hoursLoading: false };
    case GET_HOURS_REPORT_FAILURE:
      return { ...state, getHoursError: action.error, hoursLoading: false };
    case ADD_HOURS_RECORD_REQUEST:
      return { ...state, hoursLoading: true };
    case ADD_HOURS_RECORD_SUCCESS:
      return { ...state, addHoursError: null, hoursLoading: false };
    case ADD_HOURS_RECORD_FAILURE:
      return { ...state, addHoursError: action.error, hoursLoading: false };
    case DELETE_HOURS_RECORD_REQUEST:
      return { ...state, hoursLoading: true };
    case DELETE_HOURS_RECORD_SUCCESS:
      return { ...state, deleteHoursError: null, hoursLoading: false };
    case DELETE_HOURS_RECORD_FAILURE:
      return { ...state, deleteHoursError: null, hoursLoading: false };
    default:
      return { ...state };
  }
};

export default hoursReducer;
