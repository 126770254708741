import React from 'react';
import { Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import './style.scss';


export const RemoveWrapper = ({ condition, buttonName, cancelDelete, children }) => { 
	//condition = {filteredDeleteHour === row.id}
	//buttonName = "deleting hours"
	//cancelDelete = {cancelDeleteHour}
	return (
		<div className={`removeWrapper`}>
			<div className={`${condition ? 'untouchable' : 'hidden' }`}></div>
			<div className={`${condition ? 'overUntouchable visible' : 'hidden'}`}>
				<div className={`${condition ? 'visible wrapperCancelButton' : 'hidden'}`}>
					<Button danger icon={<CloseOutlined />} 
						onClick={cancelDelete}  
						ghost style={{ width: '100%', backgroundColor: 'white'}}>
						Cancel {buttonName}
					</Button>
				</div>
			</div>
			{children}
		</div>
	)
}

export default RemoveWrapper;

