import React from 'react';
import { dateHelper } from '../../../utils/helpers';
import { monthObject } from '../../../utils/constants';

const CalendarMonthInfo = ({ calendar, date }) => {
  const currentDate = dateHelper(new Date());
  const monthNorm = (calendar?.extraData?.dayNorm * calendar?.extraData?.workdays).toString();
  const currentMonth = monthObject[date.slice(5)];
  const currentMonthDays = calendar && calendar.days && Object.keys(calendar?.days).filter((day) => day.includes(date));
  const currentDayIndex = currentMonthDays?.findIndex((day) => day === currentDate);
  const workedNorm = currentDayIndex >= 0 ? (currentDayIndex + 1) * calendar?.extraData?.dayNorm : monthNorm;
  let workedHours = 0;
  currentMonthDays?.forEach((day) => {
    workedHours += calendar?.days[day].hours ? calendar?.days[day].hours : 0;
  });
  return (
    <p className="calendar__footer_info">
      Total Number of hours in {currentMonth} is{' '}
      <b>
        {workedHours} / {workedNorm}
      </b>{' '}
      (Month norm: <b>{monthNorm}</b>)
    </p>
  );
};

export default CalendarMonthInfo;
