import React from 'react';
import { Tooltip } from 'antd';

const CustomTooltip = ({ title, placement, children }) => {
  return (
    <Tooltip 
			title={title}
			overlayInnerStyle={{height: '28px', minHeight: '25px', padding: "2px 8px"}}
			placement={placement}
			color='rgb(135 208 104 / 85%)' key='rgb(135 208 104 / 85%)'
			>
				{children}
			</Tooltip>
  )
}

export default CustomTooltip;
