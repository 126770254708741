import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Alert, Button, Card, Space, Table, Collapse, Select } from "antd";
import { SaveFilled, DeleteFilled, EditFilled } from "@ant-design/icons";
import { getInfrastructures } from "../../../store/actions/projectsActions";
import AddInfrastructureButton from "../ProjectModals/AddInfrastructure/AddInfrastructureButton";
import AddCommentsModal from "../ProjectModals/AddComments/AddCommentsModal";
// import AddCommentsButton from "../ProjectModals/AddComments/AddCommentsButton";
// import CommentModal from '../ProjectModals/AddComments/CommentModal';
import UserInfoModal from "../../../components/UI/UserInfoModal/UserInfoModal";
import { CustomizedEmptyComponent } from "../../../components/UI/CustomizedEmpty/CustomizedEmpty";
import { getContacts } from "../../../store/actions/contactsActions";
import InfrastructureCard from "../../../components/UI/InfrastructureCard/InfrastructureCard";
import { getSortedAlphabet } from "../../../utils/helpers";
// getSortedAlphabet


const CommentsCard = ({title, comment}) => {
  return (
    <Card
      size="small" 
      title={title}
      extra={<div className="wrapperButtons">
        <Button>
          <span className="buttonIcon"><EditFilled /></span>
          <span className="buttonText">Edit</span>
        </Button>
        <Button>
          <span className="buttonIcon"><DeleteFilled className="icon"/></span>
          <span className="buttonText">Delete</span>
        </Button>
      </div>} 
      style={{ width: '100%' }}
    >
      <div className="commentsBody">
        <div className="content">{comment}</div>
        <div className="signature">Updated by Admin Admin 2023-07-20</div>

      </div>
    </Card>
  )
}


const info = [
  {
    id: 1,
    isSecure: false,
    type: 'VSC (Version Control System)',
    name: 'Test 0',
    url: 'https://google.com',
    comment: '',
    author: {id: 1, firstName: 'Admin', lastName: 'Admin'},
    date: '2023-07-19',
  },
  {
    id: 1,
    isSecure: false,
    type: 'VSC (Version Control System)',
    name: 'Test ttsgsvd sdh sdh',
    url: 'https://google.com',
    comment: 'Morbi sodales, velit id suscipit volutpat, ante ligula ultrices arcu, eu semper enim risus et elit. Quisque semper sapien ut nulla lacinia laoreet. In auctor dolor sit amet eros molestie congue. Maecenas ultrices interdum nisi et placerat. Mauris at tortor eget nibh fermentum fermentum. Nulla imperdiet mauris non odio sagittis nec euismod risus consectetur.',
    author: {id: 1, firstName: 'Admin', lastName: 'Admin'},
    date: '2023-07-20',
  },
  {
    id: 2,
    isSecure: false,
    type: 'Task Management (Readmine)',
    name: 'Test ttsgsvd sdh sdh 2222',
    url: 'https://google.com',
    comment: '',
    author: {id: 1, firstName: 'Admin', lastName: 'Admin'},
    date: '2023-07-17',
  },
  {
    id: 3,
    isSecure: false,
    type: 'Communication (BazaCamp)',
    name: 'Test 3',
    url: 'https://google.com',
    comment: 'Morbi sodales, velit id suscipit volutpat, ante ligula ultrices arcu, eu semper enim risus et elit. Quisque semper sapien ut nulla lacinia laoreet. In auctor dolor sit amet eros molestie congue. Maecenas ultrices interdum nisi et placerat. Mauris at tortor eget nibh fermentum fermentum. Nulla imperdiet mauris non odio sagittis nec euismod risus consectetur.',
    author: {id: 1, firstName: 'Admin', lastName: 'Admin'},
    date: '2023-08-14',
  },
  // //Viatcheslav Matveev 28
  // {
  //   id: 4,
  //   isSecure: false,
  //   type: 'Continuous Integration (Jenkins)',
  //   name: 'Test 4',
  //   url: 'https://google.com',
  //   comment: 'Morbi sodales, velit id suscipit volutpat.',
  //   author: {id: 28, firstName: 'Viatcheslav', lastName: 'Matveev'},
  //   date: '2023-08-12',
  // },
  {
    id: 5,
    isSecure: false,
    type: 'Hosting (Production server)',
    name: 'Test 5',
    url: 'https://google.com',
    comment: '',
    author: {id: 28, firstName: 'Viatcheslav', lastName: 'Matveev'},
    date: '2023-08-12',
  },
  {
    id: 6,
    isSecure: false,
    type: 'VSC (Version Control System)',
    name: 'Test 6',
    url: 'https://google.com',
    comment: '',
    author: {id: 1, firstName: 'Admin', lastName: 'Admin'},
    date: '2023-08-23',
  },
];

const { Panel } = Collapse;

const Infrastructure = ({data}) => {
  const dispatch = useDispatch();
  let { id } = useParams();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [userId, setUserId] = useState(null);

  const [postModalShow, setPostModalShow] = useState(false);
  const [activeCardsDeleting, setActiveCardsDeleting] = useState([]);
  // const [archivingCardsDeleting, setArchivingCardsDeleting] = useState([]);

  const [cardsArchiving, setCardsArchiving] = useState([]);
  const [cardsActive, setCardsActive] = useState([]);
  const [cardType, setCardType] = useState(null);
  
  // CardsDeleting
  // cards deleting  
  // cards archiving
  // cards active


  //--start---Метод GET нереализован----------
  // const infrastructures = useSelector((state) => state?.projects?.infrastructures)
  // console.log('infrastructures: ', infrastructures)
  //--end---Метод GET нереализован----------


  useEffect(() => {
    // dispatch(getInfrastructures(id-1)) //Метод GET нереализован
  }, []);

  useEffect(() => {
    dispatch(getContacts());
    // setCardsActive(info);
    setCardsActive(getSortedAlphabet(info, 'type'));
  }, []);

  useEffect(() => {
    if (cardType) {
      let newCardsActive = info?.filter((card) => card?.type === cardType);
      // setCardsActive(newCardsActive);
      // } else setCardsActive(info);
      setCardsActive(getSortedAlphabet(newCardsActive, 'type'));
  } else setCardsActive(getSortedAlphabet(info, 'type'));
  }, [cardType]);


  let optionsCardTypes = [
    {label: 'All cards types', value: null},
    {label: 'VSC (Version Control System)', value: 'VSC (Version Control System)'},
    {label: 'Task Management (Readmine)', value: 'Task Management (Readmine)'},
    {label: 'Continuous Integration (Jenkins)', value: 'Continuous Integration (Jenkins)'},
    {label: 'Hosting (Production server)', value: 'Hosting (Production server)'},
    {label: 'Sandbox (Demo Server)', value: 'Sandbox (Demo Server)'},
    {label: 'Communication (BazaCamp)', value: 'Communication (BazaCamp)'},
    {label: 'Bugtracking (Bugzilla)', value: 'Bugtracking (Bugzilla)'},
    {label: 'Code signing assets (Certificates, dev programs)', value: 'Code signing assets (Certificates, dev programs)'},
  ];

  
  return (
    <div className="infrastructure">
      <UserInfoModal id={userId} open={isModalVisible} close={() => setIsModalVisible(false)} userType={'Employees'}/>
      <div className="infrastructureBlock">
        <Alert 
          type="success" 
          message={
            <div className="alertWrapper">
              <b>Infrastructure</b>
              <AddInfrastructureButton />
            </div>
          } 
          banner 
          showIcon={false} />
        <br />
        
        <Space direction="vertical" size={16}>
        <Select
          placeholder="Select card type"
          // defaultValue="lucy"
          value={cardType}
          style={{ width: '100%' }}
          onChange={(value) => setCardType(value)}
          options={optionsCardTypes}
        />
        {cardsActive?.map((item, index) => {
          let filteredDeleteCard = activeCardsDeleting?.filter((elem) => item?.id === elem)[0];

          return(
            <InfrastructureCard
              item={item} 
              index={index} 
              cardsDeleting={activeCardsDeleting} 
              setCardsDeleting={setActiveCardsDeleting}
              cardsArchiving={cardsArchiving}
              setCardsArchiving={setCardsArchiving}
              cardsActive={cardsActive}
              setCardsActive={setCardsActive}
              setUserId={setUserId}
              setIsModalVisible={setIsModalVisible}
              cardType='active'
            />
          )})
        }
          {/* <RemoveWrapper
            condition = {filteredDeleteCard === item?.id}
            buttonName = "deleting card"
            cancelDelete = {() => {
              let newArrDelete = activeCardsDeleting?.filter((elem) => item?.id !== elem);
              setActiveCardsDeleting(newArrDelete ? newArrDelete : [])
            }}
          > */}
          {/* </RemoveWrapper> */}
          <Collapse>
            <Panel header={`Archived (${cardsArchiving?.length})`}>
            {cardsArchiving?.length === 0 ? <CustomizedEmptyComponent text={`No archiving cards`} /> : <></>}
            {cardsArchiving?.map((item, index) => (
              <InfrastructureCard
                item={item} 
                index={index} 
                cardsDeleting={activeCardsDeleting} 
                setCardsDeleting={setActiveCardsDeleting}
                cardsArchiving={cardsArchiving}
                setCardsArchiving={setCardsArchiving}
                cardsActive={cardsActive}
                setCardsActive={setCardsActive}
                setUserId={setUserId}
                setIsModalVisible={setIsModalVisible}
                cardType='archive'

                />))
            }
            </Panel>
          </Collapse>

        </Space>
      </div>
      <div className="commentsBlock">
        <Alert 
          type="success" 
          message={
            <div className="alertWrapper">
              <b>Solutions and Comments</b>
              <AddCommentsModal />
              {/* <AddCommentsButton /> */}
              {/* <CommentModal /> */}
              {/* <Button onClick={() => setPostModalShow(true)}>Add</Button> */}
              {/* <AddCommentsModal show={postModalShow} close={() => setPostModalShow(false)}/> */}
            </div>
          } 
          banner 
          showIcon={false} 
        />
          <CommentsCard title="Titleleeekel" comment='HB n fdnkfnjdnf dfndknf dfnvfd' />
          <CommentsCard title="Phoebe" comment='Wv hdvfhdf dkfn dfdkf dfmkldmf dmfdmf dfmkldm fd fdkmflkdmfkl dfkdflsmdnbvg. JjBJBn  dfndjnf jdndfndjf n fdnkfnjdnf dnjkn' />
          <CommentsCard title="HJbnndbs" comment='Hlsdn' />
          <CommentsCard title="Tbvbsm sjdn " comment='Hlsdnsdjk djndkns dnd' />
          <CommentsCard title="SDC gvh dbs" comment='j jfkej sdn' />
          <CommentsCard title="GB9769" comment='Hlsdnjjbfjf jkdfnd fkmdf mefme dflekmkrfjnekrhfkjerhfj grjfgklejnfgjklehrgk hrjkghrkjghd gejnfjhenrjfe dfkenfkde fekmrfe rfekrmfe rfejfke vjdf fvpfm efnmelf efemlfmelmfleml rfe fklelnrfenrfkenrfk enrkjfbejrbfje efnkenfke ffekfjekfmek fcefkjefkjelrfeknfkelrfkje nrjkfbkjrebfjnsjbcs bdmn dbmbdf' />
          <CommentsCard title="HJbnndbs" comment='Hlsdn' />

      </div>

    </div>
  );
};

export default Infrastructure;
