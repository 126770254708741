import React, { useState } from 'react';
import ModalFormWrapper from '../../../../components/UI/ModalForm/ModalFormWrapper';
import { Form, Input } from 'antd';
import TextEditor from '../../../../components/UI/TextEditor/TextEditor';



const { Item } = Form;

const AddCommentsModal = () => {
	const [title, setTitle] = useState(null);
  const [content, setContent] = useState(null);

  return (
    <ModalFormWrapper modalTitle='Add Post' buttonName='Add'>
			<Item name='title' label='Title'>
				<Input 
					placeholder="Enter title" 
					value={title}
					onChange={(e) => setTitle(e.target.value)}
				/>
			</Item>
			<Item 
				name='content' 
				label='Content'
				required 
				rules={[{required: true, message: "Please enter content"}]}
			>
				<TextEditor 
					value={content}
					onChange={setContent}
				/>
			</Item>
		</ModalFormWrapper>
  )
}

export default AddCommentsModal;
