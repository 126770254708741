import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { createTasks, updateTasks, deleteTasks } from '../../store/actions/tasksActions';
import { getPhases } from '../../store/actions/phasesActions';
import { Spin, Button, Popconfirm, Modal} from 'antd';
import FormInput from '../../components/forms/FormInput';
import FormTextArea from '../../components/forms/FormTextArea';
import FormSelect from '../../components/forms/FormSelect';
import FormDatePicker from '../../components/forms/FormDatePicker';
import { status_project } from '../../utils/constants';
import { taskDataRefactor, getDatePickerValue } from '../../utils/helpers';

const TaskModal = ({ visible, setVisible, id, setId }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const tasks = useSelector((state) => state.tasks.tasks);
  const phases = useSelector((state) => state.phases.phases);
  const loading = useSelector((state) => state.tasks.loading);
  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm({ mode: 'onSubmit' });

  useEffect(() => {
    if (id) {
      const task = tasks.find((item) => item.id === id);
      reset(taskDataRefactor(task));
    } else {
      setValue('phase', phases[0]?.id);
      setValue('currentStatus', status_project[1].id);
      setValue('startDate', new Date());
      setValue('endDate', new Date());
    }
  }, [visible, id, tasks]);

  useEffect(() => {
    dispatch(getPhases());
  }, [dispatch]);

  const closeModal = () => {
    if (id) setId(null);
    setVisible(false);
    reset();
  };
  const createTaskHandler = async (data) => {

    let newData = {...data}
    newData.startDate = getDatePickerValue(data.startDate);
    newData.endDate = getDatePickerValue(data.endDate);

    let response;
    if (id) {
      response = await dispatch(updateTasks(id, { task: { ...newData, user: user.user.id } }));
    } else {
      response = await dispatch(createTasks({ task: { ...newData, user: user.user.id } }));
    }
    if (response) {
      reset();
      setVisible(false);
    }
  };
  const deleteTaskHandler = async () => {
    await dispatch(deleteTasks(id));
    setVisible(false);
  };

  return (
    <Modal
      title={id ? 'Update Task' : 'Create Tasks'}
      okText={'Save'}
      style={{ top: 20 }}
      cancelText={'Cancel'}
      open={visible}
      closable={false}
      confirmLoading={loading}
      width={750}
      footer={[
        <Button onClick={closeModal}>Cancel</Button>,
        id && (
          <Popconfirm
            placement="topRight"
            title="Are you sure to delete this task?"
            onConfirm={deleteTaskHandler}
            okText="Yes"
            cancelText="No"
          >
            <Button type="primary" danger>Delete</Button>
          </Popconfirm>
        ),
        <Button type="primary" loading={loading} onClick={handleSubmit(createTaskHandler)}>
          Save
        </Button>,
      ]}
    >
      <Spin spinning={loading} size="large">
        <form onSubmit={handleSubmit(createTaskHandler)}>
          <FormSelect
            name={'phase'}
            control={control}
            title={'Phase'}
            error={errors.phase}
            required={true}
            options={phases}
          />
          <FormInput
            name={'taskManagerLink'}
            title={'Task manager link'}
            placeholder={'Task manager link'}
            control={control}
          />
          <FormInput
            name={'name'}
            title={'Name'}
            placeholder={'Name'}
            control={control}
            required={true}
            error={errors.name}
          />
          <FormTextArea name={'descriptions'} title={'Description'} placeholder={'Description'} control={control} />
          <FormDatePicker
            name={'startDate'}
            title={'Start Date'}
            control={control}
            required={true}
            error={errors.startDate}
          />
          <FormDatePicker
            name={'endDate'}
            title={'End Date'}
            control={control}
            required={true}
            error={errors.startDate}
          />
          <FormInput
            name={'estimationTime'}
            title={'Estimation time'}
            placeholder={'Estimation time'}
            control={control}
            required={true}
            error={errors.estimationTime}
          />
          <FormSelect name={'currentStatus'} control={control} title={'Current status'} options={status_project} />
        </form>
      </Spin>
    </Modal>
  );
};

export default TaskModal;
