export const access = {
    administration: {
        all: ['Admin'],
    },
    audit: {
        all: ['Admin'],
    },
    ajax: {
        all: ['Employee', 'PManager', 'Teamleader', 'HRManager', 'ITManager', 'FDManager','NewsMaker', 'Sales', 'CCManager','HoursReporter', 'EventsManager', 'Admin'],
    },
    allocations: {
        all: ['PManager', 'Teamleader', 'Admin'],
    },
    applicants: {
        all: ['HRManager', 'Admin'],
    },
    auth: {///???
        all: ['Employee', 'PManager', 'Teamleader', 'HRManager', 'ITManager', 'FDManager','NewsMaker', 'Sales', 'CCManager','HoursReporter', 'EventsManager', 'Admin'],
    },
    billable: { //есть подпункты
        all: ['PManager', 'Teamleader', 'FDManager', 'Admin'],
        ['report']: ['PManager', 'FDManager', 'Admin'],
    },
    bonuses: { //есть подпункты
        all: ['Teamleader', 'HRManager', 'Admin'],
        ['edit']: ['Teamleader', 'Admin'],
        ['save']: ['Teamleader', 'Admin'],
        ['delete']: ['Teamleader', 'Admin'],
        ['notifications']: ['Teamleader', 'Admin'],
        ['report']: ['Employee', 'Admin'], ///////////only self report
    },
    calendar: {
        all: ['Employee', 'Admin'],
    },
    clients: {//есть подпункты
        all: ['PManager', 'Teamleader', 'FDManager', 'Sales', 'CCManager', 'Admin'],
        ['edit']: ['CCManager', 'Admin'],
        ['fileUpload']: ['CCManager', 'Admin'],
        ['photoUpload']: ['CCManager', 'Admin'],
        ['save']: ['CCManager', 'Admin'],
        ['companyLinkForm']: ['CCManager', 'Admin'],
        ['companyLink']: ['CCManager', 'Admin'],
        ['companyUnLink']: ['CCManager', 'Admin'],
        ['projectLink']: ['CCManager', 'Admin'],
        ['projectUnLink']: ['CCManager', 'Admin'],
    },
    companies: {//есть подпункты
        all: ['CCManager', 'Admin'],
        ['index']: ['Admin'],
        ['grid']: ['Admin'],
        ['view']: ['Admin'],
        ['getCompaniesForAutocomplete']: ['Admin'],
    },
    contacts: {
        all: ['Employee', 'Admin'],
    },
    credentials: {//есть подпункты
        all: ['Admin'],
        ['assignCredentialsAJAX']: ['HRManager', 'Admin'],
        ['assignCredentialsAJAXSave']: ['HRManager', 'Admin'],

    //     true: ['Employee', 'PManager', 'Teamleader', 'HRManager', 'ITManager', 'FDManager','NewsMaker', 'Sales', 'CCManager','HoursReporter', 'EventsManager', 'Admin'],
    },

    ['dayoff']: { //???
        // ['all']: ['Employee', 'Admin'],
        ['all']: ['Admin'],
        ['create']: ['PManager', 'Teamleader', 'HRManager', 'Admin'],//изменять для других пользователей
        ['edit']: ['PManager', 'Teamleader', 'HRManager', 'Admin'],//добавлять для друних пользователей
    },

    documents: {
        all: ['Employee', 'Admin'],
    },
    ['events']: {//есть подпункты
        //С ограничением доступа: create и edit - разрешены только EventsManager и Admin
        //все остальное можно всем (просмотр)
        ['all']: ['EventsManager', 'Admin'], //edit, create
        ['create']: ['EventsManager', 'Admin'],
        ['edit']: ['EventsManager', 'Admin'],
        
        ['index']: ['Employee', 'Admin'], // вроде вся страница Events Page
        ['grid']: ['Employee', 'Admin'], //запрос на таблицу
        ['view']: ['Employee', 'Admin'],//employee (модалка с отображением деталей event)
    },
    files: {//???
        all: ['Employee', 'Admin'],
    },
    holidays: {
        all: ['HRManager', 'Admin'],
    },
    hours2: {
        all: ['Employee', 'PManager', 'Teamleader', 'HRManager', 'ITManager', 'FDManager','NewsMaker', 'Sales', 'CCManager','HoursReporter', 'EventsManager', 'Admin'],
    },
    hr: {
        all: ['HRManager', 'Admin'],
    },
    it: {
        all: ['ITManager', 'Admin'],
    },
    karma: {//есть подпункты
        all: ['Admin'],
        ['myProjectsKarmaTab']: ['PManager', 'Admin'],
        ['myProjectsKarmaTabGrid']: ['PManager', 'Admin'],
        ['myTeamKarmaTab']: ['Teamleader', 'Admin'],
        ['myTeamKarmaTabGrid']: ['Teamleader', 'Admin'],
        ['index']: ['Employee', 'Admin'],
        ['myKarmaTab']: ['Employee', 'Admin'],
        ['myKarmaTabGrid']: ['Employee', 'Admin'],
        ['byMeKarmaTab']: ['Employee', 'Admin'],
        ['byMeKarmaTabGrid']: ['Employee', 'Admin'],
        ['editAjax']: ['Employee', 'Admin'],
        ['saveAjax']: ['Employee', 'Admin'],
    },    
    news: {
        all: ['NewsMaker', 'Admin'],
        ['index']: ['Employee', 'Admin'],
        ['grid']: ['Employee', 'Admin'],
        ['view']: ['Employee', 'Admin'],
    },   
    notify: {
        all: ['PManager', 'Teamleader', 'FDManager', 'Admin'],
    },   
    notifyProject: {//???
        all: ['PManager', 'Teamleader', 'FDManager', 'Admin'],
    },   
    notifyUser: {//???
        all: ['PManager', 'Teamleader', 'FDManager', 'Admin'],
    },   
    phases: {//???
        all: ['PManager', 'Teamleader', 'FDManager', 'Admin'],
    },   
    profile: {
        all: ['Employee', 'Admin'],
    },   
    projectNotes: {
        all: ['Admin'],
    },   
    ['projects']: {//???
        ['all']: ['Admin'],
        // ['all']: ['Employee', 'Admin'],
        // ['summary']: ['Employee', 'Admin'],
        // ['about']: ['Employee', 'Admin'],
        ['paymentTerms']: ['Admin', 'PManager', 'CCManager', 'Sales', 'FDManager'],
        ['companyClient']: ['Admin', 'PManager', 'CCManager', 'Sales', 'FDManager'],
        ['phasesTeam']: ['Admin', 'PManager'],
        // ['infrastructure']: [], //'Admin' и все участники проекта
        // ['newPrоject']: [] //все менеджеры проекта

        // Payment terms – Admin, PManager, CCManager, Sales, FDManager.
        // Company & Client - Admin, PManager, CCManager, Sales, FDManager.
        // Files & Documentation – Admin, Все участники проекта. (к отдельным документам права регулируются отдельно) учитывать галочку — главный девелопер.
        // Phases & Team -  Admin, Pmanager.
        // Infrastructure - Admin, Все участники проекта.
        // Karma -  Admin, Все участники проекта.
        // Log – все участники проекта.
        // New prоject – все менеджеры проекта.
        // Blog – все пользователи.


    },  
    reports: {//есть подпункты //// МНОГО ВОПРОСОВ, СТРАННАЯ ТАБЛИЦА
        all: ['PManager', 'Teamleader', 'HRManager', 'FDManager','CCManager','HoursReporter', 'Admin'],
        ['EmployeeReport']: ['PManager', 'Teamleader', 'HRManager', 'FDManager', 'HoursReporter', 'Admin'],
        ['AllEmployeeReport']: ['PManager', 'Teamleader', 'HRManager', 'FDManager', 'HoursReporter', 'Admin'],
        ['ProjectReport']: ['PManager', 'Teamleader', 'HRManager', 'FDManager', 'HoursReporter', 'Admin'],
        ['AllProjectReport']: ['PManager', 'Teamleader', 'HRManager', 'FDManager', 'HoursReporter', 'Admin'],

        ['PMReport']: ['PManager', 'Teamleader', 'HRManager', 'FDManager', 'Admin'],
        ['PMEmployeeReport']: ['PManager', 'Admin'],
        ['AllPMReport']: ['PManager', 'Teamleader', 'HRManager', 'FDManager', 'Admin'],

        ['TeamReport']: ['PManager', 'Teamleader', 'HRManager', 'FDManager', 'HoursReporter', 'Admin'],
        ['AllTeamReport']: ['PManager', 'Teamleader', 'HRManager', 'FDManager', 'Admin'],
        ['TeamDevReport']: ['PManager', 'Teamleader', 'HRManager', 'FDManager', 'HoursReporter', 'Admin'],
        ['GeneralReport']: ['PManager', 'Teamleader', 'HRManager', 'FDManager', 'Admin'],
        ['BillableReport']: ['PManager', 'Teamleader', 'HRManager', 'FDManager', 'Admin'],

        ['HRHoursReport']: ['HRManager', 'HoursReporter', 'Admin'],
        ['HRApplicantsReport']: ['HRManager', 'Admin'],
        ['HRVacationsReport']: ['HRManager', 'Admin'],
        ['HRVacationsMonthlyReport']: ['HRManager', 'Admin'],
        ['HRBonusesReport']: ['HRManager', 'Admin'],

        ['ActivityHoursReport']: ['PManager', 'Teamleader', 'HRManager', 'FDManager', 'Admin'],
        ['ActivityGitReport']: ['Teamleader', 'Admin'],
        ['AllocationAndRealHoursReport']: ['PManager', 'Teamleader', 'HRManager', 'FDManager', 'Admin'],
        ['EffectiveReport']: ['PManager', 'Teamleader', 'HRManager', 'FDManager', 'Admin'],
        ['AllocationsGraphReport']: ['PManager', 'Teamleader', 'Admin'],

        ['HoursChartReport']: ['Admin'],
        ['ClientsByHoursReport']: ['CCManager', 'Admin'],
        ['ClientsByTechnologyReport']: ['CCManager', 'Admin'],
    },  
    settings: {
        all: ['Employee', 'Admin'],
    },  
    tasks2: {//???
        all: ['Employee', 'Admin'],
    },  

    // applicants: {
    //     true: ['Employee', 'PManager', 'Teamleader', 'HRManager', 'ITManager', 'FDManager','NewsMaker', 'Sales', 'CCManager','HoursReporter', 'EventsManager', 'Admin'],
    // },  
}

let roles = [
    'Employee', 
    'PManager', 
    'Teamleader', 
    'HRManager', 
    'ITManager', 
    'FDManager',
    'NewsMaker', 
    'Sales', 
    'CCManager',
    'HoursReporter', 
    'EventsManager', 
    'Admin'
]
 let roles1 = [ 'Employee', 'PManager', 'Teamleader', 'HRManager', 'ITManager', 'FDManager','NewsMaker', 'Sales', 'CCManager','HoursReporter', 'EventsManager', 'Admin']
    // audit
    // ajax
    // allocations
    // applicants
    // auth
    // billable
    // bonuses
    // calendar
    // clients
    // companies
    // contacts
    // credentials
    // dayoff
    // documents
    // events
    // files
    // holidays
    // hours2
    // hr
    // it
    // karma
    // news
    // notify

    // notifyProject
    // notifyUser
    // phases

    // profile
    // projectNotes
    // projects
    // reports
    // settings
    // tasks2

// Employee
// PManager
// Teamleader
// HRManager
// ITManager
// FDManager
// NewsMaker
// Sales
// CCManager
// HoursReporter
// EventsManager
// Admin