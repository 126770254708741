import React, { useState, useEffect } from 'react';
import moment from 'moment/moment';/////////////////////////////////////////////
import dayjs from 'dayjs';
import { Table, ConfigProvider, Collapse } from 'antd';
import { FileSyncOutlined } from '@ant-design/icons';
import TaskModal from './TaskModal';
import ProjectStatus from './ProjectStatus';
import CustomizedEmpty from '../../components/UI/CustomizedEmpty/CustomizedEmpty';
import useResizeWindows from '../../utils/hooks/useResizeWindows';
import { sorter, sortedDatesReverse } from '../../utils/helpers';
import MobileTable from '../../components/UI/MobileTable/MobileTable';



// const { Panel } = Collapse;

const ProjectItem = ({ tasks }) => {
  const [taskId, setTaskId] = useState(null);
  const [updateVisible, setUpdateVisible] = useState(false);

  const customizeRenderEmpty = CustomizedEmpty("No tasks");
  const width = useResizeWindows();

  let sortedTasks = tasks ? sortedDatesReverse(tasks) : tasks;

  const columns = [
    {
      title: 'Phase',
      dataIndex: 'phase',
      key: 'phase',
      width: '15%',
      sorter: (a, b) => a.phase.length - b.phase.length,
      render: (_, record) => (
        <p>
          <b>{record.phase?.name}</b>
        </p>
      ),
    },
    {
      title: 'Task',
      dataIndex: 'name',
      key: 'name',
      sorter,
      render: (_, record) => (
        <>
          <button
            onClick={() => {
              setUpdateVisible(true);
              setTaskId(record.id);
            }}
            className="tasks__link"
          >
            {record?.name.length < 30 ? record?.name : record?.name.slice(0,30) + '...'}
          </button>
          <span className="tasks-list__item-estimation">
            ({record?.workedHours}/{record?.estimationTime})
          </span>
          {record?.taskManagerLink && (
            <a href={record?.taskManagerLink} target={'_blank'}>
              <FileSyncOutlined color="black" />
            </a>
          )}
        </>
      ),
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      width: '15%',
      sorter: (a, b) => moment(a.startDate).unix() - moment(b.startDate).unix(),
      render: (_, record) => (
        <span style={{ fontSize: '12px' }}>
          {moment(record.startDate).format('L')}
          <span> - </span>
          {moment(record.endDate).format('L')}
        </span>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'currentStatus',
      key: 'currentStatus',
      width: '10%',
      // sorter: (a, b) => a.currentStatus.length - b.currentStatus.length,
      render: (_, record) => <ProjectStatus status={record.currentStatus} />,
    },
    {
      title: 'Descriptions',
      dataIndex: 'descriptions',
      key: 'descriptions',
      render: (_, record) => <p style={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>{record.descriptions}</p>,
    },
  ];

  return (
    <div className="tasksPage__projectItem">
    {
      width > 990 ?
      <ConfigProvider renderEmpty={customizeRenderEmpty}>
        <Table
          columns={columns}
          // dataSource={tasks}
          dataSource={sortedTasks}
          pagination={false}
          showSorterTooltip={false}
          size={'small'}
          style={{minWidth: '1200px'}}
          bordered={true}
          scroll={{
            y: 500,
          }}
        />
      </ConfigProvider>
      : <MobileTable 
          columns={columns} 
          items={tasks} 
          setUpdateVisible={setUpdateVisible} 
          setTaskId={setTaskId} 
          typeData="tasks"/>
    }
      <TaskModal visible={updateVisible} setVisible={setUpdateVisible} id={taskId} setId={setTaskId} />
    
    </div>
  );
};

export default ProjectItem;
