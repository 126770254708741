import React, { useState, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import { Button, Col, Image, Input, Row, Typography } from "antd";
import './styles.scss';

const { Title } = Typography;

const ForgotPassword = () => {
  const [name, setName] = useState('');
  const inputLoginRef = useRef(null);

  useEffect(() => {
    inputLoginRef.current.focus({
      cursor: 'end',
    });
  },[])

  return (
    <Row className="forgot-password__container">
      <Col xs={24} sm={20} md={12} lg={8} xl={8} xxl={6}>
        <div className="forgot-password">
          <Image width={250} src={require('../../assets/images/logo.png')} alt="logo" preview={false} />
          <Title level={3} className="forgot-password__title">
            Recover Password
          </Title>
          <form className="form">
            <Input 
              ref={inputLoginRef} 
              className="form__item" 
              value={name} onChange={(e) => setName(e.target.value)} 
              placeholder="Login" 
              />
            <Button type="primary" className="form__item">
              Recover
            </Button>
            <Link to='/login' style={{width: '100%'}}>
              <Button type="primary" style={{width: '100%'}}>
                Back
              </Button>
            </Link>
          </form>
        </div>
      </Col>
    </Row>
  );
};

export default ForgotPassword;
