import {
  GET_USERS_BIRTHDAYS_REQUEST,
  GET_USERS_BIRTHDAYS_SUCCESS,
  GET_USERS_BIRTHDAYS_FAILURE,
  GET_CLIENTS_BIRTHDAYS_REQUEST,
  GET_CLIENTS_BIRTHDAYS_SUCCESS,
  GET_CLIENTS_BIRTHDAYS_FAILURE,
} from '../actionTypes';

const initialState = {
  usersBirthdays: null,
  clientsBirthdays: null,
  usersLoading: false,
  clientsLoading: false,
  getUsersBirthdaysError: null,
  getClientsBirthdaysError: null,
};

const birthdaysReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USERS_BIRTHDAYS_REQUEST:
      return { ...state, usersLoading: true };
    case GET_USERS_BIRTHDAYS_SUCCESS:
      return { ...state, usersBirthdays: action.data, getUsersBirthdaysError: null, usersLoading: false };
    case GET_USERS_BIRTHDAYS_FAILURE:
      return { ...state, getUsersBirthdaysError: action.error, usersLoading: false };
    case GET_CLIENTS_BIRTHDAYS_REQUEST:
      return { ...state, clientsLoading: true };
    case GET_CLIENTS_BIRTHDAYS_SUCCESS:
      return { ...state, clientsBirthdays: action.data, getClientsBirthdaysError: null, clientsLoading: false };
    case GET_CLIENTS_BIRTHDAYS_FAILURE:
      return { ...state, getClientsBirthdaysError: action.error, clientsLoading: false };
    default:
      return { ...state };
  }
};

export default birthdaysReducer;
