import React from 'react';

export const BadSmile = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="37" viewBox="0 0 36 37">
      <defs></defs>
      <g transform="translate(-597 -882)">
        <ellipse
          className="a"
          style={{ fill: '#f2895a' }}
          cx="18"
          cy="18.5"
          rx="18"
          ry="18.5"
          transform="translate(597 882)"
        />
        <g transform="translate(-28 -3)">
          <circle
            className="b"
            style={{ fill: 'none', stroke: '#9b491e', strokeMiterlimit: 10, strokeWidth: '2px' }}
            cx="11"
            cy="11"
            r="11"
            transform="translate(632 893)"
          />
          <path
            className="c"
            style={{
              fill: 'none',
              stroke: '#9b491e',
              strokeMiterlimit: 10,
              strokeWidth: '2px',
              strokeLinecap: 'round',
            }}
            d="M-134.684-405.387a1.51,1.51,0,0,1-1.638,1.334,1.51,1.51,0,0,1-1.639-1.334"
            transform="matrix(0.875, -0.485, 0.485, 0.875, 954.075, 1190.086)"
          />
          <path
            className="c"
            style={{
              fill: 'none',
              stroke: '#9b491e',
              strokeMiterlimit: 10,
              strokeWidth: '2px',
              strokeLinecap: 'round',
            }}
            d="M-116.173-405.387a1.51,1.51,0,0,1-1.639,1.334,1.51,1.51,0,0,1-1.638-1.334"
            transform="matrix(0.891, 0.454, -0.454, 0.891, 568.3, 1316.294)"
          />
          <path
            className="c"
            style={{
              fill: 'none',
              stroke: '#9b491e',
              strokeMiterlimit: 10,
              strokeWidth: '2px',
              strokeLinecap: 'round',
            }}
            d="M-177.048-391.777c0-1.471-1.465-2.663-3.273-2.663s-3.273,1.192-3.273,2.663"
            transform="translate(823.366 1301.315)"
          />
        </g>
      </g>
    </svg>
  );
};
