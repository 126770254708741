import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import ProjectStatus from '../../../pages/Tasks/ProjectStatus';
import CustomizedEmpty, { CustomizedEmptyComponent } from '../CustomizedEmpty/CustomizedEmpty';
import { getColumnsData, getStringUppercase } from '../../../utils/helpers';
import { Table, Collapse, ConfigProvider, Pagination } from 'antd';
// import { EditFilled } from '@ant-design/icons';
import { ReactComponent as UnlockSvg } from '../../../assets/images/unlock2.svg';
import { ReactComponent as LockSvg } from '../../../assets/images/lock2.svg';
import { ReactComponent as RightArrowSvg } from '../../../assets/images/rightArrow.svg';
import './styles.scss';




const { Panel } = Collapse;

const MobileTable = ({ columns, items, setUpdateVisible, setTaskId, typeData, 
  page, setPage, limit, setLimit 
}) => { //items
  // const [page, setPage] = useState(1);
  const navigate = useNavigate();

  const onPageChange = (num) => {
    setPage(num);
    window.scroll(0, 0);
  };

  // console.log('items: ', items);


  let data = getColumnsData(columns, items, `${typeData}`); //tasks === items???
  const customizeRenderEmpty = CustomizedEmpty(`No ${typeData}`);


  const getDataDoubleSource = (dataRows) => {

    let count = 1;
    let res = dataRows?.map((dataRow) => {

      let oneRow = []; //d src
      for (let elem in dataRow) {
        let label = getStringUppercase(elem);

        oneRow.push({ //d src
          key: `${count++}`,
          label: `${label}`,
          value: (label === 'Status' && typeData === 'tasks') ? 
            <ProjectStatus status={dataRow[`${elem}`]} /> : dataRow[`${elem}`],
        })
      }
      return oneRow;
      
    });
    return res;
  }
  let dataDoubleSource = getDataDoubleSource(data);
  
  const miniColumns = [
    {
      title: '',
      dataIndex: 'label',
      key: 'label',
      render: (text) => <b>{text}</b>,
    },

    {
      title: '',
      dataIndex: 'value',
      key: 'value',
    },
  ];

  let getMiniHeader = (...args) => { //values, id
    const [values, id] = args;

    if (typeData === 'tasks') {
      return (
        // <div style={{display: 'flex', flexDirection: 'column'}} className='miniHeader'>
        <div className='miniHeader__task'>
          <div className='firstRow'>
            <span>{values?.phaseLabel}</span> 
              <button 
                className='edit-button'
                onClick={() => {
                  setUpdateVisible(true);
                  setTaskId(id);
                }}>
                Edit
              {/* <EditFilled className="edit-icon"/> */}
            </button>
          </div>
          <div className='secondRow'>
            <span className='task-label'>{values.taskLabel}</span>
            <span className='hours'>
              {/* [<span></span>{values?.workedHours}/{values?.estimationTime}] */}
              [<span className='hours-spent'>{values?.workedHours}</span>/<span className='hours-estimated'>{values?.estimationTime}</span>]

            </span>
          </div>
        </div>
      )
    }

    if (typeData === 'projects') {
      const onClick = () => {
        navigate(`/projects/${id}/summary`);
      }
      // console.log('values?.client: ', values?.client, ' ', values?.client?.length)
      // console.log('values?.company: ', values?.company)
      // values?.company

      return(<>
        <div className='miniHeader__project'>
          <div className='firstRow'>
            {values?.client} {values?.company ? `(${values?.company})` : ''}
            </div>
          {/* <div className='miniHeader__task'> */}
            <span className='secondRow'>
              <span className='title'>{values.name}</span>
              {values.status === 'Open' ? <span className='wrapperSvg'><UnlockSvg /></span> : <span className='wrapperSvg'><LockSvg /></span>} 
            </span>
            <button className={`button fullInfoButton`} onClick={onClick}>Full Info</button>
            <button className={`button arrowButton`} onClick={onClick}><RightArrowSvg /></button>

          {/* </div> */}
        </div>
      </>)
    }
  }


  return (
    <>
    {items?.length !== 0 ?
      <div className={`mobileTable ${typeData === 'tasks' ? 'mobileTable__in-table' : ''}`}>
        <Collapse accordion>
        {dataDoubleSource?.map((almostDataSource) => {
          let mobileItem = almostDataSource.reduce((sum, elem) => {
            return {...sum, [`${elem.label.toLowerCase()}`]: elem.value}
          }, []);

          let miniHeaderr = getMiniHeader(mobileItem.header, mobileItem.id);
          let dataSource = almostDataSource.filter((elem) => elem.label !== 'Id' && elem.label !== 'Header' );

          return (
            <Panel 
              header={miniHeaderr}
              key={mobileItem.id}>
              <ConfigProvider renderEmpty={customizeRenderEmpty}>
                <Table 
                  columns={miniColumns} 
                  dataSource={dataSource}
                  showHeader={false}
                  pagination={false}
                  size='small' />
              </ConfigProvider>
            </Panel>
          )}
        )}
        </Collapse>
        {/* <Pagination 
            current={page}
            // defaultPageSize={10}
            showSizeChanger
            pageSize={limit ? limit : 10} 
            // pageSize={10} //limit
            onShowSizeChange={(newLimit) => setLimit(newLimit)}

            onChange={onPageChange} 
            total={items?.length} 

            /> */}
      </div>
      : <CustomizedEmptyComponent text={`No ${typeData}`}/>}
    </>
  )
}

export default MobileTable;

