import {
  GET_PROJECTS_REQUEST,
  GET_PROJECTS_SUCCESS,
  GET_PROJECTS_FAILURE,
  GET_SINGLE_PROJECT_REQUEST,
  GET_SINGLE_PROJECT_SUCCESS,
  GET_SINGLE_PROJECT_FAILURE,
  GET_INFRASTRUCTURES_REQUEST,
  GET_INFRASTRUCTURES_SUCCESS,
  GET_INFRASTRUCTURES_FAILURE,
} from '../actionTypes';

const initialState = {
  projects: null,
  projectsError: null,
  projectsLoading: false,
  singleProject: null,
  singleProjectError: null,
  singleProjectLoading: false,
  infrastructures: null,
  infrastructuresError: null,
  infrastructuresLoading: false,
};

const projectsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROJECTS_REQUEST:
      return { ...state, projectsLoading: true };
    case GET_PROJECTS_SUCCESS:
      return { ...state, projects: action.data, projectsLoading: false };
    case GET_PROJECTS_FAILURE:
      return { ...state, projectsError: action.error, projectsLoading: false };
    case GET_SINGLE_PROJECT_REQUEST:
      return { ...state, singleProjectLoading: true };
    case GET_SINGLE_PROJECT_SUCCESS:
      return { ...state, singleProject: action.data, singleProjectLoading: false };
    case GET_SINGLE_PROJECT_FAILURE:
      return { ...state, singleProjectError: action.error, singleProjectLoading: false };
    case GET_INFRASTRUCTURES_REQUEST:
      return { ...state, infrastructuresLoading: true };
    case GET_INFRASTRUCTURES_SUCCESS:
      return { ...state, infrastructures: action.data, infrastructuresLoading: false };
    case GET_INFRASTRUCTURES_FAILURE:
      return { ...state, infrastructuresError: action.error, infrastructuresLoading: action.error }

    default:
      return { ...state };
  }
};

export default projectsReducer;
