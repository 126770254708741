import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getContacts } from '../../store/actions/contactsActions';
import { Spin, Typography, Row, Col, Divider, FloatButton } from 'antd';
import ContactItem from './ContactItem';
import Container from '../../components/layout/Container/Container';
import ContactsFilter from './ContactsFilter';
import { titleLevel } from '../../utils/helpers';
import './styles.scss';

const { Title } = Typography;

const Contacts = () => {
  const dispatch = useDispatch();
  const contacts = useSelector((state) => state.contact?.contacts);
  const contactsLoading = useSelector((state) => state.contact.loading);
  const [contactsList, setContactsList] = useState([]);
  useEffect(() => {
    dispatch(getContacts());
    setContactsList(contacts?.data?.items ?? []);
  }, [dispatch]);
  useEffect(() => {
    setContactsList(contacts?.data?.items);
  }, [contacts]);

  const contactsJSX = contactsList
    ?.sort((a, b) => a.lastName - b.lastName)
    .map((item) => {
      return <ContactItem item={item} key={item.id}/>;
    });

  return (
    <Container>
      <Spin spinning={contactsLoading}>
        <Title level={titleLevel()}>Contacts</Title>
        <Row gutter={16}>
          <Col xs={24} sm={24} md={24} lg={24}>
            <ContactsFilter setContactsList={setContactsList} contacts={contacts} />
          </Col>
          <Divider />
          <Col xs={24} sm={24} md={24} lg={24}>
            <Row gutter={16}>{contactsJSX}</Row>
          </Col>
        </Row>
      </Spin>
      <FloatButton.BackTop type="primary"/>
    </Container>
  );
};

export default Contacts;
