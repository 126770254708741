import React from 'react';
import { Switch, Descriptions } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

const DashboardSettings = ({ settings, setSettings }) => {
  const changeHandler = (input) => {
    const settingsCopy = {...settings};
    settingsCopy[input] = !settingsCopy[input];
    setSettings(settingsCopy);
  };

  return (
    <div className="settings__dashboard">
      <Descriptions bordered column={1} size={'small'}>
        <Descriptions.Item label={<b>Calendar fullwidth</b>} labelStyle={{ width: '70%' }}>
          <div className="settings__dashboard_switch">
            <Switch
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              checked={settings?.calendarFullwidth}
              onChange={() =>changeHandler('calendarFullwidth')}
            />
          </div>
        </Descriptions.Item>
        <Descriptions.Item label={<b>Birthdays</b>}>
          <div className="settings__dashboard_switch">
            <Switch
              checkedChildren="on"
              unCheckedChildren="off"
              checked={settings?.birthdays}
              onChange={() =>changeHandler('birthdays')}
            />
          </div>
        </Descriptions.Item>
        <Descriptions.Item label={<b>Dayoffs</b>}>
          <div className="settings__dashboard_switch">
            <Switch
              checkedChildren="on"
              unCheckedChildren="off"
              checked={settings?.dayoffs}
              onChange={() =>changeHandler('dayoffs')}
            />
          </div>
        </Descriptions.Item>
        <Descriptions.Item label={<b>Events</b>}>
          <div className="settings__dashboard_switch">
            <Switch
              checkedChildren="on"
              unCheckedChildren="off"
              checked={settings?.events}
              onChange={() =>changeHandler('events')}
            />
          </div>
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
};

export default DashboardSettings;
