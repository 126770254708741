import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import store from './store';
import axiosWH from './utils/axios';
import { LOGOUT_USER } from './store/actionTypes';
import moment from 'moment';
import 'moment/locale/en-nz';
import 'moment/locale/en-gb';
import { Provider } from 'react-redux';
import App from './App';
import 'antd/dist/reset.css'
import './index.scss';

moment.locale('en-nz');

axiosWH.interceptors.request.use((config) => {
  try {
    config.headers['Authorization'] = process.env.REACT_APP_API_KEY;
  } catch (e) {
    // do nothing
  }
  return config;
});

axiosWH.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    if (err.response.status === 401 || err.response.status === 403) {
      localStorage.removeItem('user');
      store.dispatch({ type: LOGOUT_USER });
      window.location = '/login';
    }
    throw err;
  }
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <ConfigProvider>
        <App />
      </ConfigProvider>
    </BrowserRouter>
  </Provider>
);
