import {
  GET_CONTACTS_REQUEST,
  GET_CONTACTS_SUCCESS,
  GET_CONTACTS_FAILURE,
  GET_LOCATIONS_REQUEST,
  GET_LOCATIONS_SUCCESS,
  GET_LOCATIONS_FAILURE,
  GET_TEAMS_REQUEST,
  GET_TEAMS_SUCCESS,
  GET_TEAMS_FAILURE,
} from '../actionTypes';

const initialState = {
  contacts: null,
  teams: null,
  locations: null,
  singleContact: null,
  getContactError: null,
  getSingleContactError: null,
  loading: false,
  metaInfoLoading: false,
};

const contactsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CONTACTS_REQUEST:
      return { ...state, loading: true };
    case GET_CONTACTS_SUCCESS:
      return { ...state, contacts: action.data, loading: false, getContactError: null };
    case GET_CONTACTS_FAILURE:
      return { ...state, loading: false, getContactError: action.error };
      case GET_TEAMS_REQUEST:
      return { ...state, metaInfoLoading: true };
    case GET_TEAMS_SUCCESS:
      return { ...state, metaInfoLoading: false, teams: action.data };
    case GET_TEAMS_FAILURE:
      return { ...state, metaInfoLoading: false };
    case GET_LOCATIONS_REQUEST:
      return { ...state, metaInfoLoading: true };
    case GET_LOCATIONS_SUCCESS:
      return { ...state, metaInfoLoading: false, locations: action.data };
    case GET_LOCATIONS_FAILURE:
      return { ...state, metaInfoLoading: false };
    default:
      return { ...state };
  }
};

export default contactsReducer;
