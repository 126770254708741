import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import WhitterItem from './WhitterItem';
import whitterLogo from '../../assets/images/whitter.jpg';

const Whitter = ({ logo = false }) => {
  const news = useSelector((state) => state.news.news);
  const newsJSX = news?.data.items.map((item) => {
    return (
      <WhitterItem
        key={item.id}
        id={item.id}
        title={item.title}
        date={item.date}
        body={item.body}
        rating={item.rating}
        haveBody={item.haveBody}
      />
    );
  });

  return (
    <div className="whitter">
      {logo && (
        <Link to="/news">
          <img src={whitterLogo} alt="whitter" className="whitter__logo" />
        </Link>
      )}
      {newsJSX}
      {logo && (
        <div className="whitterItem__moreNews">
          <Link to="/news">More news...</Link>
        </div>
      )}
    </div>
  );
};

export default Whitter;
