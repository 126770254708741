import React, { useEffect, useState } from 'react';
import { Button, Col, Input, Popover, Select, Popconfirm, Table } from 'antd';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { status_project, phaseHoursExceeded } from '../../utils/constants';
import AddTaskModal from './AddTaskModal';
import { isRussian } from '../../utils/helpers';
import RemoveWrapper from '../../components/shared/RemoveWrapper/RemoveWrapper';

const { TextArea } = Input;
const { Option } = Select;

const HoursItem = ({ remove, row, onChange, phases, tasks, error, cancelDeleteHour, deleteHours, rows, selectedDate, frontErrors }) => {
  const [textAreaFocus, setTextAreaFocus] = useState(false);
  const [phaseTasks, setPhaseTasks] = useState([]);
  const [phaseSelected, setPhaseSelected] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);

  let backErrors = error;
  //"Amount of hours for phase is exceeded."

  let backendCardErrors = {['cardId']: null, ['amount']: null, ['task']: null, ['totalAmount']: null, ['phase']: [], ['other']: []};
//backendCardErrors //currentBackErrors

  if(error?.id === row?.id) {
    backErrors?.message?.forEach((elem) => {
      if (elem === phaseHoursExceeded) {
      // if (elem === 'njmnknk') {
        // let res = !!backendCardErrors?.phase ? [...backendCardErrors.phase, phaseHoursExceeded] : [phaseHoursExceeded];  
        backendCardErrors?.phase?.push(phaseHoursExceeded);
        backendCardErrors?.cardId?.push(error?.id);
  
      } else {
        backendCardErrors?.other?.push(elem);
        backendCardErrors?.cardId?.push(error?.id);
      }
  
    })
  }

  useEffect(() => {
    setPhaseSelected(row.phase);
  }, [row]);
  useEffect(() => {
    if (phaseTasks?.length === 0) {
      onChange(null, 'task', row.id);
    }
  }, [phaseTasks]);

  useEffect(() => {
    if (phaseSelected && tasks) {
      // const currentDate = new Date();
      selectedDate = !!selectedDate ? selectedDate : new Date();
      const currentDate = new Date(selectedDate);

      const filteredTasks = tasks?.filter(
        // (task) => task?.phase.id === phaseSelected && task?.status === 'Open' && new Date(task.endDate) > currentDate
        (task) => 
          task?.phase.id === phaseSelected && 
          task?.status === 'Open' && 
          new Date(task.endDate).toLocaleDateString('en-CA') >= currentDate.toLocaleDateString('en-CA') &&
          new Date(task.startDate).toLocaleDateString('en-CA') <= currentDate.toLocaleDateString('en-CA')
      );

      setPhaseTasks(filteredTasks);
      filteredTasks?.length > 0 && onChange(filteredTasks[0]?.id, 'task', row.id);
    }
  }, [phaseSelected, tasks]);

  
  const selectPhaseHandler = (value) => {
    setPhaseSelected(value);
    onChange(value, 'phase', row.id)
  };
  const errorHandler = (name) => {
    return !!(error && error.id === row.id && error?.message?.join('').includes(name));
  };

  let filteredDeleteHour = deleteHours?.filter((item) => row.id === item)[0];


  return (
    <Col xs={24} sm={24} md={12} lg={8}>
    <RemoveWrapper
    	condition = {filteredDeleteHour === row.id}
      buttonName = "deleting hours"
      cancelDelete = {cancelDeleteHour}
    >
    {/* <div className={`hours__item_wrapper`}> */}

      {/* <div className={`${filteredDeleteHour === row.id ? 'hours__untouchable' : 'hours__hidden' }`}></div>
      <div className={`${filteredDeleteHour === row.id ? 'hours__over_untouchable hours__visible' : 'hours__hidden'}`}>
        <div className={`${filteredDeleteHour === row.id ? 'hours__visible hours__wrapper_cancel_button' : 'hours__hidden'}`}>
          <Button danger icon={<CloseOutlined />} 
          onClick={cancelDeleteHour}  
          ghost style={{ width: '100%', backgroundColor: 'white'}}>
            Cancel deleting hours
          </Button>
        </div>
      </div> */}

      <AddTaskModal
        open={modalVisible}
        close={() => setModalVisible(false)}
        phases={phases}
        phase={phaseSelected}
        onChange={onChange}
        id={row.id}
      />
      <div className="hours__item" 
        style={backendCardErrors?.other?.length !== 0 ? 
          {border: '1px solid #ff4d4f', borderRadius: '7px'} 
          : {border: '1px solid #d3d3d3', borderRadius: '7px'}}
          >
        <div className="hours__item_section">
          <Select
            // style={{ width: '100%'  }}
            style={backendCardErrors?.phase?.length !== 0 ? {border: '1px solid #ff4d4f', borderRadius: '7px', width: '100%'} : {border: '1px solid transparent', borderRadius: '7px', width: '100%'}}

            // backendCardErrors
            placeholder="Phase"
            value={phaseSelected}
            onChange={(value) => selectPhaseHandler(value)}
          >
            {phases.map((item) => (
              <Option value={item.id} key={item.id}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <span>
                    <b>[{item.project.name}] </b>
                    {item.name}
                  </span>
                  <span style={{ color: 'gray' }}>[{item?.maxHours}]</span>
                </div>
              </Option>
            ))}
          </Select>
        </div>
        <div className="hours__item_section">
          <Select
            style={errorHandler('task')|| frontErrors?.task ? { border: '1px solid #ff4d4f', width: '85%', borderRadius: '7px' } : { border: '1px solid transparent', width: '85%' }}
            placeholder="Task"
            value={row.task}
            disabled={!phaseSelected}
            onSelect={(value) => onChange(value, 'task', row.id)}
          >
            {phaseTasks?.map((item) => (
              <Option value={item.id} key={item.id}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <span>{item.name}</span>
                  <span style={{ color: 'gray' }}>
                    [{item.workedHours}/{item.estimationTime}]
                  </span>
                </div>
              </Option>
            ))}
          </Select>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            ghost
            onClick={() => setModalVisible(true)}
            style={{ width: '10%', alignSelf: 'center' }}
            // disabled={phaseTasks.length === 0}
            disabled={!phaseSelected}
          />
        </div>
        <div className="hours__item_section">
          <Input
            placeholder="Hours"
            style={errorHandler('amount')|| frontErrors?.amount || frontErrors?.totalAmount ? { border: '1px solid #ff4d4f', width: '47.5%' } : { width: '47.5%' }}
            value={row.amount}
            disabled={!phaseSelected}
            type="number"
            min="0"
            max="24"
            step="0.5"
            onChange={(e) => onChange(e.target.value, 'amount', row.id)}
          />
          <Select style={{ width: '47.5%' }} value={row.status} onChange={(value) => onChange(value, 'status', row.id)}>
            {status_project.map((item) => (
              <Option value={item.id} key={item.id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </div>
        <div className="hours__item_section">
          <Popover
            content="Looks like you are going to add comment with non latin symbols. Are you sure?"
            open={isRussian(row.comment) && textAreaFocus}
          >
            <TextArea
              placeholder="Comment"
              rows={row.comment?.length === 0 ? 2 : Math.ceil(row.comment?.length / 100) + 1}
              style={{ width: '100%', resize: 'none' }}
              value={row.comment}
              status={isRussian(row.comment) ? 'error' : null}
              onChange={(e) => onChange(e.target.value, 'comment', row.id)}
              onFocus={() => setTextAreaFocus(true)}
              onBlur={() => setTextAreaFocus(false)}
            />
          </Popover>
        </div>
        <div className="hours__item_section">
          {/* <Popconfirm
            title="Delete this hours record?"
            description="Are you sure to delete this task?"
            onConfirm={remove}
            // onConfirm={predeletionHour}

            okText="Yes"
            cancelText="No"
          > */}
          {
          !(row?.phase === null && row?.comment === "" && row?.status === "IN_PROGRESS" && rows?.length === 1) && <Button 
            danger 
            icon={<CloseOutlined />} 
            ghost 
            style={{ width: '100%' }} 
            onClick={remove}>
            Delete hours
          </Button>
          }
          {/* </Popconfirm> */}

        </div>
      </div>

    {/* </div> */}

    </RemoveWrapper>
    </Col>
  );
};

export default HoursItem;
