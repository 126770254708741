import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getLocations, getTeams } from '../../store/actions/contactsActions';
import { getUniqueArray, getSortedAlphabet } from '../../utils/helpers';
import { Button, Input, Select, Row, Col } from 'antd';
import { ClearOutlined } from '@ant-design/icons';

const ContactsFilter = ({ setContactsList, contacts }) => {
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');
  const [searchTeams, setSearchTeams] = useState(null);
  const [searchLocations, setSearchLocations] = useState(null);
  const locations = useSelector((state) => state.contact?.locations);
  const teams = useSelector((state) => state.contact.teams);



  useEffect(() => {
    dispatch(getLocations());
    dispatch(getTeams());
  }, [dispatch]);

  useEffect(() => {
    applyFilters();
  }, [searchTeams, searchLocations, searchTerm]);

  const locationsList = [
    { value: null, label: 'All locations' },
    ...(locations?.data.items || []).map(({ id, name }) => ({ value: id, label: name })),
  ];
  const teamsList = [
    { value: null, label: 'All teams' },
    ...(getSortedAlphabet(teams?.data.items, 'name') || []).map(({ id, name }) => ({ value: id, label: name })),
  ];

  const handleChangeLocation = (value) => {
    setSearchLocations(value);
  };
  const handleChangeTeam = (value) => {
    setSearchTeams(value);
  };
  const resetFilters = () => {
    setSearchTerm('');
    setSearchTeams(null);
    setSearchLocations(null);
    setContactsList(contacts?.data.items);
  };
  const applyFilters = () => {
    let updatedContacts = contacts?.data.items;
    if (searchTerm) {
      // updatedContacts = updatedContacts.filter(
      //   (item) => item.firstName.toLowerCase().search(searchTerm.toLowerCase().trim()) !== -1
      // );

      //creating
      let getShortContacts = (arr) => {
        let res = arr.map((contact) => ({['id']: contact.id, ['name']: `${contact.lastName} ${contact.firstName}`}));
        return res;
      }
      let getShortReverseContacts = (arr) => {
        let res = arr.map((contact) => ({['id']: contact.id, ['name']: `${contact.firstName} ${contact.lastName}`}));
        return res;
      }
      let getShortInitialsContacts = (arr) => {
        let res = arr.map((contact) => ({['id']: contact.id, ['name']: `${contact?.lastName?.split('')[0]} ${contact?.firstName?.split('')[0]}`}));
        return res;
      }
      let getShortReverseInitialsContacts = (arr) => {
        let res = arr.map((contact) => ({['id']: contact.id, ['name']: `${contact?.firstName?.split('')[0]} ${contact?.lastName?.split('')[0]}`}));
        return res;
      }
      let shortContacts = getShortContacts(updatedContacts);
      let shortReverseContacts = getShortReverseContacts(updatedContacts);
      let shortInitials = getShortInitialsContacts(updatedContacts);
      let shortReverseInitials = getShortReverseInitialsContacts(updatedContacts);

      //filtering
      const getFilterName = (shortArr) => {
        let res = shortArr.filter(
          (item) => item.name.toLowerCase().includes(searchTerm.toLowerCase().trim())
        );
        return res;
      }

      let filterFullname = getFilterName(shortContacts);
      let filterReverseFullname = getFilterName(shortReverseContacts);
      let filterInitials = getFilterName(shortInitials);
      let filterReverseInitials = getFilterName(shortReverseInitials);


      let getGeneralFilterFullname = () => {
        if (!searchTerm.includes(' ') || searchTerm.includes(' ') && searchTerm?.split('')?.length > 3) {
          return getUniqueArray([...filterFullname, ...filterReverseFullname]);
        }
        else return getUniqueArray([...filterInitials, ...filterReverseInitials]);
      };
      let generalFilterFullname = getGeneralFilterFullname();
            
      let resFilter = generalFilterFullname.map((fullnameContact) => //comparing initial arr with filtering arr
        updatedContacts.filter((updatedContact) => updatedContact.id === fullnameContact.id)
      );
      let newFilteredContacts = [].concat(...resFilter);

      updatedContacts = newFilteredContacts;
    }
    
    if (searchTeams) {
      updatedContacts = updatedContacts.filter((item) => item.team.id === searchTeams);
    }
    if (searchLocations) {
      updatedContacts = updatedContacts.filter((item) => item.location.id === searchLocations);
    }
    setContactsList(updatedContacts);
  };

  return (
    <Row gutter={16}>
      <Col xs={24} sm={24} md={10} lg={10}>
        <Input
          placeholder="Search contact"
          onChange={(e) => setSearchTerm(e.target.value)}
          value={searchTerm}
          className="contacts__filters_input"
        />
      </Col>
      <Col xs={24} sm={24} md={14} lg={14}>
        <div className='wrapperSelectsWithReset'>
          <div className='wrapperSelects'>
            <Select
              placeholder="Location"
              onChange={handleChangeLocation}
              value={searchLocations}
              options={locationsList}
              className="contacts__filters_select"
            />
            <Select
              placeholder="Teams"
              onChange={handleChangeTeam}
              value={searchTeams}
              options={teamsList}
              className="contacts__filters_select"
            />

          </div>
          <Button type="primary" className="contacts__filters_button" onClick={resetFilters}>
            <span className="contacts__filters_buttonText">Reset Filters</span>
            <ClearOutlined className="contacts__filters_buttonIcon" />
          </Button>

        </div>
      </Col>
    </Row>

  );
};

export default ContactsFilter;
