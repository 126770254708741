import React from 'react';
import UserInfoModal from '../UserInfoModal/UserInfoModal';
import { StarFilled } from '@ant-design/icons';
import { Alert, Collapse, Descriptions, Table } from "antd";
import { getFullname, getNiceRole, getSortedAlphabet } from '../../../utils/helpers';
import { CustomizedEmptyComponent } from '../CustomizedEmpty/CustomizedEmpty';
import './styles.scss';



const TeamListItem = ({ users, typeEmployees, setUserId, setIsModalVisible }) => { //users = data?.userProjectPhases
    console.log('users: ', users);
    let developers = [];
    let managers = [];

    // let activeUsers = users?.filter((user) => user?.status === 'Active');
    const getSortedAlphabetUsers = (objectsArray) => objectsArray?.sort((a, b) => a?.user?.team?.name > b?.user?.team?.name ? 1 : -1);
    users = getSortedAlphabetUsers(users);

    users?.forEach((user, index) => {
        let roleName = user?.role?.name;
        if (roleName === 'ProjectManager' || roleName === 'SalesManager' || roleName === 'CustomerCareManager' || roleName === 'FinancialManager') managers.push(user);
        else developers.push(user)
    })
    //'ProjectManager', 'SalesManager', 'CustomerCareManager', 'Developer'

    return(<>  
      <Alert type="info" message={<b>{typeEmployees}</b>} banner showIcon={false} />
      <Descriptions bordered column={1} size={'small'}>
      {users?.map((person, index) => {
        //if person?.status === "Active"
        let prevPerson = users[index - 1];

        let role = typeEmployees === 'Managers' ? getNiceRole(person?.role, 'full') : person?.user?.team?.name;
        let prevPersonRole = typeEmployees === 'Managers' ? getNiceRole(prevPerson?.role, 'full') : prevPerson?.user?.team?.name;
        let cutRole = typeEmployees === 'Managers' ? getNiceRole(person?.role, 'cut') : person?.user?.team?.name;
        //person?.user?.team?.name
        {/* console.log('current person: ', person)
        console.log('prevPerson: ', prevPerson)
        console.log('role: ', role)
        console.log('prevPersonRole: ', prevPersonRole)
        console.log('prevPersonRole !== role ', prevPersonRole !== role)
        console.log('prevPersonRole !== role ? role : ""') */}
  //typeEmployees === 'Managers'
        let isShowRole = (prevPersonRole !== role && typeEmployees === 'Developers') || typeEmployees === 'Managers';
        return(
          <Descriptions.Item 
            label={<b>
              <span className="fullRole">{ isShowRole ? role : ''}</span>
              <span className="cutRole">{ isShowRole ? cutRole : ''}</span>
              {/* <span className="fullRole">{ prevPersonRole === role ? '' : role}</span>
              <span className="cutRole">{ prevPersonRole === role ? '' : cutRole}</span> */}
            </b>} 
            labelStyle={{ width: "25%" }}
          >
            <div
              className='userFullname'
              onClick={() => {
                console.log('person?.user?.id: ', person?.user?.id)
                setUserId(person?.user?.id)
                setIsModalVisible(true)
            }}>
              {getFullname(person?.user)}
            </div>
  
            {person?.isLeader ? <span><StarFilled /></span> : <></>}
          </Descriptions.Item>
        )
      }
      )}
  
      </Descriptions>
    </>)
  }

  const TeamList = ({ allUsers, setUserId, setIsModalVisible, userId, isModalVisible }) => {
    let developers = [];
    let managers = []
    allUsers?.forEach((user) => {
      let roleName = user?.role?.name;

      if (roleName === 'ProjectManager' || roleName === 'SalesManager' || roleName === 'CustomerCareManager') managers.push(user)
      else developers.push(user)
    })
    return (<>
      <UserInfoModal id={userId} open={isModalVisible} close={() => setIsModalVisible(false)} userType={'Employees'}/>
      <div className='teamList'>
        {managers?.length === 0 && developers?.length === 0 ? <CustomizedEmptyComponent text={`No users`}/> : <></>}
        {managers?.length === 0 ? 
          <></> : 
          <TeamListItem 
            users={managers} 
            typeEmployees={"Managers"} 
            setUserId={setUserId} 
            setIsModalVisible={setIsModalVisible} 
            />}
        {developers.length === 0 ? 
          <></> : 
          <TeamListItem 
            users={developers} 
            typeEmployees={"Developers"} 
            setUserId={setUserId} 
            setIsModalVisible={setIsModalVisible} 
            />}
      </div>
    </>)
  }

  // export default TeamListItem;
  export default TeamList;

