import React from 'react';
import { Divider, Col, Table } from 'antd';



const ClosedHoursItem = ({ isClosed, currentItem }) => {
  
  const getDataSource = (elem) => {
    const dataSource = [
      {
        key: '1',
        label: 'Task',
        value: `${elem?.task?.name}`,
      },
      {
        key: '2',
        label: 'Status',
        value: `${elem?.task?.currentStatus}`,
      },
      {
        key: '3',
        label: 'Hours',
        value: `${elem?.amount}`,
      },
      {
        key: '4',
        label: 'Comment',
        value: `${elem?.comment}`,
      },
    ];
    return dataSource;
  }

  const columns = [
    {
      title: '',
      dataIndex: 'label',
      key: 'label',
    },

    {
      title: '',
      dataIndex: 'value',
      key: 'value',
    },
  ];

  // let dataSource = isClosed ? getDataSource(currentItem): [];
  let dataSource = getDataSource(currentItem);


  return (
    <Col xs={24} sm={24} md={12} lg={8}>
      <div className="hours__item">
        <div className="hours__item_section">
          <div className='hours__closed_item'>
            <h4><b>[{currentItem?.project?.name}]</b> {currentItem?.phase?.name}</h4>
            <Divider/>
            <Table 
            columns={columns} 
            dataSource={dataSource}
            showHeader={false}
            pagination={false} 
            size='small' />
          </div>
        </div>
      </div>
  </Col>
  )
}
export default ClosedHoursItem;
