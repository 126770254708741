import React from "react";
import { useSelector } from "react-redux";
import { Modal, Spin, Descriptions } from "antd";
import { dateHelper } from "../../../../utils/helpers";

const DetailsDayoffModal = ({ close, show }) => {
  const dayOffLoading = useSelector((state) => state.dayOff.dayOffLoading);
  const dayOff = useSelector((state) => state.dayOff?.dayOff);
  return (
    <Modal
      open={show}
      onCancel={close}
      footer={null}
      width={750}
      title={`${dayOff?.user.firstName} ${dayOff?.user.lastName}`}
    >
      <Spin spinning={dayOffLoading}>
        <Descriptions bordered column={1}>
          <Descriptions.Item label="Type" labelStyle={{ width: "40%" }}>
            {dayOff?.type}
          </Descriptions.Item>
          <Descriptions.Item label="Start Date">
            {dateHelper(dayOff?.startDate)}
          </Descriptions.Item>
          <Descriptions.Item label="End Date">
            {dateHelper(dayOff?.endDate)}
          </Descriptions.Item>
          <Descriptions.Item label="Reason">
            {dayOff?.description}
          </Descriptions.Item>
          <Descriptions.Item label="Going To Work">
            {dayOff?.workOff === 1 ? "YES" : "NO"}
          </Descriptions.Item>
          <Descriptions.Item label="Date Work Off">
            {dayOff?.dateWorkOff}
          </Descriptions.Item>
        </Descriptions>
      </Spin>
    </Modal>
  );
};

export default DetailsDayoffModal;
