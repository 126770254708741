export const hoursRefactor = (hours) => {
  return hours?.map((hour) => {
    return {
      key: hour?.id,
      phase: hour?.phase.name,
      task: hour?.task.name,
      hours: hour?.amount,
      comment: hour?.comment,
    };
  });
};
