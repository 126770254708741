import React, {useState, useEffect} from "react";
import { useDispatch } from "react-redux";
import parse from 'html-react-parser';
import { Alert, Collapse, Descriptions } from "antd";
import { getContacts } from "../../../store/actions/contactsActions";
import TeamList from "../../../components/UI/TeamList/TeamList";
import WorkedInPast from "../../../components/UI/WorkedInPast/WorkedInPast";
import BlockInfo from "../../../components/UI/BlockInfo/BlockInfo";
import { getUniqueUsers } from "../../../utils/helpers";
import { CustomizedEmptyComponent } from "../../../components/UI/CustomizedEmpty/CustomizedEmpty";



const { Panel } = Collapse;

const Summary = ({data}) => {
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [userId, setUserId] = useState(null);
  console.log('singleProject: ', data)

  useEffect(() => {
    dispatch(getContacts());
  }, []);

  const arrAbout = ["name", "otherNames", "emailAlias", "createdAt", "source", "isNda", "isInternal", "dateDeadline", "status"];
  const arrPaymentTerms = ["contractType", "paymentScheme", "estimatedHours", "estimatedMoney"];

  let activeUsers = data?.userProjectPhases?.filter((user) => user?.status === 'Active');
  let passiveUsers = data?.userProjectPhases?.filter((user) => user?.status !== 'Active');  

    
  return (
    <div className="summary">
      <div className='about_and_team'>

        <div className="summary__block">
          <BlockInfo data={data} header="About" array={arrAbout} />
          <br/>
          <Collapse accordion>
            <Panel header={<b>Description</b>} key="1">
              {data?.descriptionProduct ? 
                <p>{parse(data?.descriptionProduct)}</p> 
                : <CustomizedEmptyComponent text='No description'/>}
            </Panel>
          </Collapse>
        </div>

        <div className="summary__block">
          <Alert type="success" message={<h2>Team</h2>} banner showIcon={false} />
          <TeamList
            allUsers={getUniqueUsers(activeUsers)} 
            setUserId={setUserId} 
            setIsModalVisible={setIsModalVisible}
            userId={userId}
            isModalVisible={isModalVisible}
          />
          <br/>
          <WorkedInPast users={getUniqueUsers(passiveUsers)}/>
        </div>

      </div>
      <div className="paymentTerms">
        <BlockInfo data={data} header="Payment terms" array={arrPaymentTerms} />
      </div>
    </div>
  );
};

export default Summary;
