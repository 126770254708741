import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { createTasks } from '../../store/actions/tasksActions';
import { Modal, Typography, Row, Col } from 'antd';
import FormInput from '../../components/forms/FormInput';
import FormTextArea from '../../components/forms/FormTextArea';
import FormDatePicker from '../../components/forms/FormDatePicker';
import { getDatePickerValue } from '../../utils/helpers';

const { Title, Text } = Typography;

const AddTaskModal = ({ phases, phase, open, close, onChange, id }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const loading = useSelector((state) => state.tasks.loading);
  const selectedPhase = phases.find((item) => item.id === phase);
  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm({ mode: 'onSubmit' });


  useEffect(() => {
    setValue('startDate', new Date());
    setValue('endDate', new Date());
  }, []);

  const closeModal = () => {
    close();
    reset();
  };

  const createTaskHandler = async (data) => {
    let newData = {...data}
    newData.startDate = getDatePickerValue(data.startDate)
    newData.endDate = getDatePickerValue(data.endDate);

    const response = await dispatch(
      createTasks({ task: { ...newData, user: user.user.id, phase, currentStatus: 'IN_PROGRESS' } })
      // createTasks({ task: { ...data, user: user.user.id, phase, currentStatus: 'IN_PROGRESS' } })
    );

    onChange(response.id, 'task', id);

    close();
    reset();
  };


  return (
    <Modal
      title={
        <Row>
          <Col xs={24} sm={12} md={16} >
            <Title level={4} style={{ margin: 0 }}>
              {selectedPhase?.project.name} / <span className="hours__modal_phase">{selectedPhase?.name}</span>
            </Title>
          </Col>
          <Col xs={24} sm={12} md={8} className='hours__modal_maxHours'>
            <Text type="secondary">Max hours [{selectedPhase?.maxHours}]</Text>
          </Col>
        </Row>
      }
      closable={false}
      width={750}
      open={open}
      onOk={handleSubmit(createTaskHandler)}
      confirmLoading={loading}
      onCancel={closeModal}
      okText={'Save'}
    >
      <form onSubmit={handleSubmit(createTaskHandler)}>
        <FormInput
          name={'name'}
          title={'Name'}
          placeholder={'Name'}
          control={control}
          required={true}
          error={errors.name}
        />
        <FormInput
          name={'estimationTime'}
          title={'Estimation time'}
          placeholder={'Estimation time'}
          control={control}
          required={true}
          error={errors.estimationTime}
        />
        <FormDatePicker
          name={'startDate'}
          title={'Start Date'}
          control={control}
          required={true}
          error={errors.startDate}
        />
        <FormDatePicker
          name={'endDate'}
          title={'End Date'}
          control={control}
          required={true}
          error={errors.startDate}
        />
        <FormTextArea name={'descriptions'} title={'Description'} placeholder={'Description'} control={control} />
        <FormInput
          name={'taskManagerLink'}
          title={'Task manager link'}
          placeholder={'Task manager link'}
          control={control}
        />
      </form>
    </Modal>
  );
};

export default AddTaskModal;
