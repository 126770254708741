import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateDayoff, deleteDayoff } from '../../../../store/actions/daysoffsActions';
import { DatePicker, Modal, Select, Input, Space, Radio, Spin, Button } from 'antd';
import { dayOffTypes } from '../../../../utils/constants';
import { getDatePickerValue } from '../../../../utils/helpers';
// import moment from 'moment';
import dayjs from 'dayjs';



const { TextArea } = Input;

const dateFormat = 'YYYY-MM-DD';

const EditDayoffModal = ({ show, close }) => {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.user?.user?.id);
  const updateDayOffLoading = useSelector((state) => state.dayOff.updateDayOffLoading);
  const dayOffLoading = useSelector((state) => state.dayOff.dayOffLoading);
  const dayOff = useSelector((state) => state.dayOff?.dayOff);

  const [dayoffType, setDayoffType] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [reason, setReason] = useState(null);
  const [dateWorkOff, setDateWorkOff] = useState(null);
  const [willWork, setWillWork] = useState(null);

  useEffect(() => {
    if (dayOff) {
      setDayoffType(dayOff.type);
      // setStartDate(moment(dayOff.startDate).format('YYYY-MM-DD'));
      // setEndDate(moment(dayOff.endDate).format('YYYY-MM-DD'));
      setStartDate(dayjs(dayOff.startDate).format('YYYY-MM-DD'));
      setEndDate(dayjs(dayOff.endDate).format('YYYY-MM-DD'));
      setReason(dayOff.description);
      setDateWorkOff(dayOff.dateWorkOff);
      setWillWork(dayOff.workOff);
    }
  }, [dayOff]);

  const updateDayoffHandler = async () => {
    const dayOffData = {
      // user: userId,
      user: userId !== dayOff.user.id ? dayOff.user.id : userId,
      // startDate: moment(startDate).format('YYYY-MM-DD'),
      // endDate: moment(endDate).format('YYYY-MM-DD'),
      startDate: dayjs(startDate).format('YYYY-MM-DD'),
      endDate: dayjs(endDate).format('YYYY-MM-DD'),
      type: dayoffType,
      description: reason,
      dateWorkOff: dateWorkOff,
      workOff: willWork,
    };
    dispatch(updateDayoff(dayOff?.id, { dayoff: dayOffData }));
    closeModalHandler();
  };

  const closeModalHandler = () => {
    close();
  };

  const deleteDayoffHandler = () => {
    dispatch(deleteDayoff(dayOff.id));
    close();
  };

  return (
    // <Spin spinning={updateDayOffLoading}>
      <Modal
        open={show}
        title={`${dayOff?.user.firstName} ${dayOff?.user.lastName}`}
        onCancel={closeModalHandler}
        onOk={updateDayoffHandler}
        footer={[
          <Button onClick={closeModalHandler}>Cancel</Button>,
          <Button type="primary" danger onClick={() => deleteDayoffHandler()} disabled={dayOffLoading}>
            Delete
          </Button>,
          <Button type="primary" onClick={updateDayoffHandler} disabled={dayOffLoading}>
            Edit
          </Button>,
        ]}
      > 

        <Spin spinning={dayOffLoading}>
          <Spin spinning={updateDayOffLoading}>
            <Space direction={'vertical'} size={'middle'} style={{ width: '100%' }}>
              <Select
                dropdownMatchSelectWidth={225}
                value={dayoffType}
                onChange={(e) => setDayoffType(e)}
                options={dayOffTypes}
              />
              <Space>
                <DatePicker
                  placeholder="Start date"
                  size="large"
                  // defaultValue={moment(startDate, dateFormat)}
                  // value={dayjs(typeof(startDate) === 'string' ? startDate : startDate?.$d ? startDate?.$d.toLocaleDateString('en-CA') : startDate?.toLocaleDateString('en-CA'), dateFormat)}
                  // value={dayjs(getDatePickerValue(startDate, 'Edit | start'), dateFormat)}
                  
                  value={dayjs(getDatePickerValue(startDate), dateFormat)}
                  format={dateFormat}
                  onChange={(e) => setStartDate(e)}
                />
                <DatePicker
                  placeholder="End date"
                  size="large"
                  // defaultValue={moment(endDate, dateFormat)}
                  // value={dayjs(typeof(endDate) === 'string' ? endDate : endDate?.$d ? endDate?.$d.toLocaleDateString('en-CA') : endDate?.toLocaleDateString('en-CA'), dateFormat)}
                  value={dayjs(getDatePickerValue(endDate), dateFormat)}
                  // value={dayjs(getDatePickerValue(endDate, 'Edit | end'), dateFormat)}

                  format={dateFormat}
                  onChange={(e) => setEndDate(e)}
                />
              </Space>
              <TextArea
                rows={4}
                placeholder="Reason"
                size="large"
                value={reason}
                onChange={(e) => setReason(e.target.value)}
              />
              <Radio.Group onChange={(e) => setWillWork(e.target.value)} value={willWork}>
                <Space direction="vertical">
                  <Radio value={1}>I Will Work</Radio>
                  <Radio value={2}>I Will not Work</Radio>
                </Space>
              </Radio.Group>
              <Input
                placeholder="Enter your date work off"
                value={dateWorkOff}
                onChange={(e) => setDateWorkOff(e.target.value)}
              />
            </Space>
          </Spin>
        </Spin>
      </Modal>
    // </Spin>
  );
};

export default EditDayoffModal;
