import React, { useState } from 'react';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import AddDeveloperModal from './AddDeveloperModal';



const AddDeveloperButton = () => {
  const [modalShow, setModalShow] = useState(false);

  return (
    <>
			<Button 
				// type="primary"
				icon={<PlusOutlined />}
				onClick={() => setModalShow(true)}
			>
			Developer
			</Button>
			<AddDeveloperModal
				// id={id}
				show={modalShow}
				closeModal={() => setModalShow(false)}
			/>
    </>
  )
}

export default AddDeveloperButton;
