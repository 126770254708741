import React from 'react';

export const NormalSmile = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
      <defs></defs>
      <g transform="translate(-578 -696)">
        <circle className="a" style={{ fill: '#d4d4d4' }} cx="18" cy="18" r="18" transform="translate(578 696)" />
        <g transform="translate(585.062 703.062)">
          <circle
            className="b"
            style={{ fill: 'none', stroke: '#9a9a9a', strokeMiterlimit: 10, strokeWidth: '2px' }}
            cx="11"
            cy="11"
            r="11"
            transform="translate(-0.062 -0.062)"
          />
          <g transform="translate(5.988 7.163)">
            <line
              className="c"
              style={{
                fill: 'none',
                stroke: '#9a9a9a',
                strokeMiterlimit: 10,
                strokeWidth: '2px',
                strokeLinecap: 'round',
              }}
              y2="3.033"
            />
            <line
              className="c"
              style={{
                fill: 'none',
                stroke: '#9a9a9a',
                strokeMiterlimit: 10,
                strokeWidth: '2px',
                strokeLinecap: 'round',
              }}
              y2="3.033"
              transform="translate(9.992)"
            />
          </g>
          <line
            className="c"
            style={{
              fill: 'none',
              stroke: '#9a9a9a',
              strokeMiterlimit: 10,
              strokeWidth: '2px',
              strokeLinecap: 'round',
            }}
            x2="5.592"
            transform="translate(8.187 15.297)"
          />
        </g>
      </g>
    </svg>
  );
};
