import React, {useState} from 'react'
import CreateEventModal from './CreateEventModal';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';



const CreateEventButton = () => {
  const [createModalShow, setCreateModalShow] = useState(false);


  return (
	<>
		<Button icon={<PlusOutlined />} onClick={() => setCreateModalShow(true)}>New event</Button>
    <CreateEventModal
			// id={id}
			show={createModalShow}
			closeModal={() => setCreateModalShow(false)}
		/>
	</>
  )
}

export default CreateEventButton;
