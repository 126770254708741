import React, { useState } from 'react'
import { Modal, Form, Input, Select, Checkbox, Button } from 'antd';


const { TextArea } = Input;
const { Item } = Form;

const AddPhaseModal = ({show, closeModal}) => {
	const [phaseName, setPhaseName] = useState(null);
	const [hoursLimit, setHoursLimit] = useState(null);
	// const [stateIsLeader, setStateIsLeader] = useState(null);
	const [description, setDescription] = useState(null);
	const [type, setType] = useState(null);
	const [timeout, setTimeout] = useState(null);
	const [form] = Form.useForm();

	const reset = () => {
		setPhaseName(null);
		setHoursLimit(null);
		setDescription(null);
		setType(null);
		setTimeout(null);
	}

	const notRealOptions = [
		{
			value: '1',
			label: 'Not Identified',
		},
		{
			value: '2',
			label: 'Closed',
		},
		{
			value: '3',
			label: 'Communicated',
		},
		{
			value: '4',
			label: 'Identified',
		},
		{
			value: '5',
			label: 'Resolved',
		},
		{
			value: '6',
			label: 'Cancelled',
		},
	]

  return (
		<Modal
			title="Add new phase"
			centered
			open={show}
			onOk={() => {
				closeModal();
			}}
			onCancel={() => closeModal()}
			footer={[]}
		>
			<div className='project'>
				<Form
					form={form}
					onFinish={(e) => {
						console.log(e);
						form.resetFields();
						closeModal();
					}}
					onFinishFailed={(failedValues) => console.log({failedValues})}
				>
					<Item 
						name='phaseName' 
						label='Phase Name'
						required
						rules={[{required: true, message: "Please enter phase name"}]}
					>
						<Input
							placeholder='Enter phase name'
							value={phaseName}
							onChange={(e) => setPhaseName(e.target.value)}
						/>
					</Item>
					<Item
						name='hoursLimit' 
						label='Hours limit'
						required
						rules={[{required: true, message: "Please enter hours limit"}]}
					>
						<Input
							placeholder='Enter hours limit'
							value={hoursLimit}
							onChange={(e) => setHoursLimit(e.target.value)}
						/>

					</Item>
					<Item name='description' label='Description'>
						<TextArea
							placeholder="Enter phase description ..."
							value={description}
							onChange={(e) => setDescription(e.target.value)}
							autoSize={{ minRows: 3, maxRows: 5 }}
						/>
					</Item>
					<Item
						name='type' 
						label='Type'
						required
						rules={[{required: true, message: "Please enter type"}]}
					>
						<Select
							placeholder='Enter type'
							value={type}
							onChange={(value) => setType(value)}
							options={notRealOptions}
						/>
					</Item>

					<Item
						name='timeout' 
						label='Timeout'
						required
						rules={[{required: true, message: "Please enter timeout"}]}
					>
						<Input
							placeholder='Enter timeout'
							value={timeout}
							onChange={(e) => setTimeout(e.target.value)}
						/>
					</Item>
					<div className='wrapperButton'>
						<Button htmlType='submit' type='primary'>Submit</Button>
					</div>
				</Form>
			</div>
		</Modal>
  )
}

export default AddPhaseModal;
