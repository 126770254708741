import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Dropdown } from 'antd';
import { DownOutlined, MenuOutlined, RightOutlined, CaretDownOutlined, CaretRightOutlined } from '@ant-design/icons';
import ProfileLink from '../../UI/ProfileLink/ProfileLink';
import HeaderDrawer from './HeaderDrawer';
import { defaultEmployeeNavigation } from '../../../utils/constants';
import logo from '../../../assets/images/logo.png';
import './styles.scss';

const Header = () => {
  const location = useLocation();
  const user = useSelector((state) => state.user.user);
  const settings = useSelector((state) => state.user.settings);
  const [open, setOpen] = useState(false);
  const locationCheck = () => {
    return location.pathname === '/login' || location.pathname === '/forgot-password';
  };
  const items = [];
  const links = settings?.navigation ? settings?.navigation?.active : defaultEmployeeNavigation.active;
  const linksActive = links.items.length <= 6 ? links?.items : links?.items.slice(0,6);
  const linksMore = links.items.length > 6 ? links?.items.slice(6) : [];
  linksMore.length > 0 && linksMore.forEach((link, index) => {
    items.push({
      key: index + 1,
      label: (
        <Link to={`/${link.title}`} className="header__navLink">
          <div className="header__dropdownLink">
            {link.title} <CaretRightOutlined className="header__navLink_arrowRight"/>
          </div>
        </Link>
      ),
    });
  });


  const linksJSX = linksActive?.map((link) => {
    return (
      <NavLink
        key={link.id}
        to={`/${link.title}`}
        className={({ isActive }) => (isActive ? 'header__navLinkActive' : 'header__navLink')}
      >
        {link.title}
      </NavLink>
    );
  });
  return (
    <div className="header" style={locationCheck() ? { display: 'none' } : null}>
      <div className="header__inner">
        <Link to="/">
          <img src={logo} alt="Sibers" className="header__logo" />
        </Link>
        <div className="header__navLinks">
          {linksJSX}
          {linksMore.length > 0 && (
            <Dropdown menu={{ items }} placement="bottomRight" arrow>
              <div className="header__dropdownBtn">
                <span className="header__navLink">More</span>
                <CaretDownOutlined size="large" className="header__navLink_arrow"/>
              </div>
            </Dropdown>
          )}
        </div>
        <div className="header__profile">
          <ProfileLink name={`${user?.firstName} ${user?.lastName}`} photo={user?.photo} />
        </div>
        <div className="header__drawerButton">
          <MenuOutlined onClick={() => setOpen(true)} />
        </div>
      </div>
      <HeaderDrawer
        open={open}
        close={() => setOpen(false)}
        name={`${user?.firstName} ${user?.lastName}`}
        photo={user?.photo}
      />
    </div>
  );
};

export default Header;
