// USER ACTIONS

export const LOGIN_USER_REQUEST = 'LOGIN_USER_REQUEST';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';
export const LOGOUT_USER = 'LOGOUT_USER';
export const GET_CURRENT_USER_REQUEST = 'GET_CURRENT_USER_REQUEST';
export const GET_CURRENT_USER_SUCCESS = 'GET_CURRENT_USER_SUCCESS';
export const GET_CURRENT_USER_FAILURE = 'GET_CURRENT_USER_FAILURE';
export const GET_CURRENT_USER_SETTINGS_REQUEST = 'GET_CURRENT_USER_SETTINGS_REQUEST';
export const GET_CURRENT_USER_SETTINGS_SUCCESS = 'GET_CURRENT_USER_SETTINGS_SUCCESS';
export const GET_CURRENT_USER_SETTINGS_FAILURE = 'GET_CURRENT_USER_SETTINGS_FAILURE';
export const PUT_CURRENT_USER_SETTINGS_REQUEST = 'PUT_CURRENT_USER_SETTINGS_REQUEST';
export const PUT_CURRENT_USER_SETTINGS_SUCCESS = 'PUT_CURRENT_USER_SETTINGS_SUCCESS';
export const PUT_CURRENT_USER_SETTINGS_FAILURE = 'PUT_CURRENT_USER_SETTINGS_FAILURE';

// // USERS ACTIONS

// export const GET_USERS_REQUEST = 'GET_USERS_REQUEST';
// export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
// export const GET_USERS_FAILURE = 'GET_USERS_FAILURE';

// REPORT ACTIONS

export const GET_BONUSES_REPORT_REQUEST = 'GET_BONUSES_REPORT_REQUEST';
export const GET_BONUSES_REPORT_SUCCESS = 'GET_BONUSES_REPORT_SUCCESS';
export const GET_BONUSES_REPORT_FAILURE = 'GET_BONUSES_REPORT_FAILURE';

export const GET_EMPLOYEE_REPORT_REQUEST = 'GET_EMPLOYEE_REPORT_REQUEST';
export const GET_EMPLOYEE_REPORT_SUCCESS = 'GET_EMPLOYEE_REPORT_SUCCESS';
export const GET_EMPLOYEE_REPORT_FAILURE = 'GET_EMPLOYEE_REPORT_FAILURE';

// CALENDAR ACTIONS

export const GET_CALENDAR_REQUEST = 'GET_CALENDAR_REQUEST';
export const GET_CALENDAR_SUCCESS = 'GET_CALENDAR_SUCCESS';
export const GET_CALENDAR_FAILURE = 'GET_CALENDAR_FAILURE';

// TASKS ACTIONS

export const GET_TASKS_REQUEST = 'GET_TASKS_REQUEST';
export const GET_TASKS_SUCCESS = 'GET_TASKS_SUCCESS';
export const GET_TASKS_FAILURE = 'GET_TASKS_FAILURE';

export const GET_TASK_REQUEST = 'GET_TASK_REQUEST';
export const GET_TASK_SUCCESS = 'GET_TASK_SUCCESS';
export const GET_TASK_FAILURE = 'GET_TASK_FAILURE';

export const CREATE_TASKS_REQUEST = 'CREATE_TASKS_REQUEST';
export const CREATE_TASKS_SUCCESS = 'CREATE_TASKS_SUCCESS';
export const CREATE_TASKS_FAILURE = 'CREATE_TASKS_FAILURE';

export const UPDATE_TASKS_REQUEST = 'UPDATE_TASKS_REQUEST';
export const UPDATE_TASKS_SUCCESS = 'UPDATE_TASKS_SUCCESS';
export const UPDATE_TASKS_FAILURE = 'UPDATE_TASKS_FAILURE';

export const DELETE_TASKS_REQUEST = 'DELETE_TASKS_REQUEST';
export const DELETE_TASKS_SUCCESS = 'DELETE_TASKS_SUCCESS';
export const DELETE_TASKS_FAILURE = 'DELETE_TASKS_FAILURE';

// PHASES ACTIONS

export const GET_PHASES_REQUEST = 'GET_PHASES_REQUEST';
export const GET_PHASES_SUCCESS = 'GET_PHASES_SUCCESS';
export const GET_PHASES_FAILURE = 'GET_PHASES_FAILURE';

// NEWS ACTIONS

export const GET_NEWS_REQUEST = 'GET_NEWS_REQUEST';
export const GET_NEWS_SUCCESS = 'GET_NEWS_SUCCESS';
export const GET_NEWS_FAILURE = 'GET_NEWS_FAILURE';

export const GET_SINGLE_NEWS_REQUEST = 'GET_SINGLE_NEWS_REQUEST';
export const GET_SINGLE_NEWS_SUCCESS = 'GET_SINGLE_NEWS_SUCCESS';
export const GET_SINGLE_NEWS_FAILURE = 'GET_SINGLE_NEWS_FAILURE';

// CONTACTS ACTIONS

export const GET_CONTACTS_REQUEST = 'GET_CONTACTS_REQUEST';
export const GET_CONTACTS_SUCCESS = 'GET_CONTACTS_SUCCESS';
export const GET_CONTACTS_FAILURE = 'GET_CONTACTS_FAILURE';

export const GET_TEAMS_REQUEST = 'GET_TEAMS_REQUEST';
export const GET_TEAMS_SUCCESS = 'GET_TEAMS_SUCCESS';
export const GET_TEAMS_FAILURE = 'GET_TEAMS_FAILURE';

export const GET_LOCATIONS_REQUEST = 'GET_LOCATIONS_REQUEST';
export const GET_LOCATIONS_SUCCESS = 'GET_LOCATIONS_SUCCESS';
export const GET_LOCATIONS_FAILURE = 'GET_LOCATIONS_FAILURE';

// PROJECTS ACTIONS

// export const GET_PROJECTS_REQUEST = 'GET_CONTACTS_REQUEST';
// export const GET_PROJECTS_SUCCESS = 'GET_CONTACTS_SUCCESS';
// export const GET_PROJECTS_FAILURE = 'GET_CONTACTS_FAILURE';

export const GET_PROJECTS_REQUEST = 'GET_PROJECTS_REQUEST';
export const GET_PROJECTS_SUCCESS = 'GET_PROJECTS_SUCCESS';
export const GET_PROJECTS_FAILURE = 'GET_PROJECTS_FAILURE';

export const GET_SINGLE_PROJECT_REQUEST = 'GET_SINGLE_PROJECT_REQUEST';
export const GET_SINGLE_PROJECT_SUCCESS = 'GET_SINGLE_PROJECT_SUCCESS';
export const GET_SINGLE_PROJECT_FAILURE = 'GET_SINGLE_PROJECT_FAILURE';

//Infrastructure
export const GET_INFRASTRUCTURES_REQUEST = 'GET_INFRASTRUCTURES_REQUEST';
export const GET_INFRASTRUCTURES_SUCCESS = 'GET_INFRASTRUCTURES_SUCCESS';
export const GET_INFRASTRUCTURES_FAILURE = 'GET_INFRASTRUCTURES_FAILURE';

//

// DAYOFF ACTIONS

export const GET_DAYOFFS_REQUEST = 'GET_DAYOFFS_REQUEST';
export const GET_DAYOFFS_SUCCESS = 'GET_DAYOFFS_SUCCESS';
export const GET_DAYOFFS_FAILURE = 'GET_DAYOFFS_FAILURE';

export const CREATE_DAYOFF_REQUEST = 'CREATE_DAYOFF_REQUEST';
export const CREATE_DAYOFF_SUCCESS = 'CREATE_DAYOFF_SUCCESS';
export const CREATE_DAYOFF_FAILURE = 'CREATE_DAYOFF_FAILURE';

export const UPDATE_DAYOFF_REQUEST = 'UPDATE_DAYOFF_REQUEST';
export const UPDATE_DAYOFF_SUCCESS = 'UPDATE_DAYOFF_SUCCESS';
export const UPDATE_DAYOFF_FAILURE = 'UPDATE_DAYOFF_FAILURE';

export const GET_DAYOFF_REQUEST = 'GET_DAYOFF_REQUEST';
export const GET_DAYOFF_SUCCESS = 'GET_DAYOFF_SUCCESS';
export const GET_DAYOFF_FAILURE = 'GET_DAYOFF_FAILURE';

export const DELETE_DAYOFF_REQUEST = 'DELETE_DAYOFF_REQUEST';
export const DELETE_DAYOFF_SUCCESS = 'DELETE_DAYOFF_SUCCESS';
export const DELETE_DAYOFF_FAILURE = 'DELETE_DAYOFF_FAILURE';

// BONUSES
export const GET_BONUSES_REQUEST = 'GET_BONUSES_REQUEST';
export const GET_BONUSES_SUCCESS = 'GET_BONUSES_SUCCESS';
export const GET_BONUSES_FAILURE = 'GET_BONUSES_FAILURE';

// HOURS

export const GET_HOURS_REPORT_REQUEST = 'GET_HOURS_REPORT_REQUEST';
export const GET_HOURS_REPORT_SUCCESS = 'GET_HOURS_REPORT_SUCCESS';
export const GET_HOURS_REPORT_FAILURE = 'GET_HOURS_REPORT_FAILURE';

export const ADD_HOURS_RECORD_REQUEST = 'ADD_HOURS_RECORD_REQUEST';
export const ADD_HOURS_RECORD_SUCCESS = 'ADD_HOURS_RECORD_SUCCESS';
export const ADD_HOURS_RECORD_FAILURE = 'ADD_HOURS_RECORD_FAILURE';

export const DELETE_HOURS_RECORD_REQUEST = 'DELETE_HOURS_RECORD_REQUEST';
export const DELETE_HOURS_RECORD_SUCCESS = 'DELETE_HOURS_RECORD_SUCCESS';
export const DELETE_HOURS_RECORD_FAILURE = 'DELETE_HOURS_RECORD_FAILURE';

// BIRTHDAYS

export const GET_USERS_BIRTHDAYS_REQUEST = 'GET_USERS_BIRTHDAYS_REQUEST';
export const GET_USERS_BIRTHDAYS_SUCCESS = 'GET_USERS_BIRTHDAYS_SUCCESS';
export const GET_USERS_BIRTHDAYS_FAILURE = 'GET_USERS_BIRTHDAYS_FAILURE';

export const GET_CLIENTS_BIRTHDAYS_REQUEST = 'GET_CLIENTS_BIRTHDAYS_REQUEST';
export const GET_CLIENTS_BIRTHDAYS_SUCCESS = 'GET_CLIENTS_BIRTHDAYS_SUCCESS';
export const GET_CLIENTS_BIRTHDAYS_FAILURE = 'GET_CLIENTS_BIRTHDAYS_FAILURE';
