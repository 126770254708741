import { GET_CALENDAR_REQUEST, GET_CALENDAR_SUCCESS, GET_CALENDAR_FAILURE } from '../actionTypes';

const initialState = {
  calendar: null,
  loading: false,
  getCalendarError: null,
};

const calendarReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CALENDAR_REQUEST:
      return { ...state, loading: true };
    case GET_CALENDAR_SUCCESS:
      return { ...state, loading: false, calendar: action.data, getCalendarError: null };
    case GET_CALENDAR_FAILURE:
      return { ...state, getCalendarError: action.error, loading: false };
    default:
      return { ...state };
  }
};

export default calendarReducer;
