import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
// import { useDispatch, useSelector } from 'react-redux';
import useResizeWindows from '../../../../utils/hooks/useResizeWindows';
import { getDayoff } from '../../../../store/actions/daysoffsActions';
import { Button, Spin } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import EditDayoffModal from './EditDayoffModal';

const EditModalButton = ({ id }) => {
  const dispatch = useDispatch();
  const width = useResizeWindows();
  // const updateDayOffLoading = useSelector((state) => state.dayOff.updateDayOffLoading);
  const [editModalShow, setEditModalShow] = useState(false);
  const click = () => {
    setEditModalShow(true);
    dispatch(getDayoff(id));
  };
  return (
    <>
    {/* <Spin spinning={updateDayOffLoading}> */}
      {width < 620 ? (
        <EditOutlined onClick={() => click()} style={{ color: '#3c9ae8' }} />
      ) : (
        <Button onClick={() => click()}>Edit</Button>
      )}
      <EditDayoffModal id={id} show={editModalShow} close={() => setEditModalShow(false)} />

    {/* </Spin> */}
    </>
  );
};

export default EditModalButton;
