import {
  GET_DAYOFFS_REQUEST,
  GET_DAYOFFS_SUCCESS,
  GET_DAYOFFS_FAILURE,
  GET_DAYOFF_REQUEST,
  GET_DAYOFF_SUCCESS,
  GET_DAYOFF_FAILURE,
  CREATE_DAYOFF_REQUEST,
  CREATE_DAYOFF_SUCCESS,
  CREATE_DAYOFF_FAILURE,
  UPDATE_DAYOFF_REQUEST,
  UPDATE_DAYOFF_SUCCESS,
  UPDATE_DAYOFF_FAILURE,
  DELETE_DAYOFF_SUCCESS,
  DELETE_DAYOFF_FAILURE,
} from '../actionTypes';
import axiosWH from '../../utils/axios';
import { showNotification } from '../../App';

const getDayoffsRequest = (value) => ({ type: GET_DAYOFFS_REQUEST, value });
const getDayoffsSuccess = (data) => ({ type: GET_DAYOFFS_SUCCESS, data });
const getDayoffsFailure = (error) => ({ type: GET_DAYOFFS_FAILURE, error });

const createDayoffsRequest = (value) => ({ type: CREATE_DAYOFF_REQUEST, value });
const createDayoffsSuccess = (data) => ({ type: CREATE_DAYOFF_SUCCESS, data });
const createDayoffsFailure = (error) => ({ type: CREATE_DAYOFF_FAILURE, error });

const getDayoffRequest = (value) => ({ type: GET_DAYOFF_REQUEST, value });
const getDayoffSuccess = (data) => ({ type: GET_DAYOFF_SUCCESS, data });
const getDayofFailure = (error) => ({ type: GET_DAYOFF_FAILURE, error });

const updateDayoffRequest = (value) => ({ type: UPDATE_DAYOFF_REQUEST, value });
const updateDayoffSuccess = (data) => ({ type: UPDATE_DAYOFF_SUCCESS, data });
const updateDayoffFailure = (error) => ({ type: UPDATE_DAYOFF_FAILURE, error });

const deleteDayoffSuccess = (id) => ({type: DELETE_DAYOFF_SUCCESS, id});
const deleteDayoffFailure = (error) => ({type: DELETE_DAYOFF_FAILURE, error});

export const getDayoffs = (start = null, end = null, user = null) => {
  return async (dispatch) => {
    try {
      dispatch(getDayoffsRequest(true));
      const response = await axiosWH.get('/api/v1/dayoffs', {
        params: {
          page: 0,
          // limit: 20,
          limit: 0,
          startDate: start,
          endDate: end,
          user,
        },
      });
      dispatch(getDayoffsSuccess(response.data.data.items));
    } catch (e) {
      showNotification('error',  e.response.data.errors[0] ?? 'Something went wrong!', 'Error');
      dispatch(getDayoffsFailure(e));
    } finally {
      dispatch(getDayoffsRequest(false));
    }
  };
};

export const createDayoff = (dayoffData) => {
  return async (dispatch) => {
    try {
      dispatch(createDayoffsRequest(true));
      const response = await axiosWH.post('api/v1/dayoffs', dayoffData);
      dispatch(createDayoffsSuccess(response.data.data));
      showNotification('success', 'Successfully created Day off');
    } catch (e) {
      showNotification('error',  e.response.data.errors[0] ?? 'Something went wrong!', 'Error');
      dispatch(createDayoffsFailure(e));
    } finally {
      dispatch(createDayoffsRequest(false));
    }
  };
};

export const getDayoff = (id) => {
  return async (dispatch) => {
    try {
      dispatch(getDayoffRequest(true));
      const response = await axiosWH.get(`/api/v1/dayoffs/${id}`);
      dispatch(getDayoffSuccess(response.data.data));
    } catch (error) {
      dispatch(getDayofFailure(error));
    } finally {
      dispatch(getDayoffRequest(false));
    }
  };
};

export const updateDayoff = (id, dayOff) => {
  return async (dispatch) => {
    try {
      dispatch(updateDayoffRequest(true));
      const response = await axiosWH.put(`/api/v1/dayoffs/${id}`, dayOff);
      dispatch(updateDayoffSuccess(response.data.data));
      showNotification('success', 'Successfully updated Day off');
    } catch (e) {
      showNotification('error',  e.response.data.errors[0] ?? 'Something went wrong!', 'Error');
      dispatch(updateDayoffFailure(e));
    }finally {
      dispatch(updateDayoffRequest(false));
    }
  };
};

export const deleteDayoff = (id) => {
  return async (dispatch) => {
    try {
      await axiosWH.delete(`/api/v1/dayoffs/${id}`);
      dispatch(deleteDayoffSuccess(id));
      showNotification('success', 'Successfully deleted Day off');
    } catch (e) {
      showNotification('error',  e.response.data.errors[0] ?? 'Something went wrong!', 'Error');
      dispatch(deleteDayoffFailure(e));
    }
  }
};
