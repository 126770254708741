import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { getCurrentUserSettings } from './store/actions/userActions';
import Login from './pages/Login/Login';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import Header from './components/layout/Header/Header';
import Footer from './components/layout/Footer/Footer';
import Birthdays from './pages/Birthdays/Birthdays';
import Contacts from './pages/Contacts/Contacts';
import Tasks from './pages/Tasks/Tasks';
import Dayoff from './pages/Dayoff/Dayoff';
import Documents from './pages/Documents/Documents';
import Events from './pages/Events/Events';
import Hours from './pages/Hours/Hours';
import Main from './pages/Main/Main';
import News from './pages/News/News';
import Profile from './pages/Profile/Profile';
import Projects from './pages/Projects/Projects';
import Project from './pages/Project/Project';
import Settings from './pages/Settings/Settings';
import NotFound from './pages/NotFound/NotFound';
import LoginRoute from './routes/LoginRoute/LoginRoute';
import Reports from './pages/Reports/Reports';
import FullHoursReport from './pages/FullHoursReport/FullHoursReport';
import BonusReport from './pages/BonusReport/BonusReport';
import Summary from './pages/Project/Tabs/Summary';
import About from './pages/Project/Tabs/About';
import Phases from './pages/Project/Tabs/Phases';
import Infrastructure from './pages/Project/Tabs/Infrastructure';
import Subscriptions from './pages/Project/Tabs/Subscriptions';
import { notification } from 'antd';
import Holidays from './pages/Holidays/Holidays';

export const showNotification = (type, text, title) => {
  notification[type]({
    message: title,
    description: text,
    className: 'notification-border',
  });
};

const App = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);

  /////
  const singleProject = useSelector((state) => state.projects?.singleProject);


  useEffect(() => {
    if (user) {
      dispatch(getCurrentUserSettings());
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (!user && location.pathname !== '/forgot-password') navigate('/login');
  }, [user]);

  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/forgot-password" element={<LoginRoute user={user} children={<ForgotPassword />} />} />
        <Route path="/login" element={<LoginRoute user={user} children={<Login />} />} />
        <Route path="/birthdays" element={<Birthdays />} />
        <Route path="/contacts" element={<Contacts />} />
        <Route path="/dayoff" element={<Dayoff />} />
        <Route path="/documents" element={<Documents />} />
        <Route path="/events" element={<Events />} />
        <Route path="/holidays" element={<Holidays />} />
        <Route path="/hours" element={<Hours />} />
        <Route path="/news" element={<News />} />
        <Route path="/projects" element={<Projects />} />
        {/* <Route path="/projects/:id" exact element={<Project />}> */}
        <Route path="/projects/:id/*" exact element={<Project />}>
          <Route path="summary" element={<Summary data={singleProject} />}/>
          <Route path="about" element={<About data={singleProject} />}/>
          <Route path="phases" element={<Phases data={singleProject} />}/>
          <Route path="infrastructure" element={<Infrastructure data={singleProject} />}/>
          <Route path="subscriptions" element={<Subscriptions data={singleProject} />}/>

        </Route>
        <Route path="/profile" element={<Profile />} />
        <Route path="/report" element={<Reports />} />
        <Route path="/report/bonus" element={<BonusReport />} />
        <Route path="/report/hours" element={<FullHoursReport />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/tasks" element={<Tasks />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      {location.pathname !== '/forgot-password' && location.pathname !== '/login' && <Footer />}
    </>
  );
};

export default App;
