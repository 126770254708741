import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Card } from 'antd';

const NavigationItem = ({ item, index }) => {
  return (
    <Draggable key={item.id} draggableId={item.id} index={index}>
      {(provided) => (
        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
          <Card className="settings__navigation_item" hoverable>
            <p>{item.title}</p>
          </Card>
        </div>
      )}
    </Draggable>
  );
};

export default NavigationItem;
