import React, { useState } from 'react';
import ModalFormWrapper from '../../../components/UI/ModalForm/ModalFormWrapper';
import { Form, DatePicker, Select, Input } from 'antd';


const { Item } = Form;

const CreateHoliday = () => {
  const [date, setDate] = useState(null);
  const [type, setType] = useState(null);
  const [name, setName] = useState(null);

  const typeOptions = [
    {value: 'holiday', label: 'Holiday'},
    {value: 'worked', label: 'Worked'},
  ]

  return (
    <ModalFormWrapper modalTitle="Create new holiday" buttonName="New holiday">
      <Item label='Date' name='date'>
        <DatePicker
          // value={date}
          // onChange
        />
      </Item>
      <Item label='Type' name='type'>
        <Select
          placeholder='Select type' 
          value={type}
          onChange={(value) => setType(value)}
          options={typeOptions}
        />
      </Item>
      <Item label='Name' name='name'>
        <Input 
          placeholder='Enter name' 
          value={name}
          onChange={(e) => setName(e.target.value)}
        /> 
      </Item>

    </ModalFormWrapper>
  )
}

export default CreateHoliday;
