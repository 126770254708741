import React from 'react';
import { Typography } from 'antd';
import './styles.scss';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__wrapper" />
      <Typography className="footer__description">Copyright © 2022 Sibers, Ltd. All rights reserved</Typography>
    </footer>
  );
};

export default Footer;
