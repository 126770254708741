import { Empty } from 'antd';
import './style.scss';


const CustomizedEmpty = (text) => () => (
  <div className='customEmpty'>
    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={false} imageStyle={{marginBlock: '12px'}}/>
    <p>{text}</p>
  </div>
);


export const CustomizedEmptyComponent = ({ text }) => (
  <div className='customizedEmptyComponent'>
    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={false} imageStyle={{marginBlock: '12px'}}/>
    <p>{text}</p>
  </div>
);


export default CustomizedEmpty;