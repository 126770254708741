import React from 'react';
import { calendarDays } from '../../../utils/constants';

const Days = () => {
  const daysJSX = calendarDays.map((day) => (
    <div className="calendar__days_item" key={day}>
     <span>{day}</span>
    </div>
  ));
  return <div className="calendar__days">{daysJSX}</div>;
};

export default Days;
