import React from 'react';

export const HorribleSmile = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
      <defs></defs>
      <g transform="translate(-578 -743)">
        <circle className="a" cx="18" cy="18" r="18" transform="translate(578 743)" style={{ fill: '#f25a5a' }} />
        <g transform="translate(585.062 749.821)">
          <circle
            className="b"
            style={{ fill: 'none', stroke: '#8e2d2d', strokeMiterlimit: 10, strokeWidth: '2px' }}
            cx="11"
            cy="11"
            r="11"
            transform="translate(-0.062 0.179)"
          />
          <g transform="translate(5.99 7.15)">
            <line
              className="c"
              style={{
                strokeLinecap: 'round',
                fill: 'none',
                stroke: '#8e2d2d',
                strokeMiterlimit: 10,
                strokeWidth: '2px',
              }}
              y2="3.032"
              transform="translate(0 0)"
            />
            <line
              className="c"
              style={{
                strokeLinecap: 'round',
                fill: 'none',
                stroke: '#8e2d2d',
                strokeMiterlimit: 10,
                strokeWidth: '2px',
              }}
              y2="3.032"
              transform="translate(9.989 0)"
            />
          </g>
          <path
            className="c"
            style={{
              strokeLinecap: 'round',
              fill: 'none',
              stroke: '#8e2d2d',
              strokeMiterlimit: 10,
              strokeWidth: '2px',
            }}
            d="M-118.657-341.608c0-1.458-1.453-2.641-3.245-2.641s-3.245,1.182-3.245,2.641"
            transform="translate(132.885 358.202)"
          />
        </g>
      </g>
    </svg>
  );
};
