import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getTeams, getContacts } from '../../store/actions/contactsActions';
import { getDatePickerValue, getSortedAlphabet, 
  sourcesOptions, 
  contractsOptions, 
  statusesOptions,
  estimatedOptions,
  workedHoursOptions,
  getFilterOption,
  getFilterSort,
  getDateIsNow,
} from '../../utils/helpers';
import { Checkbox, Collapse, DatePicker, Select } from 'antd';
import dayjs from 'dayjs';


const { Panel } = Collapse;


const SelectInput = ({ name, searchValue, onChangeSelect, options }) => {

  return (
    <div className={`filter ${searchValue === null ? 'isNotSelected' : 'isSelected'}`}>
      <p>{name}</p>
      <Select
        showSearch
        style={{ width: '20%' }}
  
        placeholder={`Search to ${name}`}
        optionFilterProp="children"
        filterOption={getFilterOption}
        filterSort={getFilterSort}
        value={searchValue}
        onChange={(value) => onChangeSelect(value)}
        options={options}
      />
    </div>
  )
}

const AdvancedProjectFilter = ({ 
  isReset, setIsReset,
  searchSource, setSearchSource,
  searchContract, setSearchContract,
  searchStatus, setSearchStatus,

  searchStartDate, setSearchStartDate,
  searchEstimate, setSearchEstimate,
  searchWorkedHours, setSearchWorkedHours,
  searchIsInternal, setIsInternal,
}) => {

  const dateFormat = 'YYYY-MM-DD';
  const dispatch = useDispatch();
  const teams = useSelector((state) => state.contact.teams);
  const contacts = useSelector((state) => state.contact?.contacts?.data?.items);
  const [amountFilters, setAmountFilters] = useState(0);
  const [searchTeams, setSearchTeams] = useState(null);
  const [searchPM, setSearchPM] = useState(null);
  const [searchCC, setSearchCC] = useState(null);
  const [searchDevelopers, setSearchDevelopers] = useState(null);
  const [searchSales, setSearchSales] = useState(null);


  // console.log('Advanced | initialProjects: ', initialProjects)

  let arrayAllAdvancedFilters = [
    searchSource, searchContract, searchStatus, searchTeams,
     searchWorkedHours,
    searchEstimate, searchPM, searchCC, searchDevelopers, searchSales, 
    searchStartDate, searchIsInternal,
  ];

  const getAmountAdvancedFilters = (arrayFilters) => {
    let countFilters = 0;
    arrayFilters.forEach((filterItem) => {
      if(!!Date.parse(`${filterItem}`)) {
        let isNow = getDateIsNow(filterItem)
        if (!isNow) countFilters++;
      } else {
        if (filterItem !== null && filterItem !== undefined && filterItem !== false) countFilters++;
      }
      // console.log('filterItem: ', filterItem, ', countFilters: ', countFilters )

    })
    return countFilters;
  };


  useEffect(() => {
    if (isReset === true) {
      resetAdvancedFilters();
      setIsReset(false)
    } //изменение isReset если изменился один из фильтров ???
  }, [isReset]);

  useEffect(() => {
    dispatch(getContacts());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getTeams());
  }, [dispatch]);

  useEffect(() => {
    let amountAdvancedFilters = getAmountAdvancedFilters(arrayAllAdvancedFilters);
    if (amountFilters !== amountAdvancedFilters && (amountAdvancedFilters !== null)) setAmountFilters(amountAdvancedFilters);
  }, [...arrayAllAdvancedFilters]);



  const teamsOptions = [
    { value: null, label: 'All teams' },
    ...(getSortedAlphabet(teams?.data.items, 'name') || []).map(({ id, name }) => ({ value: id, label: name })),
  ];

  const projectManagersList = contacts?.filter(((contact) => contact?.team?.name === 'PM'))
  const customerCareList = contacts?.filter(((contact) => contact?.team?.name === 'CC'))
  const developersList = contacts?.filter((contact) => {
    let isNotDeveloper = ['CC', 'FD', 'Hr', 'PM', 'Pr', 'Sales'].reduce((sum, currentPerson) => {
      let isNotDev = contact?.team?.name === currentPerson ? true : false
      return sum + isNotDev;
    }, false)
    // return contact?.team?.name !== 'CC'
    return !isNotDeveloper
  });
  const salesList = contacts?.filter(((contact) => contact?.team?.name === 'Sales'))


  const getPeopleOptions = (typePeople, peopleList) => {
    return [
      { value: null, label: `All ${typePeople}s` }, 
      ...(getSortedAlphabet(peopleList, 'name') || []).map((person) => {
        let personFirstName = person?.firstName ? person?.firstName : '';
        let personLastName = person?.lastName ? person?.lastName : '';
        return {value: person.id, label: `${personFirstName} ${personLastName}`}
      })
    ]
  }

  const projectManagersOptions = getPeopleOptions('PM', projectManagersList);
  const customerCareOptions = getPeopleOptions('CC', customerCareList);
  const developersOptions = getPeopleOptions('Developer', developersList);
  const salesOptions = getPeopleOptions('Sale', salesList);

  const resetAdvancedFilters = () => {
    setSearchTeams(null);
    setSearchPM(null);
    setSearchCC(null);
    setSearchDevelopers(null);
    setSearchSales(null);

    //доделать reset для остальных фильтров
  }

  const getPanelHeader = (amount) => {
    return (
    <div className='headerPanel'>
      <div>Advanced filters</div>
      {amount ? <div className='amountFilters'>{amount}</div> : <></>}
    </div>)
  }


  return (
    <div className="projects__advancedFilter">
      <Collapse>
        <Panel header={getPanelHeader(amountFilters)} key="1">

          <div className='projects__advancedFilter_wrapper'>
            <div className={`filter ${searchSource === null ? 'isNotSelected' : 'isSelected'}`}>
              <p>Source</p>
              <Select
                style={{ width: '20%' }}
                value={searchSource}
                onChange={(value) => setSearchSource(value)}
                options={sourcesOptions}
              />
            </div>
            <div className={`filter ${searchContract === null ? 'isNotSelected' : 'isSelected'}`}>
              <p>Contract</p>
              <Select
                style={{ width: '20%' }}
                value={searchContract}
                onChange={(value) => setSearchContract(value)}
                options={contractsOptions}
              />
            </div>
            <div className={`filter ${searchStatus === null ? 'isNotSelected' : 'isSelected'}`}>
              <p>Status</p>
              <Select
                style={{ width: '20%' }}
                value={searchStatus}
                onChange={(value) => setSearchStatus(value)}
                options={statusesOptions}
              />
            </div>
            <div className={`filter ${searchTeams === null ? 'isNotSelected' : 'isSelected'}`}>
              <p>Teams</p>
              <Select
                disabled
                style={{ width: '20%' }}
                value={searchTeams}
                mode="multiple"
                placeholder="Please select"
                onChange={(value) => setSearchTeams(value)}
                options={teamsOptions}
              />
            </div>


            <SelectInput 
              name='PM' 
              searchValue={searchPM} 
              onChangeSelect={setSearchPM} 
              options={projectManagersOptions}
            />
            <SelectInput 
              name='CC' 
              searchValue={searchCC} 
              onChangeSelect={setSearchCC} 
              options={customerCareOptions}
            />
            <SelectInput 
              name='Developer' 
              searchValue={searchDevelopers} 
              onChangeSelect={setSearchDevelopers} 
              options={developersOptions}
            />
            <SelectInput 
              name='Sales' 
              searchValue={searchSales} 
              onChangeSelect={setSearchSales} 
              options={salesOptions}
            />


            <div className={`filter ${searchStartDate === null || searchStartDate === undefined || new Date(searchStartDate)?.toLocaleDateString('en-CA') === new Date()?.toLocaleDateString('en-CA') ? 'isNotSelected' : 'isSelected'}`}>
              <p>Created at</p>
              <DatePicker
                style={{ width: '20%' }}
                defaultValue={dayjs(getDatePickerValue(searchStartDate), dateFormat)}
                format={dateFormat}
                onChange={(e) => setSearchStartDate(getDatePickerValue(e))}
              /> 
            </div>
            <div className={`filter ${searchWorkedHours === null ? 'isNotSelected' : 'isSelected'}`}>
              <p>Worked hours</p>
              <Select
                disabled
                style={{ width: '20%' }}
                value={searchWorkedHours}
                mode="multiple"
                placeholder="Please select"
                onChange={(value) => setSearchWorkedHours(value)}
                options={workedHoursOptions}
              />

            </div>
            <div className={`filter ${searchEstimate === null ? 'isNotSelected' : 'isSelected'}`}>
              <p>Estimated</p>
              <Select
                disabled
                style={{ width: '20%' }}
                value={searchEstimate}
                mode="multiple"
                placeholder="Please select"
                onChange={(value) => setSearchEstimate(value)}
                options={estimatedOptions}
              />

            </div>
            <div className='filter'>
              <Checkbox
                onChange={(e) => setIsInternal(e.target.checked)}
                // defaultChecked={false}
                checked={!!searchIsInternal}
              >
                Is internal
              </Checkbox>
            </div>
          </div>
        </Panel>
      </Collapse>
    </div>
  );
};

export default AdvancedProjectFilter;
