import {
  GET_PROJECTS_REQUEST,
  GET_PROJECTS_SUCCESS,
  GET_PROJECTS_FAILURE,
  GET_SINGLE_PROJECT_REQUEST,
  GET_SINGLE_PROJECT_SUCCESS,
  GET_SINGLE_PROJECT_FAILURE,
  GET_INFRASTRUCTURES_REQUEST,
  GET_INFRASTRUCTURES_SUCCESS,
  GET_INFRASTRUCTURES_FAILURE,
} from '../actionTypes';
import axiosWH from '../../utils/axios';

const getProjectsRequest = () => ({ type: GET_PROJECTS_REQUEST });
const getProjectsSuccess = (data) => ({ type: GET_PROJECTS_SUCCESS, data });
const getProjectsFailure = (error) => ({ type: GET_PROJECTS_FAILURE, error });

const getSingleProjectRequest = () => ({ type: GET_SINGLE_PROJECT_REQUEST });
const getSingleProjectSuccess = (data) => ({ type: GET_SINGLE_PROJECT_SUCCESS, data });
const getSingleProjectFailure = (error) => ({ type: GET_SINGLE_PROJECT_FAILURE, error });

const getInfrastructuresRequest = () => ({ type: GET_INFRASTRUCTURES_REQUEST });
const getInfrastructuresSuccess = (data) => ({ type: GET_INFRASTRUCTURES_SUCCESS, data });
const getInfrastructuresFailure = (error) => ({ type: GET_INFRASTRUCTURES_FAILURE, error });

export const getProjects = (
  userId = null, 
  name = '', 
  client = '', 
  source = null, 
  contractType = null, 
  status = null, 
  startDate = null, 
  estimatedHours = null,
  workedHours = null,
  isInternal = 0, 
  teams = null, 
  page = 1,
  limit = 0,
  ) => {
// export const getProjects = ({userId = null, name = '', client = '', source = null, contractType = null, status = null, startDate = null, teams = null, }) => {

  return async (dispatch) => {
    try {
      dispatch(getProjectsRequest());
      const response = await axiosWH.get('/api/v1/projects', {
        params: {
          employee: userId,
          // limit: 50,
          // limit: 0,
          source,
          contractType,
          status,
          createdAt: startDate,
          estimatedHours,
          workedHours,
          isInternal,
          teams,
          name,
          client,
          page,
          limit,
        },
      });

      dispatch(getProjectsSuccess(response.data));
    } catch (error) {
      dispatch(getProjectsFailure(error));
    }
  };
};

export const getSingleProject = (id) => {
  return async (dispatch) => {
    try {
      dispatch(getSingleProjectRequest());
      const response = await axiosWH.get(`/api/v1/projects/${id}`);
      dispatch(getSingleProjectSuccess(response.data.data));
    } catch (error) {
      dispatch(getSingleProjectFailure(error));
    }
  };
};

export const getInfrastructures = (id) => {
  return async (dispatch) => {
    try {
      dispatch(getInfrastructuresRequest());
      const response = await axiosWH.get(`/api/v1/projects/${id}/infrastructures`);
      //https://wh-api.devx.sibers.com/api/v1/projects/19/infrastructures
      dispatch(getInfrastructuresSuccess(response.data.data));
    } catch (error) {
      dispatch(getInfrastructuresFailure(error))
    }
  }
};
