import React from 'react';
import { Typography, DatePicker } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { months } from "../../utils/constants";
import { titleLevel, dateHelper } from "../../utils/helpers";

const { Title } = Typography;

const MonthSelector = ({ value, onChange }) => {

  const changeDateHandler = (date, dateString) => {
    let currentMonth = new Date(date)?.getMonth() + 1;
    onChange(currentMonth);
  };
  const incrementMonth = () => {
    if (value < 12) onChange(value + 1);
    if (value === 12) onChange(1);
  };
  const decrementMonth = () => {
    if (value > 1) onChange(value - 1);
    if (value === 1) onChange(12);
  };
  
  return (
    <div className="birthdays__monthSelector">
      <LeftOutlined className="birthdays__monthSelector_icon" onClick={() => decrementMonth()} />
      <div className="birthdays__monthSelector_inner">
        <Title level={titleLevel()} className="birthdays__monthSelector_title">
          { months[value - 1] }
          <DatePicker
            onChange={changeDateHandler}
            picker="month"
            nextIcon={<RightOutlined className="main__month_arrow" width="18" height="18"/>}
            allowClear={false}
            inputReadOnly
            className="main__month_datePicker"
          />
        </Title>

      </div>
      <RightOutlined className="birthdays__monthSelector_icon" onClick={() => incrementMonth()} />
    </div>
  );
};

export default MonthSelector;
