import React, { useState } from 'react';
import { Collapse } from "antd";
import TeamList from '../TeamList/TeamList';
import './style.scss';


const { Panel } = Collapse;

const WorkedInPast = ({users}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [userId, setUserId] = useState(null);

  let usersAmount = users?.length ? users?.length : 0;

  return (
    <div className='workedInPast'>
      <Collapse accordion>
        <Panel header={<b>Worked in past ({usersAmount})</b>} key="1">
          <TeamList
            // allUsers={data?.userProjectPhases} 
            allUsers={users}
            setUserId={setUserId} 
            setIsModalVisible={setIsModalVisible}
            userId={userId}
            isModalVisible={isModalVisible}
          />
        </Panel>
      </Collapse>
    </div>
  )
}

export default WorkedInPast;
