import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Input from 'antd/es/input/Input';
import './style.scss';

const TextEditor = ({ value, onChange }) => { //{stateValue, setStateValue}

	const modules={
		toolbar: [
			// [{ header: [1, 2, 3, 4, 5, 6, false] }],
			// [{ font: [] }],
			[{ size: []}],
			['bold', 'italic', 'underline', 'strike', 'blockquote'],
			[
				{ list: 'ordered'},
				{ list: 'bullet'},
				{ 'align': [] }
				// { indent:'-1'},
				// { indent:'+1'},
			],
			// [{ 'align': [] }],
			['link', 'image', 'code-block'],
			[{ 'color': [] }, { 'background': [] }],
			]
		}
	// {/* <span dangerouslySetInnerHTML={{ __html: this.state.value }}></span> */}

  return (
		<>
			<Input value={value} onChange={onChange} style={{display: 'none'}}/>
			<ReactQuill
				theme="snow" 
				value={value} 
				onChange={(currentValue) => {
					onChange(currentValue);
					// console.log('editorValue: ', currentValue)
					// console.log('typeof value', typeof(currentValue))
					}}
				modules={modules}
			/>

		</>
	);
}

export default TextEditor;