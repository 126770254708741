import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getCalendar } from '../../store/actions/calendarActions';
import { getHoursReport, addHoursRecord, deleteHoursRecord } from '../../store/actions/hoursActions';
import { getPhases } from '../../store/actions/phasesActions';
import { getTasks } from '../../store/actions/tasksActions';
import { Typography, Button, Row, Divider, Spin, DatePicker, Col, message, } from 'antd';
import { PlusOutlined, SaveOutlined, WarningOutlined } from "@ant-design/icons";
import Container from '../../components/layout/Container/Container';
import HoursItem from './HoursItem';
import ClosedHoursItem from './ClosedHoursItem';
import { nanoid } from 'nanoid';
import { hourTemplate, days, amountMoreMessage, 
  taskMessage, amountLessMessage, totalAmountMessage  } from '../../utils/constants';
import {
  hourRefactor,
  dateHelper,
  addAction,
  errorRefactor,
  notificationMessage,
  notificationSuccessMessage,
  reduceHours,
  titleLevel,
  // getUniqueArray
} from '../../utils/helpers';
import './styles.scss';


const { Title } = Typography;

const Hours = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedDate = searchParams.get('date');
  const [messageApi, contextHolder] = message.useMessage();
  const user = useSelector((state) => state.user.user);
  const phases = useSelector((state) => state.phases.phases);
  const tasks = useSelector((state) => state.tasks.tasks);
  const hours = useSelector((state) => state.hours.hours);
  const calendar = useSelector((state) => state.calendar.calendar);
  const phasesLoading = useSelector((state) => state.phases.loading);
  const tasksLoading = useSelector((state) => state.tasks.loading);
  const hoursLoading = useSelector((state) => state.hours.hoursLoading);
  const [rows, setRows] = useState([]);
  const [viewRows, setViewRows] = useState([]);
  const [deleteHours, setDeleteHours] = useState([]);
  const [errors, setErrors] = useState([]);
  const [error, setError] = useState(null);
  const [errorsAddHours, setErrorsAddHours] = useState([]);



  useEffect(() => {
    dispatch(getCalendar(selectedDate ? selectedDate.replaceAll('-', '/') : dateHelper(new Date(), '/')));
    dispatch(getHoursReport(selectedDate ?? dateHelper(new Date())));
  }, [selectedDate, dispatch]);

  useEffect(() => {
    dispatch(getPhases());
    dispatch(getTasks());
  }, [dispatch]);

  useEffect(() => {
    if (hours && hours.length > 0 && tasks) {
      const hoursArray = [];
      hours.forEach((hour) => {
        hoursArray.push(hourRefactor(hour));
      });
      setRows(hoursArray);
      setViewRows(hoursArray);
    } else {
        let newRows = [
            { ...hourTemplate, id: `new-record-${nanoid()}`, user: user.id, date: selectedDate ?? dateHelper(new Date()) },
          ]
      setRows(newRows);
      setViewRows(newRows)
    }
  }, [hours, tasks, user]);

  useEffect(() => {
    if (calendar?.locked) {
      messageApi.error("This date is locked for reporting hours.");
    }
  }, [calendar, calendar?.locked]);

  let openPhases = phases.map(phase => phase.name);


  const getStatusesPhaseInHours = () => {// (savingHours, openPhases) 
    //savingHours - saving on frontend HoursItems
    //all information (from saving hours - no backend) about 
    //tasks with phase and project + new field: Phase STATUS (closed = true/false))

    if (hours !== null) {
      let statusPhaseInHours_0 = hours.map(itemHours => {
        let res =  openPhases.find((openPhase) => itemHours.phase.name === openPhase)
        return {...itemHours, ['closed']: !res }
      })

      let uniqueStatuses = new Set(statusPhaseInHours_0);
      return [...uniqueStatuses]
    }
  }
  let statusesPhaseInHours = getStatusesPhaseInHours();

  let getClosedHours = () => {
    let statusClosedHours = statusesPhaseInHours?.filter((item) => (item.closed === true));

    let result = hours?.map((hoursItem) => {
      let res = statusClosedHours?.filter((statusItem) => statusItem?.id === hoursItem?.id)
      return res;
    })
    return result ? [].concat(...result) : result;
  } 

  let closedHours = getClosedHours();


  const changeDateHandler = (newDate) => {
    setSearchParams(`date=${dateHelper(newDate)}`);
  };

  const hoursSaveHandler = async () => {
    let rowsWithoutEmpty = rows.filter((row) => !!row.phase || !!row.task || !!row.amount);
    let getOpenCards = () => {
      let res = phases.map((phase) => rowsWithoutEmpty.filter((row) => row.phase === phase.id));
      return res ? [].concat(...res) : res;
    }

    let newOpenCards = getOpenCards();


    if (deleteHours.length > 0) {
      for (const hourId of deleteHours) {
        if (!hourId.toString().includes('new-record')) {//для несохраненных HoursItems, чтобы не было отправки на сервер
          const response = await dispatch(deleteHoursRecord(hourId));
          // console.log('DELETE | response: ', response)

          if (response.errors?.errors) {
            // console.log('DELETE | response.errors?.errors: ', response.errors?.errors)
            notificationMessage(errorRefactor(response.errors?.errors[0]));
            return;
          } else {
            notificationSuccessMessage('Hours removed successfully')
            //на каждый удаленный hours будет нотификация
          }
        } else {
          notificationSuccessMessage('Hours removed successfully')
          //на все несохраненные hours одно сообщение разом
        }
      }
      setDeleteHours([]);
      if (rows.length === 1 && !rows[0].amount && !rows[0].task && !rows[0].phase) { //rows or rowsWithoutEmpty?
        navigate('/');
      }
    }

    if ((rows.length === 1 && rows[0].phase) || rows.length > 1) {
      const getValidationErrors = () => {
        let newErrors = [];
        let totalHours = newOpenCards.reduce((sum, card) => sum + +card?.amount, 0);
  
        newOpenCards.forEach((card) => { //amountMoreMessage, taskMessage, amountLessMessage 
          if (card.task === null) newErrors.push({['cardId']: card.id, ['error']: taskMessage})
          if (card.amount === null || +card.amount < 0.5) newErrors.push({['cardId']: card.id, ['error']: amountMoreMessage})
          if (+card.amount > 24 ) newErrors.push({['cardId']: card.id, ['error']: amountLessMessage });
          if (+totalHours > 24) newErrors.push({['cardId']: card.id, ['error']: totalAmountMessage});
        });

        return newErrors;
      }
      let validationErrors = getValidationErrors();
      let isRightData = validationErrors.length === 0 ? true : false;
      setErrorsAddHours(validationErrors);

      if (isRightData) {
        // console.log('newOpenCards: ', newOpenCards)
        const response = await dispatch(addHoursRecord(addAction(newOpenCards)));
        // console.log('ADD | response: ', response)
  
        if (response.data.length > 0) {
          navigate('/');
        }
        // if (response.errors?.errors) {  
        if (response.errors) {
          for (let item in response.errors) {
            // console.log('response.errors[item]: ', response.errors[item])
            setError({
            //   id: rows[response.errors.action].id,
              id: rowsWithoutEmpty[response.errors[item]?.action]?.id,
              message: response.errors[item]?.errors,
            });

          }


          // setError({
          //   //   id: rows[response.errors.action].id,
          //     id: rowsWithoutEmpty[response.errors.action].id,
          //     message: response.errors?.errors,
          //   });
          // console.log('ADD | response.errors?.errors: ', response.errors?.errors)
          // console.log('ADD | response.errors: ', response.errors)

          // response.errors?.errors.forEach((item) => {
          //   console.log('item: ', item)
          //   notificationMessage(errorRefactor(item));
          // });

          for (let item in response.errors) {
            // console.log(item, ', response.errors[item]?.errors:', response.errors[item]?.errors)
            response.errors[item]?.errors?.forEach((elem) => {
              notificationMessage(errorRefactor(elem));
            })
          }
          // response.errors?.errors.forEach((item) => {
          //   console.log('item: ', item)
          //   notificationMessage(errorRefactor(item));
          // });
        } else { //разобраться с successfully если нет подтверждения из response
          if (rowsWithoutEmpty?.length !== 0) notificationSuccessMessage('Hours saved successfully');
          else notificationMessage('Has empty Hours Cards');
        }

      } else {
        let errorsIds = validationErrors.map(item => item.cardId);
        let setErrorsIds = new Set(errorsIds);
        let uniqueErrorsIds = [...setErrorsIds];

        uniqueErrorsIds.forEach(elem => {
          let errorsForOneCard = validationErrors.filter((item) => item.cardId === elem);
          let arrErrors = errorsForOneCard.map(item => item.error);
          let errorsMessage = arrErrors.join(' ');

          notificationMessage(`${errorsMessage}`)
        })
      }

    }

    let newRows = rowsWithoutEmpty?.length !== 0 ? rowsWithoutEmpty  
        : [ { ...hourTemplate, id: `new-record-${nanoid()}`, user: user.id, date: selectedDate ?? dateHelper(new Date()) }, ]
    setRows([...newRows]);
    setViewRows([...newRows]);
    // setViewRows(rows);
  };

  const addHour = () => {
    let newItem = {
        ...hourTemplate,
        id: `new-record-${nanoid()}`,
        user: user.id,
        date: selectedDate ?? dateHelper(new Date()),
      }

    const add = [...rows];
    add.push(newItem);
    setRows(add);

    const viewAdd = [...viewRows];
    viewAdd.push(newItem);
    setViewRows(viewAdd);
  };

  const removeHour = async (id) => {
    // if (!id.toString().includes('new-record')) {
      const deleteHoursCopy = [...deleteHours];
      deleteHoursCopy.push(id);
      setDeleteHours(deleteHoursCopy);
    // }

    if (rows.length > 1) {
      setRows([...rows].filter((row) => row.id !== id));
    } else {
      const rowsCopy = [...rows];
      const rowIndex = rowsCopy.findIndex((item) => item.id === id);
      rowsCopy[rowIndex] = { ...hourTemplate, id: `new-record-${nanoid()}`, user: user.id };
      setRows(rowsCopy);
    }

  };

  const handleChange = (value, name, id) => {//(value, 'task', row.id)

    // if (value || name === 'comment' || name === 'amount' ) {
    if (value || name !== 'phase') {        
      const rowsCopy = [...rows];
      const rowIndex = rowsCopy.findIndex((item) => item.id === id);
      rowsCopy[rowIndex][name] = value;
      setRows(rowsCopy);
      // if (rowIndex === -1) console.log("У Выбранной Фазы и ItemHours НЕ СОВПАЛИ IDШНИКИ!!!!!!!!!!!")

      const viewRowsCopy = [...viewRows];
      const viewRowIndex = viewRowsCopy.findIndex((item) => item.id === id);
      viewRowsCopy[viewRowIndex][name] = value;
      setViewRows(viewRowsCopy);
  
      const errorsCopy = [...errors];
      setErrors(errorsCopy.filter((err) => err.id !== id && err.name !== name));
      setError(null);
    }
  };

  const cancelDeleteHour = (id) => {
    let newDeleteHours = deleteHours.filter((deleteHour) => deleteHour !== id); // remove from deleteHours
    let undeleteHoursItem = viewRows.filter((viewRow) => viewRow.id === id)[0];
    let newRows = [...rows, undeleteHoursItem]; //add undelete HoursItem to rows (to all HoursItems)
    setDeleteHours(newDeleteHours) 
    setRows(newRows); 
  }

//   const hoursItemsJSX = rows.map((item) => {
  const hoursItemsJSX = viewRows.map((item) => {
    let itemCLosedPhase = closedHours?.filter((closedHoursItem) => closedHoursItem?.id === item?.id);
    let curCardErrors = errorsAddHours.filter((elem) => elem.cardId === item.id);
    let currentCardErrors = {['cardId']: null, ['amount']: null, ['task']: null, ['totalAmount']: null};

    curCardErrors.forEach((elem) => {//amountMoreMessage, taskMessage, amountLessMessage 
      currentCardErrors.cardId = elem.cardId;
      if (elem.error === amountMoreMessage) currentCardErrors.amount = currentCardErrors?.amount ? [...currentCardErrors?.amount, amountMoreMessage] : [amountMoreMessage];
      if (elem.error === amountLessMessage) currentCardErrors.amount = currentCardErrors?.amount ? [...currentCardErrors?.amount, amountLessMessage] : [amountLessMessage];
      if (elem.error === taskMessage) currentCardErrors.task = taskMessage;

      if (elem.error === totalAmountMessage) currentCardErrors.totalAmount = totalAmountMessage;
    })  

    const getItemLockedCalendar = () => {
      let res = hours?.filter((itemHours) => itemHours?.id === item?.id);
      return res && res?.length !== 0 ? res[0] : res;  
    }
    let itemLockedCalendar = getItemLockedCalendar();

    let isClosed = !itemCLosedPhase ? undefined : itemCLosedPhase?.length === 0 ? false : itemCLosedPhase[0]?.closed 
    let isLocked = calendar?.locked;

    return (
      <>
      {!isClosed && !isLocked &&<HoursItem
        key={item.id}
        remove={() => removeHour(item.id)}
        phases={phases}
        tasks={tasks}
        row={item}
        error={error}
        frontErrors={currentCardErrors}
        onChange={handleChange}
        deleteHours={deleteHours}
        cancelDeleteHour={() => cancelDeleteHour(item.id)}
        rows={rows}
        selectedDate={selectedDate}
      />}
      {(isClosed || isLocked) && <ClosedHoursItem isClosed={isClosed} currentItem={isLocked ? itemLockedCalendar : itemCLosedPhase[0]}/>}
      </>
    );
  });

  return (
    <Spin spinning={phasesLoading || tasksLoading || hoursLoading} size="large">
      {contextHolder}
      <Container>
        <Row gutter={16}>
          <Col xs={24} sm={24} md={24} lg={16}>
            <Title level={titleLevel()} className="hours__header_title">
              {calendar?.locked && <WarningOutlined style={{color: 'tomato', marginRight: '10px'}}/>}
              <span>Hours / </span>
              <span className="hours__header_date">
                {dateHelper(selectedDate ?? new Date())} {days[parseInt(calendar?.weekday) - 1]}
              </span>
            </Title>
          </Col>
          <Col xs={24} sm={24} md={24} lg={8}>
            <DatePicker
              onChange={changeDateHandler}
              className="hours__header_datePicker"
              allowClear={false}
              format={'YYYY-MM-DD'}
            />
          </Col>
        </Row>
        <Divider />
        <Row gutter={16}>{hoursItemsJSX}</Row>
        <Divider />
        <div className="hours__buttons">
          <Button
            onClick={() => addHour()}
            type="primary"
            icon={<PlusOutlined />}
            disabled={rows.length >= 17 || calendar?.locked}
          >
            Add hours
          </Button>
          <Button type="primary" icon={<SaveOutlined />} onClick={() => hoursSaveHandler()} disabled={calendar?.locked}>
            Save (Total: {reduceHours(rows)})
          </Button>
        </div>
      </Container>
    </Spin>
  );
};

export default Hours;
