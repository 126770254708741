import React, { useState } from 'react';
import { Modal, Form, Input, DatePicker, Button } from 'antd';



const { TextArea } = Input;
const { Item } = Form;

const CreateEventModal = ({show, closeModal}) => {
  //Сделать UpdateEventModal !!!

  const [title, setTitle] = useState('');
  const [date, setDate] = useState(null);
  const [description, setDescription] = useState('');
	const [form] = Form.useForm();


  return (
    <Modal
      title="Create new event"
      centered
      open={show}
      onOk={() => {
        closeModal();
      }}
      onCancel={() => closeModal()}
      footer={[]}
    >
    <div className='event'>
      <Form
        form={form}
        onFinish={(e) => {
          console.log(e);
          form.resetFields();
        }}
        onFinishFailed={(failedValues) => console.log({failedValues})}
      >
        <Item 
          name='title' 
          label='Title'
          required
          rules={[{required: true, message: "Please enter event title"}]}
          >
          <Input
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </Item>
        <Item 
          name='date' 
          label='Date'
          required
          rules={[{required: true, message: "Please enter event date"}]}
          >
          <DatePicker 
            showTime={{ format: 'HH:mm' }}
            // onChange={onChange} 
            // onOk={onOk} 

            />
        </Item>
        <Item 
          name='description' 
          label='Description'
          required
          rules={[{required: true, message: "Please enter event title"}]}
          >
          <TextArea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Enter event description ..."
            autoSize={{ minRows: 3, maxRows: 5 }}
          />
        </Item>
        <div className='wrapperButton'>
          <Button htmlType='submit' type='primary'>Submit</Button>
        </div>

      </Form>

    </div>

    </Modal>
  )
}

export default CreateEventModal;
