import React, { useState } from 'react';
import { Modal, Select, Input, Checkbox, Form, Button } from "antd";
import TextEditor from '../../../../components/UI/TextEditor/TextEditor';
import { contractsOptions } from '../../../../utils/helpers';



const { Item } = Form;

const InfrastructureModal = ({ show, closeModal }) => {
  const [stateIsSecure, setStateIsSecure] = useState(null);
  const [type, setType] = useState(null);
  const [name, setName] = useState(null);
  const [url, setUrl] = useState(null);
  const [comment, setComment] = useState(null);
	const [form] = Form.useForm();

  return(
    <Modal 
      open={show} 
      onOk={() => closeModal()}
			onCancel={() => closeModal()}
      title="Add Infrastructure" 
      // okText="Save" 
      // onOk={createDayoffHandler}
      footer={[]}
    >
      <div className='project'>
        <Form
          form={form}
          onFinish={(e) => {
            console.log(e);
            form.resetFields();
            closeModal();
          }}
          onFinishFailed={(failedValues) => {
            console.log({failedValues});
            // closeModal();
          }}
        >
          <Item name='isSecure' label='' valuePropName="checked">
            <Checkbox
              checked={!!stateIsSecure}
              onChange={(e) => setStateIsSecure(!e.target.checked)}
            >Is secure</Checkbox>
          </Item>
          <Item 
            name='type' 
            label='Type'
            required 
            rules={[{required: true, message: "Please select type"}]}
          >
            <Select
              // это не тип контрактов, будь внимательнее
              placeholder='Select type'
              value={type}
              style={{ width: 120 }}
              onChange={(value) => setType(value)}
              options={contractsOptions}
            />
          </Item>
          <Item name='name' label='Name'>
            <Input 
              placeholder="Enter name" 
              value={name}
              onChange={(e) => setName(e.target.value)}
              />
          </Item>
          <Item 
            name='url' 
            label='URL' 
            required 
            rules={[{required: true, message: "Please enter URL"}]}
          >
            <Input 
              placeholder="Enter URL" 
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              />
          </Item>
          <Item name='comment' label='Comment'>
            <TextEditor value={comment} onChange={setComment} />
          </Item>
          <div className='wrapperButton'>
            <Button type='primary' htmlType='submit'>Submit</Button>
          </div>
        </Form>
      </div>
    </Modal>
  )
}

export default InfrastructureModal;
