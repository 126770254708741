import React from 'react';
import { Controller } from 'react-hook-form';
import { Input, Typography } from 'antd';
import './styles.scss';

const FormInput = ({ title, placeholder, name, control, error, required = false }) => {
  return (
    <>
      <Controller
        name={name}
        control={control}
        rules={{ required: required ? `${title} is required` : false }}
        render={({ field: { onChange, value } }) => (
          <>
            <Typography className="field__label">
              {title} {required && <span className="required">*</span>}
            </Typography>
            <Input placeholder={placeholder} value={value} onChange={onChange} status={error ? 'error' : null} />
          </>
        )}
      />
      {error && <p className="errorText">{error.message}</p>}
    </>
  );
};

export default FormInput;
