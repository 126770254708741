import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import useResizeWindows from '../../../../utils/hooks/useResizeWindows';
import { getDayoff } from '../../../../store/actions/daysoffsActions';
import { Button } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import DetailsDayoffModal from './DetailsDayoffModal';

const DetailsModalButton = ({ id, icon = false }) => {
  const dispatch = useDispatch();
  const width = useResizeWindows();
  const [detailsModalShow, setDetailsModalShow] = useState(false);
  const click = () => {
    setDetailsModalShow(true);
    dispatch(getDayoff(id));
  };
  return (
    <>
      {icon || width < 620 ? (
        <InfoCircleOutlined className="listItem__info" onClick={() => click()} style={{ color: '#52c41a' }} />
      ) : (
        <Button onClick={() => click()}>Details</Button>
      )}
      <DetailsDayoffModal
        id={id}
        show={detailsModalShow}
        close={() => {
          setDetailsModalShow(false);
        }}
      />
    </>
  );
};

export default DetailsModalButton;
