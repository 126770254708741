export const status_project = [
  {
    name: 'Done',
    id: 'DONE',
  },
  {
    name: 'In progress',
    id: 'IN_PROGRESS',
  },
  {
    name: 'Rejected',
    id: 'REJECTED',
  },
  {
    name: 'Need Feedback',
    id: 'NEED_FEEDBACK',
  },
];
export const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
export const calendarDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
export const monthObject = {
  '01': 'January',
  '02': 'February',
  '03': 'March',
  '04': 'April',
  '05': 'May',
  '06': 'June',
  '07': 'July',
  '08': 'August',
  '09': 'September',
  10: 'October',
  11: 'November',
  12: 'December',
};

export const monthReverseObject = {
  'January': '01' ,
  'February': '02',
  'March': '03',
  'April': '04',
  'May': '05',
  'June': '06',
  'July': '07',
  'August': '08',
  'September': '09',
  'October': '10', 
  'November': '11', 
  'December': '12', 
};

export const shortMonthObject = {
  '01': 'Jan',
  '02': 'Feb',
  '03': 'Mar',
  '04': 'Apr',
  '05': 'May',
  '06': 'Jun',
  '07': 'Jul',
  '08': 'Aug',
  '09': 'Sept',
  '10': 'Oct',
  '11': 'Nov',
  '12': 'Dec',
};
export const hourTemplate = {
  amount: null,
  comment: '',
  status: 'IN_PROGRESS',
  task: null,
  phase: null,
};
export const dayOffTypes = [
  { value: 'DOF', label: 'Day off' },
  { value: 'VAC', label: 'Vacation' },
  { value: 'ILL', label: 'Illness' },
];
export const grades = [
  {
    name: 'Junior developer 1',
    points: 20,
  },
  {
    name: 'Junior developer 2',
    points: 20,
  },
  {
    name: 'Developer 1',
    points: 30,
  },
  {
    name: 'Developer 2',
    points: 35,
  },
  {
    name: 'Developer 3',
    points: 45,
  },
  {
    name: 'Senior developer 1',
    points: 60,
  },
  {
    name: 'Senior developer 2',
    points: 70,
  },
  {
    name: 'Expert developer 1',
    points: 90,
  },
  {
    name: 'Expert developer 2',
    points: 'n/a',
  },
];
export const defaultEmployeeNavigation = {
  active: {
    items: [
      { id: '1', title: 'hours' },
      { id: '2', title: 'tasks' },
      { id: '3', title: 'contacts' },
      { id: '4', title: 'birthdays' },
      { id: '5', title: 'news' },
      { id: '6', title: 'dayoff' },
      { id: '7', title: 'projects' },
      { id: '8', title: 'documents' },
      { id: '9', title: 'events' },
    ],
  },
  hidden: {
    items: [],
  },
};
export const defaultStorageFilters = {
  news: ''
};

export const defaultDashboardSettings = {
  calendarFullwidth: false,
  birthdays: true,
  events: true,
  dayoffs: true,
};

export const amountMoreMessage = "Hours amount  should be greater then 0.5.";
export const taskMessage = "Task value should not be null.";
export const amountLessMessage = 'Hours amount should be less then 24.';
export const totalAmountMessage = 'Try to write less than 24 hours a day.';

export const phaseHoursExceeded = "Amount of hours for phase is exceeded.";