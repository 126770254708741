import { GET_PHASES_FAILURE, GET_PHASES_REQUEST, GET_PHASES_SUCCESS } from '../actionTypes';
import axiosWH from '../../utils/axios';

const getPhasesRequest = () => ({ type: GET_PHASES_REQUEST });
const getPhasesSuccess = (phases) => ({ type: GET_PHASES_SUCCESS, phases });
const getPhasesFailure = (error) => ({ type: GET_PHASES_FAILURE, error });

export const getPhases = () => {
  return async (dispatch) => {
    try {
      dispatch(getPhasesRequest());
      const response = await axiosWH.get('/api/v1/phases');
      dispatch(getPhasesSuccess(response.data.data.items));
    } catch (e) {
      dispatch(getPhasesFailure(e));
    }
  };
};
