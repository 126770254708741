import React, { useState } from 'react';
import { Form, Input, Button, Upload } from 'antd';
import ModalFormWrapper from '../../../../components/UI/ModalForm/ModalFormWrapper';


const { TextArea } = Input;
const { Item } = Form;

const AddFile = () => {
	const [title, setTitle] = useState(null);
	const [description, setDescription] = useState(null);

  return (
    <ModalFormWrapper modalTitle='Add files' buttonName='+ File'>
			<Item name='title' label='Title'>
				<Input
					placeholder='Enter title' 
					value={title} 
					onChange={(e) => setTitle(e.target.value)}/>
			</Item>
			<Item name='description' label='Description'>
				<TextArea
					placeholder='Enter description' 
					value={description}
					onChange={(e) => setDescription(e.target.value)}
					autoSize={{ minRows: 3, maxRows: 5 }}
					//parse??
				/>
			</Item>
			<Item name='file' label='File'>
				<Upload.Dragger
					multiple
					listType="picture"
					>
					<Button>Upload file</Button>
				</Upload.Dragger>
			</Item>

    </ModalFormWrapper>
  )
}
export default AddFile;