import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getTasks } from '../../store/actions/tasksActions';
import Container from '../../components/layout/Container/Container';
import ProjectsCollapse from './ProjectsCollapse';
import TaskModal from './TaskModal';
import { CustomizedEmptyComponent } from '../../components/UI/CustomizedEmpty/CustomizedEmpty';
import { Button, Typography, Badge, Spin, Divider } from "antd";
import { PlusOutlined } from '@ant-design/icons';
import { titleLevel, getUniqueArray } from '../../utils/helpers';
import './styles.scss';


const { Title } = Typography;

const Tasks = () => {
  const dispatch = useDispatch();
  const tasks = useSelector((state) => state.tasks.tasks);
  // const projects = useSelector((state) => state.tasks.projects);
  const loading = useSelector((state) => state.tasks.loading);
  const phases = useSelector((state) => state.phases.phases);
  const phasesLoading = useSelector((state) => state.phases.loading);
  console.log('phasesLoading: ', phasesLoading)


  const [visible, setVisible] = useState(false);

  useEffect(() => {
    dispatch(getTasks(0));
  }, [dispatch]);

  const getRightProjects = (phasess) => {
    let filteredProjects = phases.map((phase) => phase.project);
    return getUniqueArray(filteredProjects);
  }

  let rightProjects = getRightProjects(phases);

  return (
    <Spin spinning={loading} size="large">
      <Container>
        <div className="tasksPage__header">
          <div className="tasks__wrapperTitle">
            <Badge count={tasks.length} overflowCount={999} color={'green'} >
              <Title level={titleLevel()} className="tasksPage__header_title">Tasks</Title>
            </Badge>
            {phasesLoading && <Spin />}
          </div>

          <Button icon={<PlusOutlined />} onClick={() => setVisible(!visible)}>
            Create Task
          </Button>
        </div>
        <Divider/>
        { rightProjects?.length !== 0 
        ? <ProjectsCollapse projects={rightProjects} tasks={tasks} />
        : <CustomizedEmptyComponent text="No projects with tasks"/>}

      </Container>
      <TaskModal visible={visible} setVisible={setVisible} />
    </Spin>
  );
};

export default Tasks;
