import React from "react";
import parse from 'html-react-parser';
import { Collapse, Descriptions } from "antd";
// import moment from 'moment';
import dayjs from 'dayjs';
import { CustomizedEmptyComponent } from "../../../components/UI/CustomizedEmpty/CustomizedEmpty";


const { Panel } = Collapse;

const About = ({ data }) => {
  return (
    <div className="project-about">
      <Collapse defaultActiveKey={["1"]} size={'small'}>
        <Panel header="General" key="1">
          <Descriptions bordered column={1} size={'small'}>
            <Descriptions.Item label="Name" labelStyle={{ width: "25%" }}>
              {data?.name}
            </Descriptions.Item>
            <Descriptions.Item label="Other Name">
              {data?.otherNames}
            </Descriptions.Item>
            <Descriptions.Item label="Email alias">
              {data?.emailAlias}projects.sibers.com
            </Descriptions.Item>
            <Descriptions.Item label="Started/finished	">
              {/* {moment(data?.createdAt).format('L')} - now <span style={{color: 'red'}}>TO DO</span> */}
              {dayjs(data?.createdAt).format('L')} - now <span style={{color: 'red'}}>TO DO</span>
            </Descriptions.Item>
            <Descriptions.Item label="Source">
              {data?.source}
            </Descriptions.Item>
            <Descriptions.Item label="NDA">
              {data?.isNda ? 'YES' : 'NO'}
            </Descriptions.Item>
            <Descriptions.Item label="Internal">
              {data?.isInternal ? 'YES' : 'NO'}
            </Descriptions.Item>
            <Descriptions.Item label="Deadline">
              {data?.dateDeadline}
            </Descriptions.Item>
            <Descriptions.Item label="Status">
              {data?.status}
            </Descriptions.Item>
          </Descriptions>
        </Panel>
        <Panel header="Links" key="2">
          {parse(data?.links ?? '')}
          {!data?.links ? <CustomizedEmptyComponent text='No links' /> : <></>}
        </Panel>
        <Panel header="Description" key="3">
          <p>{data?.descriptionProduct ? parse(data?.descriptionProduct) : data?.descriptionProduct}</p>
          {!data?.descriptionProduct ? <CustomizedEmptyComponent text='No description' /> : <></>}
        </Panel>
      </Collapse>
    </div>
  );
};

export default About;
