import { GET_EMPLOYEE_REPORT_REQUEST, GET_EMPLOYEE_REPORT_SUCCESS, GET_EMPLOYEE_REPORT_FAILURE } from '../actionTypes';

const initialState = {
  hoursReport: null,
  hoursReportLoading: false,
  hoursReportError: null,
};

const reportReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_EMPLOYEE_REPORT_REQUEST:
      return { ...state, hoursReportLoading: true };
    case GET_EMPLOYEE_REPORT_SUCCESS:
      return { ...state, hoursReport: action.data, hoursReportLoading: false, hoursReportError: null };
    case GET_EMPLOYEE_REPORT_FAILURE:
      return { ...state, hoursReportLoading: false, hoursReportError: action.error };
    default:
      return { ...state };
  }
};

export default reportReducer;
