import React from "react";
import { useSelector } from "react-redux";
import { Input, Button, Checkbox } from "antd";
import useResizeWindows from "../../utils/hooks/useResizeWindows";

const ProjectsFilter = ({ name, setName, client, setClient, checked, setChecked, reset }) => {
  
  const width = useResizeWindows();
  const userId = useSelector((state) => state.user?.user?.id);

  const setUserId = (e) => {
    setChecked(e.target.checked ? userId : null);
  };


  return (
    <div className="projects__filter">
      <Input
        placeholder="Name"
        onChange={(e) => setName(e.target.value)}
        value={name}
        className="projects__filter_input"
      />
      <Input
        placeholder="Client"
        onChange={(e) => setClient(e.target.value)}
        value={client}
        className="projects__filter_input"
      />
{/* {нет смысла в перестраивании на 450px это лишняя нагрузка УБРАТЬ ПЕРЕДЕЛАТЬ} */}
      {width > 450 ?
      <>
        <Checkbox checked={!!checked} onChange={(e) => setUserId(e)} className="projects__filter_checkbox">
          <b>My projects</b>
        </Checkbox>
        <Button type="primary" onClick={() => reset()}>
          Reset <span className="projectFilter__reset">filter</span>
        </Button>
      </>
      : <div className="checkbox-with-reset">
          <Checkbox checked={!!checked} onChange={(e) => setUserId(e)} className="projects__filter_checkbox">
            <b>My projects</b>
          </Checkbox>
          <Button type="primary" onClick={() => reset()}>
            Reset <span className="projectFilter__reset">filter</span>
          </Button>
        </div>
        }
    </div>
  );
};

export default ProjectsFilter;
