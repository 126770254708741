import React from 'react';
import moment from 'moment';
import { List, Row, Col, ConfigProvider } from 'antd';
import { dateHelper, sortedDates } from '../../../utils/helpers';
import { shortMonthObject } from '../../../utils/constants';
import UserInfoModalButton from "../UserInfoModal/UserInfoModalButton";
import DetailsModalButton from "../../../pages/Dayoff/DayoffModals/DetailsModal/DetailsModalButton";
import CustomizedEmpty from '../CustomizedEmpty/CustomizedEmpty';
import './styles.scss';

const DayoffMain = ({ dayOffs }) => {
  const customizeRenderEmpty = CustomizedEmpty("No dayoffs");

  const getNiceShortDate = (date) => {
    let arr = date.split('-');
    return `${arr[1]}-${arr[2]}`
    // return `${shortMonthObject[arr[1]]}${+arr[2]}`
  }
  let now = new Date();
  let future = now.setDate(now.getDate() + 14);

  let shortDayoffs = dayOffs?.filter((dayoff) => (future - new Date(dayoff?.startDate)?.getTime()) >= 0)
  let sortedDayoffs = shortDayoffs ? sortedDates(shortDayoffs) : shortDayoffs;

  return (
    <ConfigProvider renderEmpty={customizeRenderEmpty}>
    <div className='dayoffMain__wrapper'>
      <List
        bordered
        header={<b>Next 2 Week Dayoffs</b>}
        // dataSource={dayOffs ?? []}
        dataSource={sortedDayoffs ?? []}
        renderItem={(item) => (
          <List.Item>
            <Row gutter={4} style={{width:'100%'}}>
              {/* <Col xs={24} sm={8} md={8} lg={24} xxl={24}>
                <span className='dayoffMain__date'>{`${dateHelper(item.startDate)} / ${dateHelper(item.endDate)}`}</span>
              </Col>
              <Col xs={22} sm={14} md={14} lg={22} xxl={22}>
                <UserInfoModalButton user={item.user} userType={'Employees'}/>

              </Col>
              <Col xs={2} sm={2} md={2} lg={2} xxl={2} style={{textAlign: 'right'}}>
                <DetailsModalButton id={item.id} icon/>
              </Col> */}


              <Col xs={22} sm={22} md={22} lg={22} xxl={22}>
                <span className='dayoffMain__date'>{`${getNiceShortDate(dateHelper(item.startDate))}/${getNiceShortDate(dateHelper(item.endDate))}`}</span>
                <UserInfoModalButton user={item.user} userType={'Employees'}/>
              </Col>
              <Col xs={2} sm={2} md={2} lg={2} xxl={2}>
                <DetailsModalButton id={item.id} icon/>

              </Col>
            </Row>
          </List.Item>
        )}
      />

    </div>
    </ConfigProvider>
  );
};

export default DayoffMain;
