import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getEmployeeHoursReport } from '../../store/actions/reportActions';
import { Col, Divider, Row, Space, Spin, Typography } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import Container from '../../components/layout/Container/Container';
import HoursReport from '../Profile/HoursReport';
import ReportDetailedTable from './ReportDetailedTable';
import {
  currentMonthYear,
  dateHelper,
  getFirstAndLastDayOfMonth,
  titleLevel,
  hoursReportDetailedParser,
} from '../../utils/helpers';

const { Title, Text } = Typography;

const FullHoursReport = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const hoursReport = useSelector((state) => state.report.hoursReport);
  const hoursReportLoading = useSelector((state) => state.report.hoursReportLoading);
  const [currentDate, setCurrentDate] = useState(dateHelper(new Date(), '-'));
  const [firstDay, lastDay] = getFirstAndLastDayOfMonth(currentDate);
  useEffect(() => {
    dispatch(getEmployeeHoursReport(user.id, firstDay, lastDay));
  }, [dispatch, user, user.id, currentDate]);
  const changeMonths = (value) => {
    let result;
    if (value) {
      result = dateHelper(new Date(currentDate).setMonth(new Date(currentDate).getMonth() + 1));
    } else {
      result = dateHelper(new Date(currentDate).setMonth(new Date(currentDate).getMonth() - 1));
    }
    setCurrentDate(result);
  };
  return (
    <Spin spinning={hoursReportLoading} size="large">
      <Container>
        <Row>
          <Col xs={12} sm={16} md={16} lg={16}>
            <Title level={titleLevel()} style={{ margin: 0 }}>
              Employee Report
            </Title>
            <Text mark>
              {firstDay} - {lastDay}
            </Text>
          </Col>
          <Col xs={12} sm={8} md={8} lg={8} style={{ textAlign: 'right' }}>
            <Space>
              <LeftOutlined className="main__month_arrow" onClick={() => changeMonths(false)} />
              <Title level={5} className="main__month_title">
                {currentMonthYear(currentDate, true)}
              </Title>
              <RightOutlined className="main__month_arrow" onClick={() => changeMonths(true)} />
            </Space>
          </Col>
        </Row>
        <Divider />
        <HoursReport data={hoursReport?.report.brief} />
        <Divider />
        <Title level={titleLevel()} style={{ margin: 0 }}>
          Hours Detailed
        </Title>
        <Divider />
        <ReportDetailedTable data={hoursReportDetailedParser(hoursReport?.report.detailed)} />
      </Container>
    </Spin>
  );
};

export default FullHoursReport;
