import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createDayoff } from '../../../../store/actions/daysoffsActions';
import { getContacts } from '../../../../store/actions/contactsActions';
import { DatePicker, Modal, Select, Input, Space, Radio, Spin } from 'antd';
import { dayOffTypes } from '../../../../utils/constants';
import { getAccess } from '../../../../utils/helpers';
// import moment from 'moment';
import dayjs from 'dayjs';
import { loadFromLocalStorage } from '../../../../store/localStorage';
import { getUsersOptions, getDatePickerValue } from '../../../../utils/helpers';


const { TextArea } = Input;

const CreateDayoffModal = ({ show, closeModal }) => {
  let localStorageUser = loadFromLocalStorage('user').user.user
  let currentUser = `${localStorageUser.lastName} ${localStorageUser.firstName}`

  const dispatch = useDispatch();
  const userId = useSelector((state) => state.user?.user?.id);
  const createDayOffLoading = useSelector((state) => state.dayOff.createDayOffLoading);
  const users = useSelector((state) => state.contact?.contacts?.data.items);
  const [dayoffType, setDayoffType] = useState('DOF');
  const [dayoffUser, setDayoffUser] = useState(currentUser) //////////////////
  const [usersOptionsState, setUsersOptions] = useState([]);/////////////////
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [reason, setReason] = useState(null);
  const [dateWorkOff, setDateWorkOff] = useState(null);
  const [willWork, setWillWork] = useState(1);

  useEffect(() => {
    dispatch(getContacts());
  }, [dispatch]);

  useEffect(() => {
    if (users) {
      let usersOptions = getUsersOptions(users);
      setUsersOptions(usersOptions);
    }

  }, [users])


  let createAccess = getAccess('dayoff', 'create');

  const getRightUserId = () => {
    if (!createAccess) return userId
    else {
      if(dayoffUser) {//not undefined
        if (dayoffUser!=='Select user') {
          let userSelectedInfo = usersOptionsState.find(user => user.value === dayoffUser);
          return userSelectedInfo.id;
        } else return userId
      } else return userId
    }
  }


  const createDayoffHandler = async () => {
    let rightUserId = getRightUserId();

    const dayOffData = {
      user: rightUserId,
      startDate: getDatePickerValue(startDate),
      endDate: getDatePickerValue(endDate),
      type: dayoffType,
      description: reason,
      dateWorkOff,
      workOff: willWork,
    };
    await dispatch(createDayoff({ dayoff: dayOffData }));
    closeModalHandler();
  };
  const closeModalHandler = () => {
    closeModal();
    setDayoffType('DOF');
    setDayoffUser(currentUser)///////////////////////////
    setStartDate(new Date());
    setEndDate(new Date());
    setReason(null);
    setDateWorkOff(null);
    setWillWork(1);
  };
  const dateFormat = 'YYYY-MM-DD';

  return (
    <Modal open={show} onCancel={closeModalHandler} title="Create Dayoff" okText="Save" onOk={createDayoffHandler}>
      <Spin spinning={createDayOffLoading}>
        <Space direction={'vertical'} size={'middle'} style={{ width: '100%' }}>
          <Select
            dropdownMatchSelectWidth={125}
            style={{ width: 125, marginBottom: '15px' }}
            value={dayoffType}
            onChange={(e) => setDayoffType(e)}
            options={dayOffTypes}
          />
          {createAccess ? 
          <Select
            dropdownMatchSelectWidth={125}
            style={{ width: 200, marginBottom: '15px' }}
            value={dayoffUser}
            onChange={(e) => setDayoffUser(e)}
            options={usersOptionsState}
          />:<></>}

          <Space size={'middle'}>
            <DatePicker
              placeholder="Start date"
              size="large"
              className="datepicker__item"
              // defaultValue={moment(startDate, dateFormat)}
              value={dayjs(getDatePickerValue(startDate), dateFormat)}
              format={dateFormat}
              onChange={(e) => setStartDate(e)}
            />
            <DatePicker
              placeholder="End date"
              size="large"
              className="datepicker__item"
              // defaultValue={moment(endDate, dateFormat)}
              value={dayjs(getDatePickerValue(endDate), dateFormat)}
              format={dateFormat}
              onChange={(e) => setEndDate(e)}
            />
          </Space>
          <TextArea
            rows={4}
            placeholder="Reason"
            size="large"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          />
          <Radio.Group onChange={(e) => setWillWork(e.target.value)} value={willWork}>
            <Space direction="vertical">
              <Radio value={1}>I Will Work</Radio>
              <Radio value={2}>I Will not Work</Radio>
            </Space>
          </Radio.Group>
          <Input
            placeholder="Enter your date work off"
            value={dateWorkOff}
            onChange={(e) => setDateWorkOff(e.target.value)}
          />
        </Space>
      </Spin>
    </Modal>
  );
};

export default CreateDayoffModal;
