import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSingleNews, getSingleNewsSuccess } from '../../store/actions/newsActions';
import { Divider, Modal, Spin } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { HorribleSmile, BadSmile, NormalSmile, SatisfiedSmile, HappySmile } from '../../assets/whitterIcons';
import { dateHelper } from "../../utils/helpers";
import parse from 'html-react-parser';



const WhitterItem = ({ title, date, rating, haveBody, id }) => {
  const dispatch = useDispatch();
  const singleNewsLoading = useSelector((state) => state.news.singleNewsLoading);
  const singleNews = useSelector((state) => state.news.singleNews);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
    dispatch(getSingleNews(id));
  };
  const handleCancel = () => {
    setIsModalVisible(false);
    dispatch(getSingleNewsSuccess(null));
  };
  const icon = (value) => {
    switch (value) {
      case -2:
        return <HorribleSmile />;
      case -1:
        return <BadSmile />;
      case 0:
        return <NormalSmile />;
      case 1:
        return <SatisfiedSmile />;
      case 2:
        return <HappySmile />;
      default:
        return <NormalSmile />;
    }
  };

let newBody = singleNews?.body ? parse(singleNews?.body) : singleNews?.body;
// console.log('newBody: ', newBody);


  return (
    <>
      <Modal title={null} open={isModalVisible} footer={null} onCancel={handleCancel}>
        <Spin spinning={singleNewsLoading}>
          <div className="whitterItem__modalContainer">
            <div className="whitterItem__modal">
              {icon(singleNews?.rating)}
              <span className="whitterItem__modal_date"> {dateHelper(singleNews?.date)}</span>
            </div>
            <Divider />
            <p className="whitterItem__modal_title">{singleNews?.title}</p>
            <Divider />
            <p className="whitterItem__modal_body">{newBody}</p>
            {/* <p className="whitterItem__modal_body">{singleNews?.body}</p> */}
          </div>
        </Spin>
      </Modal>
      <div className={`whitterItem ${ haveBody ? 'cursorPointer' : '' }`} onClick={haveBody ? showModal : () => {}}>
        <div className="whitterItem__rating">
          {icon(rating)}
        </div>
        <div className="whitterItem__body">
          <span className="whitterItem__date">{dateHelper(date)}</span>
          <span className="whitterItem__title">{title}</span>
        </div>
        <div className="whitterItem__more">
          {haveBody && (
            <RightOutlined className="whitterItem__moreIcon" style={{ color: '#08c' }} />
          )}
        </div>
      </div>
      <Divider style={{ margin: '5px 0' }} />
    </>
  );
};

export default WhitterItem;
