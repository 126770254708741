import {
  GET_USERS_BIRTHDAYS_REQUEST,
  GET_USERS_BIRTHDAYS_SUCCESS,
  GET_USERS_BIRTHDAYS_FAILURE,
  GET_CLIENTS_BIRTHDAYS_REQUEST,
  GET_CLIENTS_BIRTHDAYS_SUCCESS,
  GET_CLIENTS_BIRTHDAYS_FAILURE,
} from '../actionTypes';
import axiosWH from '../../utils/axios';
import { getClientsBirthdaysAccess } from "../../utils/helpers.js";

const getUsersBirthdayRequest = () => ({ type: GET_USERS_BIRTHDAYS_REQUEST });
const getUsersBirthdaySuccess = (data) => ({ type: GET_USERS_BIRTHDAYS_SUCCESS, data });
const getUsersBirthdayFailure = (error) => ({ type: GET_USERS_BIRTHDAYS_FAILURE, error });

const getClientsBirthdayRequest = () => ({ type: GET_CLIENTS_BIRTHDAYS_REQUEST });
const getClientsBirthdaySuccess = (data) => ({ type: GET_CLIENTS_BIRTHDAYS_SUCCESS, data });
const getClientsBirthdayFailure = (error) => ({ type: GET_CLIENTS_BIRTHDAYS_FAILURE, error });

export const getUsersBirthdays = (month) => {
  return async (dispatch) => {
    try {
      dispatch(getUsersBirthdayRequest());
      const response = await axiosWH.get(`api/v1/users/birthdays/${month}`, {
        params: {
          limit: 0
        },
      });
      dispatch(getUsersBirthdaySuccess(response.data.data.items));
    } catch (error) {
      dispatch(getUsersBirthdayFailure(error));
    }
  };
};

export const getClientsBirthdays = (month) => {
  const clientsBirthdaysAccess = getClientsBirthdaysAccess();

  return async (dispatch) => {
    if(clientsBirthdaysAccess) {

      try {
        dispatch(getClientsBirthdayRequest());
        const response = await axiosWH.get(`api/v1/clients/birthdays/${month}`, {
          params: {
            limit: 0
          },
        });
        dispatch(getClientsBirthdaySuccess(response.data.data.items));
      } catch (error) {
        dispatch(getClientsBirthdayFailure(error));
      }

    } else return {}
  };

};
