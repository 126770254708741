import {
  LOGIN_USER_REQUEST,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  LOGOUT_USER,
  GET_CURRENT_USER_REQUEST,
  GET_CURRENT_USER_SUCCESS,
  GET_CURRENT_USER_FAILURE,
  GET_CURRENT_USER_SETTINGS_REQUEST,
  GET_CURRENT_USER_SETTINGS_SUCCESS,
  GET_CURRENT_USER_SETTINGS_FAILURE,
  PUT_CURRENT_USER_SETTINGS_REQUEST,
  PUT_CURRENT_USER_SETTINGS_SUCCESS,
  PUT_CURRENT_USER_SETTINGS_FAILURE,
} from '../actionTypes';
import axiosWH from '../../utils/axios';

const loginUserRequest = () => ({ type: LOGIN_USER_REQUEST });
const loginUserSuccess = (user) => ({ type: LOGIN_USER_SUCCESS, user });
const loginUserFailure = (error) => ({ type: LOGIN_USER_FAILURE, error });

const getCurrentUserRequest = () => ({ type: GET_CURRENT_USER_REQUEST });
const getCurrentUserSuccess = (data) => ({ type: GET_CURRENT_USER_SUCCESS, data });
const getCurrentUserFailure = (error) => ({ type: GET_CURRENT_USER_FAILURE, error });

const getCurrentUserSettingsRequest = () => ({ type: GET_CURRENT_USER_SETTINGS_REQUEST });
const getCurrentUserSettingsSuccess = (data) => ({ type: GET_CURRENT_USER_SETTINGS_SUCCESS, data });
const getCurrentUserSettingsFailure = (error) => ({ type: GET_CURRENT_USER_SETTINGS_FAILURE, error });

const putCurrentUserSettingsRequest = () => ({ type: PUT_CURRENT_USER_SETTINGS_REQUEST });
const putCurrentUserSettingsSuccess = () => ({ type: PUT_CURRENT_USER_SETTINGS_SUCCESS });
const putCurrentUserSettingsFailure = (error) => ({ type: PUT_CURRENT_USER_SETTINGS_FAILURE, error });

export const loginUser = (userData, navigate) => {
  return async (dispatch) => {
    try {
      dispatch(loginUserRequest());
      const response = await axiosWH.post('/api/security/login', userData);
      dispatch(loginUserFailure(null));
      dispatch(loginUserSuccess(response.data.data));
      navigate('/');
    } catch (error) {
      if (error.response && error.response.data) {
        dispatch(loginUserFailure(error.response.data.errors));
      } else {
        dispatch(loginUserFailure({ global: 'No internet' }));
      }
    }
  };
};

export const logoutUser = () => {
  return async (dispatch) => {
    localStorage.removeItem('user');
    dispatch({ type: LOGOUT_USER });
    window.location = '/login';
  };
};

export const getCurrentUser = () => {
  return async (dispatch) => {
    try {
      dispatch(getCurrentUserRequest());
      const response = await axiosWH.get('/api/v1/user/current');
      dispatch(getCurrentUserSuccess(response.data));
    } catch (e) {
      dispatch(getCurrentUserFailure(e));
    }
  };
};

export const getCurrentUserSettings = () => {
  return async (dispatch) => {
    try {
      dispatch(getCurrentUserSettingsRequest());
      const response = await axiosWH.get('/api/v1/user/current/settings');
      dispatch(getCurrentUserSettingsSuccess(response.data.data));
    } catch (e) {
      dispatch(getCurrentUserSettingsFailure(e));
    }
  };
};

export const putCurrentUserSettings = (data) => {
  return async (dispatch) => {
    try {
      dispatch(putCurrentUserSettingsRequest());
      dispatch(getCurrentUserSettingsSuccess(data));
      await axiosWH.put('/api/v1/user/current/settings', data);
      dispatch(putCurrentUserSettingsSuccess());
    } catch (e) {
      dispatch(putCurrentUserSettingsFailure(e));
    }
  };
};
