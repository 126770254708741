import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useResizeWindows from "../../utils/hooks/useResizeWindows";
import { getDayoffs } from '../../store/actions/daysoffsActions';
import { getContacts } from '../../store/actions/contactsActions';
import { Spin, Typography, Table, Space, Divider, ConfigProvider } from 'antd';
import Container from '../../components/layout/Container/Container';
import DayoffFilter from './DayoffFilter';
import CreateDayoffButton from './DayoffModals/CreateDayoff/CreateDayoffButton';
import DetailsModalButton from './DayoffModals/DetailsModal/DetailsModalButton';
import EditModalButton from './DayoffModals/EditModal/EditModalButton';
import UserInfoModalButton from '../../components/UI/UserInfoModal/UserInfoModalButton';
import CustomizedEmpty from '../../components/UI/CustomizedEmpty/CustomizedEmpty';
import { dateHelper, titleLevel } from '../../utils/helpers';
import { getAccess, sortedDates } from '../../utils/helpers';
// import moment from 'moment';
import dayjs from 'dayjs';
import './styles.scss';

const { Title, Text } = Typography;

const Dayoff = () => {
  const dispatch = useDispatch();
  const width = useResizeWindows();
  const dayOffs = useSelector((state) => state?.dayOff?.dayOffs);
  const dayOffsLoading = useSelector((state) => state.dayOff.dayOffsLoading);
  const contacts = useSelector((state) => state.contact?.contacts?.data);
  const userId = useSelector((state) => state.user?.user?.id);

  const [searchStartDate, setSearchStartDate] = useState(new Date());
  // const [searchEndDate, setSearchEndDate] = useState(moment().endOf('month'));
  const [searchEndDate, setSearchEndDate] = useState(dayjs().endOf('month'));
  const [user, setUser] = useState(null);
  const [myDayOff, setMyDayOff] = useState(false);

  useEffect(() => {
    dispatch(getContacts());
  }, [dispatch]);
  useEffect(() => {
    let currentUser;
    if (myDayOff) currentUser = userId;
    if (user) currentUser = user;
    dispatch(getDayoffs(dateHelper(searchStartDate), dateHelper(searchEndDate), currentUser));
  }, [dispatch, searchStartDate, searchEndDate, myDayOff, user]);
  
  let editAccess = getAccess('dayoff', 'edit');
  // let createAccess = getAccess('dayoff', 'create');
  const customizeRenderEmpty = CustomizedEmpty("No Dayoffs");
  
  let now = new Date(new Date().toLocaleDateString('en-CA')).getTime();
  let getTimestamp = (newDate) => new Date (new Date(newDate)?.toLocaleDateString('en-CA')).getTime();


  const columns = [
    {
      title: 'Date',
      dataIndex: 'startDate',
      key: 'startDate',
      render: (_, date) => (
        <div className={`wrapper-cell ${ now >= getTimestamp(date?.startDate) && now <= getTimestamp(date?.endDate) ? 'isToday' : ''}`}>
          <p className={`dayoff__date`}>
            {`${dateHelper(date.startDate)} / ${dateHelper(date.endDate)}`}
          </p>
        </div>
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      // render: (_, record) => <UserInfoModalButton user={record?.user}/>,
      render: (_, record) => (
        <div className='wrapper-cell'>
          <UserInfoModalButton user={record?.user} userType={'Employees'}/>
        </div>
      )

    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (_, record) => (
        <div className='wrapper-cell'>
          <Text mark>{record?.type}</Text>
        </div>
      )
  
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => (
        <div className='wrapper-cell'>
          <Space size={'middle'}>
            <DetailsModalButton id={record.id} />
            {(record.user.id === userId || editAccess) && <EditModalButton id={record.id} />}
          </Space>
        </div>
      ),
    },
  ];

  const columnsMobile = [
    {
      title: 'Date / Name',
      dataIndex: 'startDate',
      key: 'startDate',
      render: (_, record) => (
        <>
          <p className="dayoff__date">{`${dateHelper(record.startDate)} / ${dateHelper(record.endDate)}`}</p>
          {/* <UserInfoModalButton user={record?.user} /> */}
          <UserInfoModalButton user={record?.user} userType={'Employees'}/>

          
        </>
      ),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (_, record) => <Text mark>{record?.type}</Text>
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => (
        <Space size={'middle'}>
          <DetailsModalButton id={record.id} />
          {(record.user.id === userId || editAccess) && <EditModalButton id={record.id} />}
        </Space>
      ),
    },
  ];

  const changeStartDate = (e) => {
    setSearchStartDate(e);
  };
  const changeEndDate = (e) => {
    setSearchEndDate(e);
  };
  const changeUser = (e) => {
    setMyDayOff(false);
    setUser(e);
  };
  const changeMyDayOff = (e) => {
    setUser(null);
    setMyDayOff(e.target.checked);
  };

  let sortedDayoffs = dayOffs ? sortedDates(dayOffs) : dayOffs
  

  return (
    <Spin spinning={dayOffsLoading}>
      <Container>
        <div className="dayoff__header">
          <Title level={titleLevel()} style={{ margin: 0 }}>
            Day off
          </Title>
          <CreateDayoffButton />
        </div>
        <Divider />
        <Space direction={'vertical'} style={{ width: '100%' }} size={'large'}>
          <DayoffFilter
            contacts={contacts?.items}
            startDate={searchStartDate}
            endDate={searchEndDate}
            user={user}
            myDayOff={myDayOff}
            setUser={changeUser}
            setMyDayOff={changeMyDayOff}
            changeStartDate={changeStartDate}
            changeEndDate={changeEndDate}
          />
          <ConfigProvider renderEmpty={customizeRenderEmpty}>
            <div className="dayoffPage__wrapper-table">
              <Table columns={width < 500 ? columnsMobile : columns} dataSource={sortedDayoffs} pagination={false} size='small' />
            </div>
          </ConfigProvider>
        </Space>
      </Container>
    </Spin>
  );
};

export default Dayoff;
