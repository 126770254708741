import React from 'react';
// import moment from 'moment';
import dayjs from 'dayjs';
import { Checkbox, DatePicker, Select, Row, Col } from 'antd';
import { getDatePickerValue, getFilterOption, getSortedAlphabet } from "../../utils/helpers.js";

const dateFormat = 'YYYY-MM-DD';

const DayoffFilter = ({
  contacts,
  startDate,
  endDate,
  changeStartDate,
  changeEndDate,
  user,
  setUser,
  myDayOff,
  setMyDayOff,
}) => {  

  const contactsOptions = [
    {
      value: null,
      label: 'For all employees',
    },
    ...getSortedAlphabet(!contacts ? [] : contacts?.map((contact) => ({
      value: contact?.id,
      label: `${contact?.lastName} ${contact?.firstName}`,
    })), 'label')
  ]; 


  return (
    <Row gutter={16}>
      <Col xs={12} sm={12} md={6} xl={4}>
        <DatePicker
          // defaultValue={moment(startDate, dateFormat)}
          value={dayjs(getDatePickerValue(startDate), dateFormat)}
          format={dateFormat}
          onChange={changeStartDate}
          allowClear={false}
          className="dayoff__filter_input"
        />
      </Col>
      <Col xs={12} sm={12} md={6} xl={4}>
        <DatePicker
          // defaultValue={moment(endDate, dateFormat)}
          value={dayjs(getDatePickerValue(endDate), dateFormat)}
          format={dateFormat}
          onChange={changeEndDate}
          allowClear={false}
          className="dayoff__filter_input"
        />
      </Col>
      <Col xs={12} sm={12} md={6} xl={4}>
        <Select
          showSearch
          placeholder="Select a person"
          // defaultValue={null}
          value={user}
          className="dayoff__filter_input"
          filterOption={getFilterOption}
          onChange={(value) => setUser(value)}
          options={contactsOptions}
        />
      </Col>
      <Col xs={12} sm={12} md={6} xl={4} className="dayoff__filter_checkbox">
        <Checkbox onChange={setMyDayOff} checked={myDayOff}>
          Only in my Day off
        </Checkbox>
      </Col>
    </Row>
  );
};

export default DayoffFilter;
