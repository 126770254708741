import React, { useState } from 'react';
import useResizeWindows from '../../../utils/hooks/useResizeWindows';
import { Tooltip } from 'antd';
import { ClockCircleOutlined, LockOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import HoursInfoModal from './HoursInfoModal';
import { dateHelper } from '../../../utils/helpers';
import eventIcon from '../../../assets/calendarIcons/eventIcon.png';
import holidayIcon from '../../../assets/calendarIcons/holidayIcon.png';
import workIcon from '../../../assets/calendarIcons/workIcon.png';

const Cell = ({ date, calendar, currentDate }) => {
  const width = useResizeWindows();
  const [modalDate, setModalDate] = useState(null);
  const currentDateInfo = calendar[date];
  const dateYear = date.slice(0, -6);
  const dateMonth = date?.slice(5, -3);
  const dateDay = date.slice(8);
  const d = new Date();
  const currentYear = d.getFullYear().toString();
  const currentMonth = d.getMonth().toString().length === 1 ? '0' + (d.getMonth() + 1) : (d.getMonth() + 1).toString();
  const currentDay = d.getDate().toString().length === 1 ? '0' + d.getDate() : d.getDate().toString();

  const backgroundColor = () => {
    if (
      ((currentDateInfo.weekday === '6' || currentDateInfo.weekday === '7') &&
        date.includes(currentDate) &&
        !(currentDateInfo.holiday && currentDateInfo.holiday.type === 'Worked')) ||
      (currentDateInfo.holiday && currentDateInfo.holiday.type === 'Holiday')
    ) {
      return '#fbd6e4'; //red #ffa9df29; //rgb(255 163 221 / 16%); // rgb(255 247 148 / 21%);//#fbd6e4
    } else if (dateMonth === currentMonth && dateDay === currentDay && dateYear === currentYear) {
      return '#D7FAE0'; //green
    } else if (date.includes(currentDate)) {
      return '#e2f2fb'; //blue rgb(220 244 255 / 69%); //#e2f2fb
    } else return '#ececec'; //grey rgb(236 236 236 / 50%) // rgba(236, 236, 236, 0.35);//#ececec
  };
  return (
    <>
      <HoursInfoModal date={modalDate} close={() => setModalDate(null)} data={currentDateInfo}/>
      <div
        className="cell"
        style={{ backgroundColor: backgroundColor() }}
        onClick={() => width <= 500 && setModalDate(date)}
      >
        <p className="cell__day" style={{ transform: 'scale(1)' }}>
          {dateDay[0] === '0' ? dateDay.slice(1) : dateDay}
        </p>
        <div className="cell__event">
          {currentDateInfo.holiday && currentDateInfo.holiday.type === 'Holiday' && (
            <Tooltip title={currentDateInfo.holiday.name}>
              <img src={holidayIcon} alt="event" className="cell__event_icon" />
            </Tooltip>
          )}
          {currentDateInfo.holiday && currentDateInfo.holiday.type === 'Worked' && (
            <Tooltip title={currentDateInfo.holiday.name}>
              <img src={workIcon} alt="event" className="cell__event_icon" />
            </Tooltip>
          )}
          {currentDateInfo.event && !currentDateInfo.holiday && (
            <Tooltip title={currentDateInfo.event.title}>
              <img src={eventIcon} alt="event" className="cell__event_icon" />
            </Tooltip>
          )}
        </div>

        <div className="cell__hours">
          {!currentDateInfo?.locked ? (
            <Link to={`/hours?date=${dateHelper(date)}`}>
              <ClockCircleOutlined className="cell__iconHours" />
            </Link>
          ) : (
            <LockOutlined className="cell__icon" />
          )}
        </div>
        <p className="cell__hoursAmount" onClick={() => width > 500 && setModalDate(date)}>
          {currentDateInfo?.hours ?? 0}
        </p>
      </div>
    </>
  );
};

export default Cell;
