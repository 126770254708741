import axios from 'axios';
import { apiURL } from './config';

axios.defaults.withCredentials = true;

const axiosWH = axios.create({
  baseURL: apiURL,
});

export default axiosWH;
