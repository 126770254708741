import {
  GET_HOURS_REPORT_REQUEST,
  GET_HOURS_REPORT_SUCCESS,
  GET_HOURS_REPORT_FAILURE,
  ADD_HOURS_RECORD_REQUEST,
  ADD_HOURS_RECORD_SUCCESS,
  ADD_HOURS_RECORD_FAILURE,
  DELETE_HOURS_RECORD_REQUEST,
  DELETE_HOURS_RECORD_SUCCESS,
  DELETE_HOURS_RECORD_FAILURE,
} from '../actionTypes';
import axiosWH from '../../utils/axios';

const getHoursReportRequest = () => ({ type: GET_HOURS_REPORT_REQUEST });
export const getHoursReportSuccess = (data) => ({ type: GET_HOURS_REPORT_SUCCESS, data });
const getHoursReportFailure = () => ({ type: GET_HOURS_REPORT_FAILURE });

const addHoursRecordRequest = () => ({ type: ADD_HOURS_RECORD_REQUEST });
const addHoursRecordSuccess = (data) => ({ type: ADD_HOURS_RECORD_SUCCESS, data });
const addHoursRecordFailure = (error) => ({ type: ADD_HOURS_RECORD_FAILURE, error });

const deleteHoursRecordRequest = () => ({ type: DELETE_HOURS_RECORD_REQUEST });
const deleteHoursRecordSuccess = (data) => ({ type: DELETE_HOURS_RECORD_SUCCESS, data });
const deleteHoursRecordFailure = (error) => ({ type: DELETE_HOURS_RECORD_FAILURE, error });

export const getHoursReport = (date) => {
  return async (dispatch) => {
    try {
      dispatch(getHoursReportRequest());
      const response = await axiosWH.get(`/api/v1/hours?format=json&limit=0&day=${date}`);
      dispatch(getHoursReportSuccess(response.data.data.items));
    } catch (error) {
      dispatch(getHoursReportFailure());
    }
  };
};


export const addHoursRecord = (data) => {
  return async (dispatch) => {
    try {
      dispatch(addHoursRecordRequest());
      const response = await axiosWH.post('/api/v1/hours/bulk', data);
      dispatch(addHoursRecordSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(addHoursRecordFailure(error));
      return error.response.data;
    }
  };
};

export const deleteHoursRecord = (id) => {
  return async (dispatch) => {
    try {
      dispatch(deleteHoursRecordRequest());
      const response = await axiosWH.delete(`/api/v1/hours/${id}`);
      dispatch(deleteHoursRecordSuccess(response.data));
      return  response.data;
    } catch (error) {
      dispatch(deleteHoursRecordFailure(error));
      return error.data;
    }
  };
};
