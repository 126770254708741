import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Pagination, Typography, Divider, Spin, Row, Col, Input, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { getNews } from '../../store/actions/newsActions';
import Container from '../../components/layout/Container/Container';
import Whitter from './Whitter';
import { titleLevel } from '../../utils/helpers';
import { defaultStorageFilters } from '../../utils/constants';
import { loadFromLocalStorage, saveToLocalStorage } from '../../store/localStorage';
import './styles.scss';

const { Title } = Typography;

const News = () => {
  const dispatch = useDispatch();
  const news = useSelector((state) => state.news.news);
  const newsLoading = useSelector((state) => state.news.newsLoading);
  const [storageFilters, setStorageFilters] = useState(null);
  const [page, setPage] = useState(1);
  // const [amountNews, setAmountNews] = useState(10);
  useEffect(() => {
    const filters = loadFromLocalStorage('filters');
    setStorageFilters(filters ?? defaultStorageFilters);
  }, []);
  useEffect(() => {
    if (storageFilters?.news?.length > 0) {
      dispatch(getNews(`page=${page - 1}&search=${storageFilters?.news}`));
    } else if (storageFilters) {
      dispatch(getNews(`page=${page - 1}`));
    }
  }, [dispatch, page, storageFilters]);

  const inputChangeHandler = (e) => {
    setPage(1);
    setStorageFilters({ ...storageFilters, news: e.target.value });
    saveToLocalStorage('filters', { ...storageFilters, news: e.target.value });
  };
  const onChange = (num) => {
    setPage(num);
    window.scroll(0, 0);
  };
  const resetFilters = () => {
    if (storageFilters?.news?.length > 0) {
      setPage(1);
      setStorageFilters({ ...storageFilters, news: '' });
      saveToLocalStorage('filters', { ...storageFilters, news: '' });
    }
  };

  return (
    <Spin spinning={newsLoading} size="large">
      <Container>
        <div className="news">
          <Row>
            <Col xs={24} sm={24} md={12} lg={12} xl={16}>
              <Title level={titleLevel()} style={{ marginBottom: 0 }}>
                News Archive
              </Title>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={8}>
              <div style={{ display: 'flex' }}>
                <Input
                  placeholder="Title"
                  value={storageFilters?.news}
                  onChange={(e) => inputChangeHandler(e)}
                  suffix={<SearchOutlined />}
                  style={{ flexGrow: 1, marginRight: '10px' }}
                />
                <Button type="primary" onClick={() => resetFilters()} disabled={storageFilters?.news?.length === 0}>
                  Reset filters
                </Button>
              </div>
            </Col>
          </Row>
          <Divider />
          <Whitter />
          {/* {console.log("page: ", page)} */}
          <div className="news__pagination">
            <Pagination 
            current={page}
            defaultPageSize={10}
            // showSizeChanger
            // pageSize={page} 
            // pageSize={10} 
            onChange={onChange} 
            total={news?.data.count} />
          </div>
        </div>
      </Container>
    </Spin>
  );
};

export default News;
